import { createFileRoute } from "@tanstack/react-router";
import { ManageRolesRoute } from "src/containers/manageRolesRoute";

export const Route = createFileRoute("/_layout-nosidebar/manageRoles")({
    component: ManageRoles,
});

export default function ManageRoles() {
    return <ManageRolesRoute />;
}
