import { Anchor, Menu, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { type MRT_ColumnDef, MRT_Table, useMantineReactTable } from "mantine-react-table";
import type React from "react";
import { chatVectorIndex } from "src/db";
import { type DatasourceIngestionStatus, useIngestionStatus } from "src/hooks/useIngestionStatus";

export const IngestionStatusOverviewAnchor: React.FC = () => {
    const { data: ingestionStatus } = useIngestionStatus();
    const [modalOpened, { open, close }] = useDisclosure(false);

    return (
        <>
            <Menu>
                <Menu.Target>
                    <Anchor onClick={open}>Data Source Details</Anchor>
                </Menu.Target>
            </Menu>
            <IngestionStatusOverviewModal
                status={ingestionStatus?.data_sources}
                opened={modalOpened}
                onClose={close}
            />
        </>
    );
};

const ingestionStatusOverviewColumns: MRT_ColumnDef<DatasourceIngestionStatus>[] = [
    {
        id: "datasource_name",
        accessorKey: "id",
        accessorFn: (row) => {
            return (
                chatVectorIndex.find((indexElement) => indexElement.value[0] === row.id)?.label ??
                row.id
            );
        },
        header: "Data Source",
    },
    {
        accessorFn: (row) => {
            return row.product_lines
                .map((productLineInfo) => productLineInfo.product_line)
                .toSorted((a, b) => a.localeCompare(b))
                .join(", ");
        },
        header: "Product Lines",
        enableSorting: false,
    },
    {
        accessorFn: (row) => {
            return row.total_count > 0 ? row.total_count : "";
        },
        header: "Count",
    },
    {
        accessorKey: "ingestion_status",
        header: "Ingestion Status",
    },
    {
        header: "Latest Document Date",
        accessorKey: "latest_document_timestamp",
        sortingFn: "datetime",
        accessorFn: (row) =>
            row.latest_document_timestamp
                ? new Date(row.latest_document_timestamp * 1000)
                : undefined,
        Cell: ({ cell }) =>
            cell.getValue<Date>()?.toLocaleDateString(undefined, {
                day: "numeric",
                month: "numeric",
                year: "numeric",
            }),
    },
];

interface IngestionStatusOverviewModalProps {
    status?: DatasourceIngestionStatus[];
    opened: boolean;
    onClose: () => void;
}

const IngestionStatusOverviewModal: React.FC<IngestionStatusOverviewModalProps> = ({
    status,
    opened,
    onClose,
}) => {
    const sortedValues = status?.toSorted((a, b) => a.id.localeCompare(b.id, "en"));

    const table = useMantineReactTable({
        columns: ingestionStatusOverviewColumns,
        data: sortedValues ?? [],

        enablePagination: false,
        enableColumnActions: false,
        enableColumnFilters: false,
        initialState: {
            density: "xs",
            sorting: [{ id: "datasource_name", desc: false }],
        },
        mantineTableBodyCellProps: { sx: { wordWrap: "break-word" } },
    });

    return (
        <Modal size="100em" opened={opened} onClose={onClose} title={"Data Source Details"}>
            <MRT_Table table={table} />
        </Modal>
    );
};
