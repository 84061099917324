import { type ReactNode, createContext, useContext } from "react";
import { useGuidedTour } from "../hooks/useGuidedTour";

type TourContextType = {
    isTourOpen: boolean;
    startTour: () => void;
    completeTour: () => void;
};

const TourContext = createContext<TourContextType | undefined>(undefined);

export function TourProvider({ children }: { children: ReactNode }) {
    const { isTourOpen, completeTour, resetTour } = useGuidedTour();

    const startTour = () => {
        resetTour();
    };

    return (
        <TourContext.Provider
            value={{
                isTourOpen,
                startTour,
                completeTour,
            }}
        >
            {children}
        </TourContext.Provider>
    );
}

export function useTour() {
    const context = useContext(TourContext);
    if (context === undefined) {
        throw new Error("useTour must be used within a TourProvider");
    }
    return context;
}
