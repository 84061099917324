import type React from "react";
import { createContext, useContext, useState } from "react";
import type { MessageEntity } from "src/db";
import { useSearchCompletion } from "src/hooks/useSearchCompletion";

interface SearchContextType {
    content: string;
    setContent: (content: string) => void;
    isLoading: boolean;
    messages: MessageEntity[];
    sendMessage: (
        content: string,
        chatId?: string,
        metadataFilter?: { key: string; value: string }[],
        selectedTab?: string,
    ) => Promise<void>;
    setMessages: React.Dispatch<React.SetStateAction<MessageEntity[]>>;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

export function SearchProvider({ children }: { children: React.ReactNode }) {
    const [content, setContent] = useState("");
    const { messages, isloading: isLoading, sendMessage, setMessages } = useSearchCompletion();

    return (
        <SearchContext.Provider
            value={{
                content,
                setContent,
                isLoading,
                // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                messages: messages as any,
                sendMessage,
                // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                setMessages: setMessages as any,
            }}
        >
            {children}
        </SearchContext.Provider>
    );
}

export function useSearchContext() {
    const context = useContext(SearchContext);
    if (context === undefined) {
        throw new Error("useSearchContext must be used within a SearchProvider");
    }
    return context;
}
