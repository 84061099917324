import { notifications } from "@mantine/notifications";
import { useNavigate } from "@tanstack/react-router";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useEffect, useRef } from "react";
import { useUser } from "./useUser";

/**
 * Custom hook to synchronize user's preferred roles with their actual assigned roles
 * Ensures that any roles in the user's preferences that aren't valid user roles are filtered out
 * Redirects user to preferences page if new roles are detected
 */
export const useSyncUserRoles = () => {
    const { data: user, isLoading: userLoading } = useUser();
    const userDatasources = user?.datasources?.map((ds) => ds.id) || [];
    const navigate = useNavigate();
    const notificationShownRef = useRef(false);

    const [chatPreferences, setChatPreferences] = useLocalStorage<{
        selectedProductLines: string[];
        selectedDatasources: string[];
    }>("chatPreferences", {
        selectedProductLines: [],
        selectedDatasources: [],
    });

    const [availableDataSources, setAvailableDataSources] = useLocalStorage<string[]>(
        "availableDataSources",
        [],
    );

    const [notifiedRoles, setNotifiedRoles] = useLocalStorage<{
        timestamp: number;
        roleIds: string[];
    }>("notifiedNewRoles", { timestamp: 0, roleIds: [] });

    const [_, setStoredUserDatasources] = useLocalStorage<string[]>("userDatasources", []);

    useEffect(() => {
        if (userDatasources?.length) {
            setStoredUserDatasources(userDatasources);
        }
    }, [userDatasources, setStoredUserDatasources]);

    useEffect(() => {
        // Only run when user data is loaded and not loading
        if (userLoading || !user || !user.datasources) return;

        // Get the user's available datasources from the API response
        const currentAvailableDatasources = user.datasources.map((ds) => ds.id);

        // Check if this is a fresh state (first visit)
        const isFreshState = availableDataSources.length === 0;

        // Check if there are new roles assigned that weren't previously available
        const newRoles = currentAvailableDatasources.filter(
            (id) => !availableDataSources.includes(id),
        );

        const newRolesAssigned = newRoles.length > 0;

        // If new roles were assigned and this isn't the first load (availableDataSources not empty)
        if (newRolesAssigned && !isFreshState) {
            // Check if we've already notified about these specific roles recently (within the last 10 seconds)
            const oneHourAgo = Date.now() - 10 * 1000;
            const alreadyNotified =
                notifiedRoles.timestamp > oneHourAgo &&
                newRoles.every((role) => notifiedRoles.roleIds.includes(role));

            // Only show notification if we haven't shown it yet for these roles and the ref is false
            if (!alreadyNotified && !notificationShownRef.current) {
                notifications.show({
                    title: "New roles detected",
                    message: "Please update your role preferences",
                    color: "orange",
                });

                // Update the notified roles with the current timestamp and role IDs
                setNotifiedRoles({
                    timestamp: Date.now(),
                    roleIds: [...notifiedRoles.roleIds, ...newRoles],
                });

                // Set the ref to true to prevent showing the notification again during this session
                notificationShownRef.current = true;
            }

            // Check if we're not already on the questionnaire page to prevent redirect loops
            const currentLocation = window.location.pathname;
            if (!currentLocation.includes("questionnaire")) {
                navigate({ to: "/questionnaire" });
            }
        }

        // Update the stored available datasources if needed
        if (JSON.stringify(availableDataSources) !== JSON.stringify(currentAvailableDatasources)) {
            setAvailableDataSources(currentAvailableDatasources);
        }

        // Use functional update to avoid dependency on the current value of chatPreferences
        setChatPreferences((currentPreferences) => {
            // Filter the user's preferred datasources to only include those that exist in their available datasources
            const filteredDatasources = currentPreferences.selectedDatasources.filter((dsId) =>
                currentAvailableDatasources.includes(dsId),
            );

            // Check if there's actually a change to prevent unnecessary updates and infinite loops
            if (
                JSON.stringify(filteredDatasources) ===
                JSON.stringify(currentPreferences.selectedDatasources)
            ) {
                // No change needed, return the original preferences to prevent triggering updates
                return currentPreferences;
            }

            console.log("User role preferences synchronized with available roles");

            // Return updated preferences
            return {
                ...currentPreferences,
                selectedDatasources: filteredDatasources,
            };
        });
    }, [
        user,
        userLoading,
        setChatPreferences,
        navigate,
        availableDataSources,
        setAvailableDataSources,
        notifiedRoles,
        setNotifiedRoles,
    ]);

    return null; // This hook doesn't need to return anything, it just performs side effects
};
