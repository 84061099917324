import { useAccount, useMsal } from "@azure/msal-react";
import { PdfFocusProvider } from "@llamaindex/pdf-viewer";
import {
    ActionIcon,
    Alert,
    Anchor,
    Avatar,
    Box,
    Button,
    Flex,
    Group,
    Image,
    Loader,
    Menu,
    Modal,
    Stack,
    Table,
    Text,
    Textarea,
    Title,
    rem,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import {
    IconAlertCircle,
    IconDotsVertical,
    IconFileDislike,
    IconFileLike,
    IconTrash,
} from "@tabler/icons-react";
import { useLiveQuery } from "dexie-react-hooks";
import { Feature, useFeature } from "flagged";
import "property-information";
import { useEffect, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { useAdmin } from "src/utils/useAdmin";
import { v4 as uuidv4 } from "uuid";
import { type MessageEntity, type Reference, db } from "../db";
import { CHAT_COMPLETIONS_URL } from "../hooks/useChatCompletion";
import "../styles/markdown.scss";
import { getFullUrl, handleArtifactClick, isArtifactURL } from "../utils/artifactHandler";
import { DeleteMessageEntityItemModal } from "./DeleteChatMessageItemModal";
import { InlineReference } from "./InlineReference";
import { MessageItemCode } from "./MessageItemCode";
import { ReferenceViewer } from "./ReferenceViewer";
import { AsyncPdfViewer } from "./TopToolbarCustomActions";
import { TypingIndicator } from "./TypingIndicator";

enum FeedbackType {
    POSITIVE = "positive",
    NEGATIVE = "negative",
}

interface MDComponentProps {
    node: unknown;
    children?: React.ReactNode;
    href?: string;
}

interface ImageProps {
    src: string | null;
    alt: string;
    withPlaceholder?: boolean;
    placeholder?: React.ReactNode;
    loader?: () => Promise<string>;
}

export const MDTable = ({ node, ...props }: MDComponentProps) => (
    <Table
        striped
        highlightOnHover
        fontSize="xs"
        verticalSpacing="xs"
        horizontalSpacing="xs"
        {...props}
    />
);

export const MDCode = ({ node, ...props }: MDComponentProps) => (
    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
    <MessageItemCode {...(props as any)} />
);

export const MDParagraph = ({ node, children, ...props }: MDComponentProps) => (
    <Text {...props}>{children}</Text>
);

export const MDHeading = ({ node, children, ...props }: MDComponentProps) => (
    <Title order={2} {...props}>
        {children}
    </Title>
);

export const MDImage = ({ src, alt }: ImageProps) => {
    const account = useAccount();
    const apiKey = account?.idToken;
    const [imageUrl, setImageUrl] = useState<string | null>(null);

    const loader = async () => {
        const response = await fetch(src || "", {
            headers: {
                Authorization: `Bearer ${apiKey}`,
            },
        });
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setImageUrl(url);
        return url;
    };

    return (
        <Image
            src={imageUrl}
            alt={alt}
            withPlaceholder
            placeholder={<Loader variant="bars" />}
            onLoad={loader}
        />
    );
};

export const MDA = ({ node, children, ...props }: MDComponentProps) => {
    const { instance } = useMsal();
    const account = useAccount();
    const apiKey = account?.idToken;
    const openPDFModal = useFeature("OPEN_PDF_MODAL") && props.href?.endsWith(".pdf");
    const [opened, { open, close }] = useDisclosure(false);

    if (isArtifactURL(props.href)) {
        const fullUrl = getFullUrl(props.href);
        return (
            <>
                <Modal opened={opened} onClose={close} withCloseButton={false} size="90vw">
                    <MDAWithPdfPreview node={node} instance={instance} apiKey={apiKey} {...props}>
                        {children}
                    </MDAWithPdfPreview>
                </Modal>
                <a
                    target="_blank"
                    href={fullUrl}
                    onClick={(e) => {
                        if (openPDFModal) open();
                        else handleArtifactClick(e, props.href, apiKey, instance);
                        e.preventDefault();
                    }}
                    rel="noreferrer"
                >
                    {children}
                </a>
            </>
        );
    }
    return (
        <a target="_blank" {...props}>
            {children}
        </a>
    );
};

export const MDAWithPdfPreview = ({ node, ...props }) => {
    const { instance } = useMsal();
    const account = useAccount();
    const apiKey = account?.idToken;

    if (props.href?.endsWith(".pdf")) {
        const fullUrl = getFullUrl(props.href);
        return (
            <Flex gap="md" align="center">
                <AsyncPdfViewer
                    pdfUrl={fullUrl}
                    apiKey={apiKey}
                    row={fullUrl.split("object_name=")[1] || ""}
                    instance={instance}
                />
            </Flex>
        );
    }
    if (props.href?.endsWith(".html")) {
        const fullUrl = getFullUrl(props.href);
        return (
            <a target="_blank" href={fullUrl} rel="noreferrer">
                {props.children}
            </a>
        );
    }
    return (
        <a target="_blank" href={props.href} {...props}>
            {props.children}
        </a>
    );
};

export const MDAWithImagePreview = ({ node, ...props }) => {
    // Get the instance and apiKey from the useMsal and useAccount hooks
    const { instance } = useMsal();
    const account = useAccount();
    const apiKey = account?.idToken;

    if (props.src?.endsWith(".jpg") || props.src?.endsWith(".png") || props.src?.endsWith(".gif")) {
        const fullUrl = getFullUrl(props.src);
        return (
            <Flex gap="md" align="center">
                {/* Render the image with authentication */}
                <MDImage src={fullUrl} alt="Image" />
            </Flex>
        );
    }

    // Fallback to the default behavior for other links
    return (
        <a target="_blank" href={props.href} {...props}>
            {props.children}
        </a>
    );
};

export function isAllCaps(str: string): boolean {
    return str === str.toUpperCase();
}

type StructuredData = Record<string, unknown>;

export const renderStructuredRequest = (data: string | StructuredData): React.ReactNode => {
    const renderObject = (obj: StructuredData) => (
        <div>
            <pre>{JSON.stringify(obj, null, 2)}</pre>
        </div>
    );

    const renderArray = (arr: unknown[]) => (
        <ul>
            {arr.map((item) => (
                // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                <li key={item as any}>{renderStructuredRequest(item as string)}</li>
            ))}
        </ul>
    );

    try {
        if (!data) {
            return null;
        }

        if (typeof data === "string") {
            let parsedData: StructuredData | string[] | string;
            try {
                parsedData = JSON.parse(data);
            } catch (error) {
                return <span>{data}</span>; // Return original string if parsing fails
            }

            if (Array.isArray(parsedData)) {
                return renderArray(parsedData);
            }
            if (
                typeof parsedData === "object" &&
                parsedData !== null &&
                Object.keys(parsedData).length > 0
            ) {
                return renderObject(parsedData);
            }
            return <span>{data}</span>; // Fallback for parsed data that is neither array nor object
        }
        if (typeof data === "object" && data !== null) {
            return renderObject(data);
        }
        return <span>{JSON.stringify(data)}</span>; // Fallback for non-string, non-object data types
    } catch (error) {
        return <span>Error rendering data</span>;
    }
};

export const sendFeedbackToApi = async (
    apiKey: string | undefined,
    responseId: string,
    feedbackType: FeedbackType,
    feedback: string,
) => {
    const transactionId = uuidv4();

    Sentry.withScope((scope) => {
        scope.setTag("transaction_id", transactionId);
    });

    const response = await fetch(`${CHAT_COMPLETIONS_URL}/v1/feedback/upsert`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
            "X-Transaction-ID": transactionId,
        },
        method: "POST",
        body: JSON.stringify({
            response_id: responseId,
            feedback_type: feedbackType,
            comments: feedback,
        }),
    });
    // Do the some handling for the response that was being done in the SSE event listeners
    const res = await response.json();
    if (response.status !== 200) {
        notifications.show({
            color: "red",
            title: "Sorry, Feedback failed to be sent!",
            message: res.detail ?? "Please try again later",
        });
    } else {
        notifications.show({
            color: "green",
            title: "Feedback sent successfully!",
            message: "Thanks for sharing, we'll keep improving!",
        });
    }
};

interface AnswerProps {
    isCompact?: boolean;
    content: string;
    index: number;
    answers: string[];
    isAssistant: boolean;
    message: MessageEntity;
    question: MessageEntity | null;
    retry: (newMessage: string) => Promise<void>;
    isAdmin: boolean;
    wordCount: number;
    showOldword: boolean;
    nextAssistantMessage: MessageEntity | undefined;
    inProgress: boolean;
}

// Function to process markdown content and replace bracketed references (e.g. "[1]")
// with a custom self-closing tag <ref index="1" />.
const processMarkdownContent = (content: string, references?: Reference[]) => {
    return content.replace(/\[(\d+)\](?!\()/g, (match, num) => {
        const refIndex = Number.parseInt(num) - 1;
        if (references?.[refIndex]) {
            return `<inline-ref index="${num}"/></inline-ref>`;
        }
        return match;
    });
};

const Answer = ({
    isCompact,
    content,
    index,
    answers,
    isAssistant,
    message,
    question,
    retry,
    isAdmin,
    wordCount,
    showOldword,
    nextAssistantMessage,
    inProgress,
}: AnswerProps) => {
    return (
        <>
            {answers.length > 1 && content && isAssistant && !message.hasError ? (
                <Title order={6}>{index === 0 ? "CTS Knowledge Base" : "Public data"}</Title>
            ) : null}
            {index === 0 && message.hasError ? (
                <Alert
                    data-testid="error-alert"
                    mt="xs"
                    mb="md"
                    variant="light"
                    color="red"
                    title="Error happened"
                >
                    {message.error?.includes("Unauthorized") ? "An error occurred" : message.error},
                    Please try again sending this message{" "}
                    <Button
                        variant="light"
                        c="white"
                        data-testid="retry-button"
                        size="xs"
                        onClick={() => {
                            // If question content is empty, find the last user message
                            if (!question?.content) {
                                if (!db) {
                                    // In test environments, db might not be available
                                    // Use a plain fallback instead of database access
                                    retry("Could you please try again?");
                                } else {
                                    // We need to get messages from the database to find the last user question
                                    db.messages
                                        .where("chatId")
                                        .equals(message.chatId)
                                        .sortBy("createdAt")
                                        .then((messages) => {
                                            // Find the last user message with content
                                            const lastUserMessage = [...messages]
                                                .reverse()
                                                .find((msg) => msg.role === "user" && msg.content);

                                            if (lastUserMessage?.content) {
                                                retry(decodeURIComponent(lastUserMessage.content));
                                            } else {
                                                // Fallback if no user message is found
                                                retry("Could you please try again?");
                                            }
                                        });
                                }
                            } else {
                                retry(decodeURIComponent(question.content));
                            }
                        }}
                    >
                        Try Again
                    </Button>
                </Alert>
            ) : (
                !message.done &&
                typeof nextAssistantMessage?.content === "undefined" &&
                !message.content &&
                !message.hasError &&
                null
            )}

            {/* {isAssistant && (
                <Feature name="REFERENCE_VIEWER">
                    <Flex align="center" justify="space-between" mb="md">
                        <Group spacing="xs">
                            <IconMessageDots size={18} />
                            <Text size="sm" weight={700} transform="uppercase">
                                Answer
                            </Text>
                        </Group>

                        {message.references?.length > 0 && !isCompact && (
                            <Box w={450} sx={{ textAlign: "left" }}>
                                <Group spacing="xs">
                                    <IconFiles size={18} />
                                    <Text size="sm" weight={700} transform="uppercase">
                                        Sources
                                    </Text>
                                </Group>
                            </Box>
                        )}
                    </Flex>
                </Feature>
            )} */}
            <Flex align="flex-start" gap="lg">
                <Box
                    sx={{ flex: 1, alignSelf: "flex-start" }}
                    className="markdown"
                    data-testid="markdown"
                >
                    {!message.content ? (
                        <TypingIndicator />
                    ) : (
                        !message.error && (
                            <PdfFocusProvider>
                                <ReactMarkdown
                                    remarkPlugins={[remarkGfm]}
                                    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                                    rehypePlugins={[rehypeRaw as any]}
                                    components={
                                        {
                                            table: MDTable,
                                            code: MDCode,
                                            a: MDA,
                                            // Custom renderer for our inline <inline-ref> tag.
                                            "inline-ref": ({ node, ...props }) => {
                                                const { index } = props;
                                                const refIndex = Number.parseInt(index);
                                                const refItem = message.references?.[refIndex - 1];

                                                if (refItem) {
                                                    return (
                                                        <Feature name="REFERENCE_VIEWER">
                                                            <InlineReference
                                                                reference={refItem}
                                                                index={index}
                                                            />
                                                        </Feature>
                                                    );
                                                }
                                                return <span>[{index}]</span>;
                                            },
                                            // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                                        } as any
                                    }
                                >
                                    {processMarkdownContent(content, message.references)}
                                </ReactMarkdown>
                            </PdfFocusProvider>
                        )
                    )}
                    {message.toolCall && inProgress && <TypingIndicator />}
                </Box>

                {isAssistant && message.references?.length > 0 && !isCompact && (
                    <Feature name="REFERENCE_VIEWER">
                        <Box
                            sx={(theme) => ({
                                width: "450px",
                                flexShrink: 0,
                                borderLeft: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`,
                                paddingLeft: theme.spacing.md,
                                marginLeft: theme.spacing.sm,
                            })}
                        >
                            <ReferenceViewer references={message.references} />
                        </Box>
                    </Feature>
                )}
            </Flex>
        </>
    );
};

export const AnswersToBeRendered = ({
    isCompact,
    answers,
    isAssistant,
    message,
    question,
    retry,
    isAdmin,
    wordCount,
    showOldword,
    nextAssistantMessage,
    inProgress,
}: {
    isCompact?: boolean;
    answers: string[];
    isAssistant: boolean;
    message: MessageEntity;
    question: MessageEntity | null;
    retry: (newMessage: string) => Promise<void>;
    isAdmin: boolean;
    wordCount: number;
    showOldword: boolean;
    nextAssistantMessage: MessageEntity | undefined;
    inProgress: boolean;
}) => {
    const renderAnswer = (content = "", index = 0) => (
        <Answer
            key={`answer-${message.responseId}-${message.id}`}
            content={content}
            index={index}
            answers={answers}
            isAssistant={isAssistant}
            isCompact={isCompact}
            message={message}
            question={question}
            retry={retry}
            isAdmin={isAdmin}
            wordCount={wordCount}
            showOldword={showOldword}
            nextAssistantMessage={nextAssistantMessage}
            inProgress={inProgress}
        />
    );

    return (
        <>
            {answers.length
                ? answers.map((content, index) => renderAnswer(content, index))
                : renderAnswer()}
        </>
    );
};

export const MessageItem = function MessageItem({
    isCompact,
    message,
    nextAssistantMessage,
    retry,
    sendFeedbackToApi,
    inProgress,
}: {
    sendFeedbackToApi: (
        apiKey: string | undefined,
        responseId: string,
        feedbackType: FeedbackType,
        feedback: string,
    ) => Promise<void>;
    message: MessageEntity;
    nextAssistantMessage?: MessageEntity;
    retry: (newMessage: string) => Promise<void>;
    inProgress: boolean;
    isCompact?: boolean;
}) {
    const isAdmin = useAdmin();
    const [deleteOpened, { open: openDelete, close: closeDelete }] = useDisclosure(false);
    const account = useAccount();
    const apiKey = account?.idToken;

    useEffect(() => {
        document.querySelector(".mantine-AppShell-main")?.scrollIntoView(false);
    }, []);

    const question = useLiveQuery(async () => {
        if (!message.repliedId) {
            return null;
        }
        const result = await db.messages.get(message.repliedId);
        return result;
    }, [message.repliedId]);

    const wordCount = useMemo(() => {
        if (typeof message.content !== "string") return 0;
        const matches = message.content?.match(/[\w'-()]+/gi);
        return matches ? matches.length : 0;
    }, [message.content]);

    const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);
    const [feedback, setFeedback] = useState<string>("");
    const [feedbackPlaceholder, setFeedbackPlaceholder] = useState<string | undefined>(undefined);
    const [feedbackType, setFeedbackType] = useState<FeedbackType>(FeedbackType.POSITIVE);

    const showOldword =
        message?.suggestions && message?.suggestions?.length > 0 && message.suggestions[0]?.oldWord;
    const isCaptialCase = showOldword && isAllCaps(message?.suggestions?.[0]?.oldWord ?? "");
    const isAssistant = message.role === "assistant";
    const answers = [message?.content, message?.pretrained].filter(
        (c) => Boolean(c) && c !== "N/A",
    );

    const [isSendingFeedback, setIsSendingFeedback] = useState(false);
    const sendFeedback = async (
        feedbackType: FeedbackType,
        allowFeedbackComment: boolean,
        feedbackPlaceholder?: string,
    ) => {
        if (!isSendingFeedback) {
            setIsSendingFeedback(true);
            setFeedbackType(feedbackType);
            setFeedback("");

            if (allowFeedbackComment) {
                setFeedbackPlaceholder(feedbackPlaceholder);
                openModal();
            } else {
                setFeedback("");
                await sendFeedbackToApi(apiKey, message.responseId, feedbackType, "");
                closeModal();
            }
            setIsSendingFeedback(false);
        }
    };

    return (
        <>
            {showOldword ? (
                <Alert
                    sx={(theme) => ({
                        backgroundColor:
                            theme.colorScheme === "dark" ? theme.colors.dark[6] : "#fff",
                    })}
                    icon={<IconAlertCircle size="1rem" />}
                    title={
                        <Anchor
                            onClick={() => {
                                retry(
                                    decodeURIComponent(question?.content ?? "").replace(
                                        message?.suggestions?.[0]?.oldWord ?? "",
                                        message?.suggestions?.[0]?.text ?? "",
                                    ),
                                );
                            }}
                        >
                            <Title display="flex" order={6} color="blue">
                                <Text fw="400">Search for </Text>{" "}
                                <Text mx=".25rem" transform={isCaptialCase ? "uppercase" : "none"}>
                                    {message.suggestions?.[0].text}
                                </Text>{" "}
                                instead of{" "}
                                <Text mx=".25rem">{message.suggestions?.[0].oldWord}</Text> ?
                            </Title>
                        </Anchor>
                    }
                    radius="xs"
                    withCloseButton
                    data-testid="error-alert"
                >
                    {""}
                </Alert>
            ) : (
                <Flex
                    direction="column"
                    gap="sm"
                    className="message-item"
                    sx={(theme) => ({
                        backgroundColor:
                            theme.colorScheme === "dark" ? theme.colors.dark[6] : "#fff",
                        padding: theme.spacing.sm,
                        borderRadius: "var(--spacing-tiny)",
                        width: "100%",
                    })}
                >
                    <Flex gap="sm">
                        {message.role === "user" && (
                            <Avatar color="blue" radius="xl" data-testid="user-avatar">
                                <Text c="blue.5">
                                    {account?.name
                                        ?.split(" ")
                                        .slice(0, 2)
                                        .map((name) => name[0])
                                        .reverse()
                                        .join("")}
                                </Text>
                            </Avatar>
                        )}
                        {isAssistant && (
                            <div data-testid="assistant-logo">
                                <Image
                                    fit="contain"
                                    src="/ai-icon.png"
                                    alt="AI Assistant"
                                    width={32}
                                    height={32}
                                />
                            </div>
                        )}

                        <Box
                            sx={{ flex: 1, alignSelf: "center", position: "relative" }}
                            className="markdown"
                            data-testid="message-content"
                        >
                            {!showOldword ? (
                                <AnswersToBeRendered
                                    isCompact={isCompact}
                                    answers={answers}
                                    isAssistant={isAssistant}
                                    message={message}
                                    question={question}
                                    retry={retry}
                                    isAdmin={isAdmin}
                                    wordCount={wordCount}
                                    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                                    showOldword={showOldword as any}
                                    nextAssistantMessage={nextAssistantMessage}
                                    inProgress={inProgress}
                                />
                            ) : null}
                        </Box>
                        {isAssistant ? (
                            <Menu
                                position="left"
                                shadow="md"
                                width={200}
                                data-testid="assistant-menu"
                            >
                                <Menu.Target>
                                    <ActionIcon data-testid="assistant-menu-target">
                                        <IconDotsVertical size={18} />
                                    </ActionIcon>
                                </Menu.Target>

                                <Menu.Dropdown data-testid="assistant-menu-dropdown">
                                    {!inProgress && (
                                        <>
                                            <Menu.Item
                                                icon={<IconFileLike size={14} />}
                                                onClick={() =>
                                                    sendFeedback(FeedbackType.POSITIVE, false)
                                                }
                                                data-testid="inside-like-menu-item"
                                            >
                                                Like
                                            </Menu.Item>
                                            <Menu.Item
                                                icon={<IconFileDislike size={14} />}
                                                onClick={() =>
                                                    sendFeedback(
                                                        FeedbackType.NEGATIVE,
                                                        true,
                                                        "This didn't answer my question, I expected …",
                                                    )
                                                }
                                                data-testid="inside-dislike-menu-item"
                                            >
                                                Dislike
                                            </Menu.Item>
                                        </>
                                    )}
                                    <Menu.Item
                                        color="red"
                                        icon={<IconTrash size={14} />}
                                        data-testid="delete-menu-item"
                                        onClick={openDelete}
                                    >
                                        Delete
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                        ) : null}
                    </Flex>
                    {isAssistant && !inProgress && (
                        <Group sx={{ justifyContent: "end" }}>
                            <Text>Did we answer your question?</Text>
                            <ActionIcon
                                color="green"
                                variant="subtle"
                                size="lg"
                                aria-label="Like"
                                sx={{ cursor: "pointer" }}
                                onClick={() => sendFeedback(FeedbackType.POSITIVE, false)}
                                data-testid="like-menu-item"
                            >
                                <IconFileLike style={{ width: rem(20) }} stroke={1.5} />
                            </ActionIcon>
                            <ActionIcon
                                variant="subtle"
                                color="red"
                                size="lg"
                                aria-label="Dislike"
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                    sendFeedback(
                                        FeedbackType.NEGATIVE,
                                        true,
                                        "This didn't answer my question, I expected …",
                                    )
                                }
                                data-testid="dislike-menu-item"
                            >
                                <IconFileDislike style={{ width: rem(20) }} stroke={1.5} />
                            </ActionIcon>
                        </Group>
                    )}
                </Flex>
            )}

            <DeleteMessageEntityItemModal
                message={message}
                isOpen={deleteOpened}
                close={closeDelete}
            />
            <Modal
                size="lg"
                opened={modalOpened}
                onClose={closeModal}
                title="Write your feedback"
                data-testid="feedback-modal"
            >
                <Stack align="flex-end">
                    <Textarea
                        placeholder={feedbackPlaceholder}
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        style={{ width: "100%" }}
                        minRows={4}
                        data-testid="feedback-textarea"
                    />
                    <Button
                        onClick={async () => {
                            await sendFeedbackToApi(
                                apiKey,
                                message.responseId,
                                feedbackType,
                                feedback,
                            );
                            closeModal();
                        }}
                        data-testid="submit-feedback-button"
                    >
                        Submit
                    </Button>
                </Stack>
            </Modal>
        </>
    );
};
