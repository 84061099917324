import { Autocomplete, Button, Container, Flex, Stack, Text, Title } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import { useSearchContext } from "src/contexts/searchContext";
import { useTab } from "src/contexts/tabContext";
import { useChatId } from "src/hooks/useChatId";
import { SearchItems } from "./SearchItems";

export function SearchRoute() {
    const [selectedSources, setSelectedSources] = useState(["documents"]);
    const { selectedTabShorthand, selectedTab, setSelectedTab } = useTab();
    const { content, setContent, isLoading, sendMessage, messages, setMessages } =
        useSearchContext();
    const chatId = useChatId();
    const ref = useRef<HTMLInputElement>(null);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (!content.trim()) return;

        setMessages([]);

        setContent(content);
        await sendMessage(
            content,
            chatId,
            selectedSources.map((source) => ({ key: "source", value: source })),
        );
    };

    useEffect(() => {
        if (window.location.hash === "#/search") {
            setSelectedTab("combined");
        }
    }, [setSelectedTab]);

    return (
        <>
            {/* Show search form when there are no messages */}
            {(!messages || messages.length === 0) && (
                <Container
                    size="xl"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "calc(100vh - 200px)",
                    }}
                >
                    <Stack align="center" spacing="xl">
                        <Title order={1} align="center" c="purple.8" size="3rem">
                            Welcome to CTS Knowledge Search
                        </Title>
                        <Text size="xl" align="center" maw={700} c="gray.7">
                            Your intelligent gateway to field service reports, Salesforce cases,
                            engineering drawings, and comprehensive technical documentation.
                        </Text>

                        <form
                            onSubmit={handleSubmit}
                            style={{
                                width: "100%",
                                maxWidth: "800px",
                            }}
                        >
                            <Flex gap="sm" align="center">
                                <Autocomplete
                                    ref={ref}
                                    value={content}
                                    style={{ flex: 1 }}
                                    id="chat-input"
                                    maxDropdownHeight={200}
                                    onChange={(value) => setContent(value)}
                                    styles={(theme) => ({
                                        input: {
                                            borderRadius: theme.radius.md,
                                            height: "36px",
                                        },
                                        root: {
                                            width: "100%",
                                        },
                                    })}
                                    data={[]}
                                />
                                <Button
                                    data-testid="send-button"
                                    h={36}
                                    variant="filled"
                                    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                                    onClick={handleSubmit as any}
                                    disabled={false}
                                >
                                    <IconSearch size={18} />
                                </Button>
                            </Flex>
                        </form>
                    </Stack>
                </Container>
            )}

            {/* Show messages when they exist */}
            {messages && messages.length > 0 && (
                <SearchItems
                    chatId={chatId}
                    messages={messages.filter((message) => message.role === "assistant")}
                    metadataFilter={selectedSources.map((source) => ({
                        key: "source",
                        value: source,
                    }))}
                />
            )}
        </>
    );
}
