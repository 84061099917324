import type { AccountInfo } from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import { notifications } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useNavigate } from "@tanstack/react-router";
import { useLocalStorage } from "@uidotdev/usehooks";
import type { IndexableType } from "dexie";
import { useLiveQuery } from "dexie-react-hooks";
import { useFeature } from "flagged";
import { useCallback, useEffect, useState } from "react";
import { TAB_INDEX_MAPPING } from "src/utils/constants";
import { v4 as uuidv4 } from "uuid";
import { useTab } from "../contexts/tabContext";
import {
    ChatEntity,
    ChatRole,
    type ChatVectorIndex,
    MessageEntity,
    type Reference,
    type ReferenceJson,
    type SingleSuggestionOption,
    db,
} from "../db";
import { useChatId } from "./useChatId";
import { useMetadata } from "./useMetadata";

interface ISendingParams {
    vector_database?: ChatVectorIndex;
    chatId?: string | IndexableType;
    content?: string;
    systemContent?: string;
}

interface OpenAIChatMessage {
    answer: string;
    suggestions: SingleSuggestionOption[];
    content: string;
    role: string;
}

interface ChatMessageToken extends OpenAIChatMessage {
    timestamp: number;
}

interface ChatMessageParams extends OpenAIChatMessage {
    timestamp?: number;
    meta?: {
        loading?: boolean;
        responseTime?: string;
        chunks?: ChatMessageToken[];
    };
}

export interface ChatMessage extends ChatMessageParams {
    responseId?: string;
    pretrained?: string;
    toolCall: boolean;
    timestamp: number;
    references: Reference[];
    meta: {
        loading: boolean;
        responseTime: string;
        chunks: ChatMessageToken[];
    };
}

// callback function type
type CallbackFunction = (
    answer: ChatMessage | string,
    error?: string,
    reference?: Reference,
    toolCall?: boolean,
) => void;

const makeMessagesSendingRequest = async ({
    chatId,
    content,
}: {
    chatId: string | IndexableType | undefined;
    content: string;
}): Promise<ChatMessageParams[]> => {
    if (!chatId) {
        throw new Error("Chat ID is required");
    }

    return [
        {
            content,
            role: "user",
            answer: "",
            suggestions: [],
        },
    ];
};

export const CHAT_COMPLETIONS_URL = import.meta.env.VITE_BACKEND_URL;

// Utility method for transforming a chat message that may or may not be decorated with metadata
// to a fully-fledged chat message with metadata.
const createChatMessage = ({ content, role, ...restOfParams }: ChatMessageParams): ChatMessage => ({
    content,
    answer: content,
    role,
    timestamp: restOfParams.timestamp ?? Date.now(),
    references: [],
    suggestions: [],
    toolCall: false,
    meta: {
        loading: false,
        responseTime: "",
        chunks: [],
        ...restOfParams.meta,
    },
});

const prepareUpdatedMessages = (
    messages: MessageEntity[] | undefined,
    newMessages: ChatMessageParams[] | undefined,
): ChatMessage[] => {
    const convertToChat = (msg: MessageEntity): ChatMessage => ({
        content: msg.content ?? "",
        answer: msg.content ?? "",
        role: msg.role,
        timestamp: msg.createdAt ?? Date.now(),
        references: msg.references ?? [],
        suggestions: msg.suggestions ?? [],
        toolCall: msg.toolCall,
        meta: {
            loading: false,
            responseTime: "",
            chunks: [],
        },
    });

    return [
        ...(messages?.map(convertToChat) ?? []),
        ...(newMessages?.map(createChatMessage) ?? []),
        createChatMessage({
            content: "",
            role: "",
            answer: "",
            suggestions: [],
            meta: { loading: true },
        }),
    ];
};

interface AnswerJson {
    content: string;
    responseId?: string;
    response_id?: string;
    pretrained?: string;
    suggestions?: SingleSuggestionOption[];
    references?: Reference[];
    mindmaps?: unknown;
    good_solutions?: unknown;
    salesforce?: unknown;
    field_service_reports?: unknown;
    tool_call: boolean;
}

export const preparePayload = (
    newMessages: ChatMessageParams[] | undefined,
    messages: ChatMessage[] | undefined,
    chatId: string | undefined,
    account: AccountInfo | null,
    metadata: Record<string, string>[] | undefined,
    productLine?: string,
    selectedProductLines?: string[],
    userAuthorizedDatasources?: string[],
) => {
    if (!chatId) return undefined;
    const question = newMessages?.reverse().find((m) => m.role === "user")?.content ?? "";
    const HOW_MANY_PAST_MESSAGES = 3;
    // const chat_history = messages
    //     ?.reduce((acc: { user_message: string; ai_message: string }[], message, i) => {
    //         if (message.role === "user") {
    //             acc.push({
    //                 user_message: message.content,
    //                 ai_message: messages[i + 1]?.content || "",
    //             });
    //         }
    //         return acc;
    //     }, [])
    //     .filter(
    //         (m) =>
    //             m.ai_message &&
    //             !["Failed to fetch", "Sorry but I can't answer your question."].includes(
    //                 m.ai_message,
    //             ),
    //     )
    //     .slice(-HOW_MANY_PAST_MESSAGES);

    return JSON.stringify({
        question,
        // chat_history,
        conversation_id: chatId,
        options: {
            debug: false,
            metadata_filter: metadata ?? [],
            ...(selectedProductLines ? { product_line: selectedProductLines[0] } : {}),
            ...(userAuthorizedDatasources ? { datasources: userAuthorizedDatasources } : {}),
        },
    }).replace('"id":"', '"key":"');
};

const prepareRequestDetails = (
    apiKey: string,
    selectedTab: string,
    isStreamEnabled: boolean,
    onboardingEnabled: boolean,
    isChatWithDocument?: boolean,
) => {
    const transactionId = uuidv4();
    Sentry.withScope((scope) => {
        scope.setTag("transaction_id", transactionId);
    });

    const CHAT_HEADERS = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiKey}`,
        "X-Transaction-ID": transactionId,
        Accept: "text/event-stream",
    };

    let CHAT_ENDPOINT = `${CHAT_COMPLETIONS_URL}/v1/chat`;
    const queryParameters = "";

    // If onboarding is enabled, always use combined endpoint (only if not in chat with document where documentType is provided)
    if (onboardingEnabled && !isChatWithDocument) {
        // Using combined endpoint for onboarding
        CHAT_ENDPOINT += "";
    } else if (selectedTab === "salesforce") {
        CHAT_ENDPOINT += "/salesforce";
    } else if (
        selectedTab === "field_service_reports" ||
        selectedTab === "field_service_reports_sg"
    )
        CHAT_ENDPOINT += "/field_service_reports";
    else if (selectedTab === "mindmaps") {
        CHAT_ENDPOINT += "/mindmaps";
    } else if (selectedTab === "drawings") {
        CHAT_ENDPOINT += "/drawings";
    } else if (selectedTab === "rds_reports") {
        CHAT_ENDPOINT += "/rds_reports";
    } else if (selectedTab === "support_emails") {
        CHAT_ENDPOINT += "/support_emails";
    } else if (selectedTab === "useful_info") {
        CHAT_ENDPOINT += "/useful_info";
    } else if (selectedTab === "ads_good_solutions") {
        CHAT_ENDPOINT += "/ads_good_solutions";
    } else if (selectedTab === "salesorders") {
        CHAT_ENDPOINT += "/salesorders";
    }
    const CHAT_ENDPOINT_STREAM: string = isStreamEnabled
        ? `${CHAT_ENDPOINT}/stream`.concat(queryParameters)
        : CHAT_ENDPOINT.concat(queryParameters);

    return { CHAT_HEADERS, CHAT_ENDPOINT_STREAM };
};

const makeApiRequest = async (
    CHAT_ENDPOINT_STREAM: string,
    CHAT_HEADERS: Record<string, string>,
    payload: string | undefined,
) => {
    if (!payload) {
        throw new Error("No payload provided");
    }

    const response = await fetch(CHAT_ENDPOINT_STREAM, {
        headers: CHAT_HEADERS,
        method: "POST",
        cache: "no-cache",
        keepalive: true,
        body: payload,
    });
    if (response.status === 401) {
        throw new Error("Unauthorized");
    }
    return response;
};

const processResponse = async (
    response: Response,
    isStreamEnabled: boolean,
    callback: CallbackFunction,
) => {
    let answer = "";
    let answerJson: AnswerJson = {
        content: "",
        tool_call: false,
    };

    if (isStreamEnabled) {
        ({ answer, answerJson } = await processStreamResponse(response, callback));
    } else {
        ({ answer, answerJson } = await processNonStreamResponse(response));
    }

    if (!answer) {
        throw new Error("No response from API");
    }
    return { answer, answerJson };
};

const processStreamResponse = async (response: Response, callback: CallbackFunction) => {
    let answer = "";
    const answerJson: AnswerJson = {
        content: "",
        tool_call: false,
    };

    // Check for error status codes first
    if (response.status === 403) {
        const errorData = await response.json();
        const errorMessage =
            errorData.detail ||
            "You are not authorized to access this resource. Please contact the administrator.";
        callback("", errorMessage);
        throw new Error(errorMessage);
    }

    if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.detail || "An error occurred while processing your request";
        callback("", errorMessage);
        throw new Error(errorMessage);
    }

    const reader = response.body?.getReader();
    setStreamingState(true);

    try {
        while (reader) {
            const { value, done } = await reader.read();
            if (done) break;

            const lines = new TextDecoder().decode(value).split("\n");
            for (const line of lines) {
                if (line.startsWith("data: ")) {
                    const jsonValue = parseJsonLine(line.slice(6));

                    // Check for end event and reset streaming state immediately
                    if (jsonValue?.event === "end") {
                        setStreamingState(false);
                    }

                    if (jsonValue?.event === "error") {
                        const errorMessage = jsonValue.content || "An error occurred";
                        // Check if the error message indicates an authorization issue
                        if (
                            errorMessage.toLowerCase().includes("not authorized") ||
                            errorMessage.toLowerCase().includes("unauthorized") ||
                            errorMessage.includes("403")
                        ) {
                            const fullError = `${errorMessage}. Please contact the administrator.`;
                            callback("", fullError);
                            throw new Error(fullError);
                        }
                        callback("", errorMessage);
                        throw new Error(errorMessage);
                    }
                    const updatedAnswer = updateAnswerAndJson(jsonValue, answerJson, callback);
                    if (updatedAnswer) {
                        answer += updatedAnswer;
                        callback?.(answer);
                    }
                }
            }
        }
    } catch (error) {
        const errorMessage =
            error instanceof Error
                ? error.message
                : "An error occurred while streaming the response";
        callback("", errorMessage);
        throw error;
    } finally {
        setStreamingState(false);
    }

    return { answer, answerJson };
};

const processNonStreamResponse = async (response: Response) => {
    const answerJson = await response.json();
    if (response.status === 403) {
        throw new Error(
            "You are not authorized to access this resource. Please contact the administrator.",
        );
    }
    if (response.status !== 200) {
        throw answerJson.detail ?? new Error("API response was not OK");
    }
    const answer = answerJson?.content;
    return { answer, answerJson };
};

const parseJsonLine = (jsonString: string) => {
    try {
        return JSON.parse(jsonString);
    } catch (error) {
        console.error("Error parsing JSON:", error);
        return null;
    }
};

export const parseContent = (content: string): unknown => {
    // Protect apostrophes that occur within words (such as contractions or possessives)
    const protectedContent = content.replace(/(\w)'(\w)/g, "$1<APOS>$2");

    // Replace remaining apostrophes (used as delimiters) with double quotes
    const replaced = protectedContent.replace(/'/g, '"');

    // Restore the protected apostrophes back to literal apostrophes
    const restored = replaced.replace(/<APOS>/g, "'");

    // Fix boolean and null literals to conform to JSON
    const sanitized = restored
        .replace(/:\s*True/g, ": true")
        .replace(/:\s*False/g, ": false")
        .replace(/:\s*None/g, ": null");

    return JSON.parse(sanitized);
};

const updateAnswerAndJson = (
    jsonValue: unknown,
    answerJson: AnswerJson,
    callback: CallbackFunction,
) => {
    if (!jsonValue || typeof jsonValue !== "object") return;

    const jsonObj = jsonValue as { event?: string; content?: string };

    try {
        if (jsonObj.event === "mindmaps") {
            answerJson.mindmaps = parseContent(jsonObj.content ?? "") as unknown;
        }

        if (jsonObj.event === "good_solutions") {
            answerJson.good_solutions = parseContent(jsonObj.content ?? "") as unknown;
        }

        if (jsonObj.event === "salesforce") {
            answerJson.salesforce = parseContent(jsonObj.content ?? "") as unknown;
        }

        if (jsonObj.event === "field_service_reports") {
            answerJson.field_service_reports = parseContent(jsonObj.content ?? "") as unknown;
        }

        if (jsonObj.event === "error") {
            return callback("", jsonObj.content);
        }

        // Handle end event to ensure loading state is properly reset
        if (jsonObj.event === "end") {
            setStreamingState(false);
            return;
        }

        if (jsonObj.event === "response_id") {
            answerJson.response_id = jsonObj.content;
        }
        if (jsonObj.event === "message") {
            return jsonObj.content;
        }
        if (jsonObj.event === "on_tool_start") {
            answerJson.tool_call = true;
            callback("", undefined, undefined, true);
        }
        if (jsonObj.event === "on_tool_end") {
            answerJson.tool_call = false;
            callback("", undefined, undefined, false);
        }

        if (jsonObj.event === "reference") {
            const parsedReference = parseContent(jsonObj.content ?? "") as ReferenceJson;
            if (!answerJson.references) {
                answerJson.references = [];
            }
            // map the parsedReference to the Reference type
            const newReference: Reference = {
                index: parsedReference.index,
                title: parsedReference.title,
                source: decodeURIComponent(parsedReference.source),
                dataSourceType: parsedReference.data_source_type,
            };
            // return if reference is already in the array and source is not empty
            if (
                answerJson.references.some(
                    (existingReference: Reference) =>
                        existingReference.source === newReference.source &&
                        newReference.source !== "",
                )
            ) {
                return;
            }
            answerJson.references.push(newReference);
            callback("", undefined, newReference);
        }
    } catch (error) {
        console.error("Error updating answer and JSON:", error);
    }
};

const prepareReturnValue = (
    answer: string,
    answerJson: AnswerJson,
    updatedMessages: ChatMessage[],
    beforeTimestamp: number,
    afterTimestamp: number,
): ChatMessage => {
    const diffInSeconds = (afterTimestamp - beforeTimestamp) / 1000;
    const formattedDiff = `${diffInSeconds.toFixed(2)} sec.`;
    return {
        answer,
        responseId: answerJson?.response_id,
        content: answer,
        pretrained: answerJson?.pretrained,
        role: "user",
        timestamp: afterTimestamp,
        references: answerJson?.references ?? [],
        suggestions: [],
        toolCall: answerJson.tool_call,
        meta: {
            ...updatedMessages[updatedMessages.length - 1].meta,
            loading: false,
            responseTime: formattedDiff,
        },
    };
};

const handleError = (
    error: unknown,
    setIsloading: (value: boolean) => void,
    setAcquireToken: (value: boolean) => void,
    callback?: CallbackFunction,
) => {
    let errorMessage = "An error occurred";
    if (typeof error === "string") {
        errorMessage = error;
    } else if (error instanceof Error) {
        errorMessage = error.message;
    } else if (typeof error === "object" && error !== null && "message" in error) {
        errorMessage = (error as { message?: string }).message ?? errorMessage;
    }
    setIsloading(false);
    setStreamingState(false);
    callback?.(errorMessage, errorMessage);
    if (errorMessage === "Unauthorized") {
        setAcquireToken(true);
    }
};

const streamingEmitter = new EventTarget();
let currentStreamingState = false;

// Track streaming state by conversation ID
const getStreamingStateKey = () => "chatStreamingStates";

export const setStreamingState = (isStreaming: boolean, conversationId?: string) => {
    currentStreamingState = isStreaming;
    streamingEmitter.dispatchEvent(new CustomEvent("streamingChange", { detail: isStreaming }));

    // If we have a conversation ID, store its state in localStorage
    if (typeof window !== "undefined" && conversationId) {
        try {
            // Get existing states or initialize empty object
            const storedStates = localStorage.getItem(getStreamingStateKey());
            const streamingStates = storedStates ? JSON.parse(storedStates) : {};

            if (isStreaming) {
                // Set this conversation as streaming
                streamingStates[conversationId] = isStreaming;
            } else {
                // Remove this conversation from streaming states
                delete streamingStates[conversationId];
            }

            localStorage.setItem(getStreamingStateKey(), JSON.stringify(streamingStates));
        } catch (error) {
            console.error("Error updating streaming state:", error);
        }
    }
};

const getStreamingState = (conversationId?: string): boolean => {
    // If we have a conversation ID, check its specific state
    if (typeof window !== "undefined" && conversationId) {
        try {
            const storedStates = localStorage.getItem(getStreamingStateKey());
            if (storedStates) {
                const streamingStates = JSON.parse(storedStates);
                return !!streamingStates[conversationId];
            }
        } catch (error) {
            console.error("Error retrieving streaming state:", error);
        }
    }

    return currentStreamingState;
};

// Clean up any stale streaming states on load
const resetAllStreamingStates = () => {
    if (typeof window !== "undefined") {
        try {
            localStorage.setItem(getStreamingStateKey(), JSON.stringify({}));
        } catch (error) {
            console.error("Error resetting streaming states:", error);
        }
    }
    currentStreamingState = false;
    streamingEmitter.dispatchEvent(new CustomEvent("streamingChange", { detail: false }));
};

// Initialize: reset stale states on module load
if (typeof window !== "undefined") {
    // Only reset in browser context
    resetAllStreamingStates();
}

export const useApiStreamingState = (conversationId?: string) => {
    const [isStreaming, setIsStreaming] = useState(() => getStreamingState(conversationId));

    useEffect(() => {
        const handleStreamingChange = (event: Event) => {
            const customEvent = event as CustomEvent<boolean>;
            setIsStreaming(customEvent.detail);
        };

        streamingEmitter.addEventListener("streamingChange", handleStreamingChange);
        return () => {
            streamingEmitter.removeEventListener("streamingChange", handleStreamingChange);
        };
    }, []);

    return isStreaming;
};

export const useChatCompletion = (_chatId?: IndexableType, isChatWithDocument?: boolean) => {
    const { instance } = useMsal();
    const account = useAccount();
    const apiKey = account?.idToken;
    const chatId = useChatId() || _chatId;
    const { selectedTab } = useTab();
    const [_, setAcquireToken] = useLocalStorage("acquireToken", false);
    const [isloading, setIsloading] = useState(false);
    const settings = useLiveQuery(async () => {
        return db.settings.where({ id: "general" }).first();
    });

    const isStreamEnabled = true;

    const messages = useLiveQuery(() => {
        if (!chatId) return [];
        return db.messages.where("chatId").equals(chatId).sortBy("createdAt");
    }, [chatId]);

    // Reset loading state whenever chatId changes (either new chat or navigation between chats)
    useEffect(() => {
        setIsloading(false);
        setStreamingState(false);
    }, []);

    // Feature flag for onboarding - ensure we get a boolean value
    const onboardingEnabled = Boolean(useFeature("ONBOARDING"));

    // Get search preferences from localStorage
    const storedPreferences = localStorage.getItem("chatPreferences");
    const chatPreferences = storedPreferences
        ? JSON.parse(storedPreferences)
        : {
              selectedProductLines: [],
              selectedDatasources: [],
          };

    // Function to continue or start a new conversation
    const continueConversation = useCallback(
        async (existingChatId?: IndexableType) => {
            if (existingChatId) {
                return existingChatId;
            }

            const tabToUse = onboardingEnabled ? "combined" : selectedTab;

            // Create a new chat and persist its ID
            const newChatId = await ChatEntity._(tabToUse as ChatVectorIndex).add();
            return newChatId;
        },
        [selectedTab, onboardingEnabled],
    );

    const send = useCallback(
        async (
            newMessages: ChatMessageParams[],
            callback: CallbackFunction,
            metadata?: Record<string, string>[],
            messageChatId?: string | IndexableType,
            productLine?: string,
            documentType?: string,
        ) => {
            if (messages && messages[messages.length - 1]?.done === false) return;

            const beforeTimestamp = Date.now();
            // Convert MessageEntity[] to ChatMessage[] before processing
            const convertedMessages = messages?.map(
                (msg): ChatMessage => ({
                    content: msg.content ?? "",
                    answer: msg.content ?? "",
                    role: msg.role,
                    timestamp: msg.createdAt ?? Date.now(),
                    references: msg.references ?? [],
                    suggestions: msg.suggestions ?? [],
                    toolCall: msg.toolCall,
                    meta: {
                        loading: false,
                        responseTime: "",
                        chunks: [],
                    },
                }),
            );

            const updatedMessages = prepareUpdatedMessages(messages, newMessages);

            // Get user preferences for API request
            const selectedProductLines = chatPreferences?.selectedProductLines;
            const userAuthorizedDatasources = chatPreferences?.selectedDatasources?.map(
                (ds: string) => (ds === "All" ? "combined" : ds.toLowerCase()),
            );

            const payload = preparePayload(
                newMessages,
                convertedMessages, // Use converted messages here
                chatId?.toString() ?? messageChatId?.toString(),
                account,
                metadata,
                productLine,
                selectedProductLines,
                userAuthorizedDatasources,
            );

            const { CHAT_HEADERS, CHAT_ENDPOINT_STREAM } = prepareRequestDetails(
                apiKey ?? "",
                isChatWithDocument && documentType ? TAB_INDEX_MAPPING[documentType] : selectedTab, // Use documentType if provided, otherwise use selectedTab
                isStreamEnabled,
                onboardingEnabled,
                isChatWithDocument,
            );

            try {
                setIsloading(true);
                // Set streaming state with conversation ID at the start of stream
                setStreamingState(true, payload ? JSON.parse(payload)?.conversation_id : undefined);
                const response = await makeApiRequest(CHAT_ENDPOINT_STREAM, CHAT_HEADERS, payload);
                const { answer, answerJson } = await processResponse(
                    response,
                    isStreamEnabled,
                    callback,
                );

                const afterTimestamp = Date.now();
                const returnValue = prepareReturnValue(
                    answer,
                    answerJson,
                    updatedMessages,
                    beforeTimestamp,
                    afterTimestamp,
                );

                callback?.(returnValue);
                setIsloading(false);
                // Reset streaming state with conversation ID at the end of stream
                setStreamingState(
                    false,
                    payload ? JSON.parse(payload)?.conversation_id : undefined,
                );
            } catch (error) {
                // Reset streaming state with conversation ID on error
                setStreamingState(
                    false,
                    payload ? JSON.parse(payload)?.conversation_id : undefined,
                );
                handleError(error, setIsloading, setAcquireToken, callback);
            }
        },
        [
            apiKey,
            chatId,
            messages,
            selectedTab,
            onboardingEnabled,
            chatPreferences,
            isChatWithDocument,
            setAcquireToken,
            account,
        ],
    );

    const navigate = useNavigate();

    const { chatMetadata } = useMetadata();

    const sendMessage = useCallback(
        async (
            messageContent: string,
            isResend = false,
            chatId?: IndexableType,
            metadataFilter?: { key: string; value: string }[],
            productLine?: string,
            documentType?: string,
        ) => {
            let messageChatId: IndexableType;
            if (!chatId) {
                // If no chat ID provided, create a new chat
                messageChatId = await continueConversation();
                navigate({ to: `/chats/${messageChatId}`, replace: true });
            } else {
                messageChatId = chatId;
            }

            try {
                setIsloading(true);
                const messagesSending = await makeMessagesSendingRequest({
                    chatId: messageChatId.toString(),
                    content: messageContent,
                });
                const userMessageId = await MessageEntity._()
                    .setChatId(messageChatId)
                    .setRole(ChatRole.USER)
                    .setContent(messageContent)
                    .add();

                const assistantMessageReceivedId = await MessageEntity._()
                    .setChatId(messageChatId)
                    .setRole(ChatRole.ASSISTANT)
                    .setContent("")
                    .setRepliedId(userMessageId as string) // Add type assertion here
                    .add();
                await send(
                    messagesSending,
                    (async (
                        chatMessage: ChatMessage | string,
                        error?: string,
                        reference?: Reference,
                        toolCall?: boolean,
                    ) => {
                        await db.messages
                            .where({ id: assistantMessageReceivedId })
                            .modify((message) => {
                                if (reference) message.references.push(reference);
                                else if (toolCall !== undefined) message.toolCall = toolCall;
                                else if (typeof chatMessage === "string")
                                    message.content = chatMessage;
                                else {
                                    message.content = chatMessage.content;
                                    message.responseId = chatMessage.responseId || ""; // Add default value
                                    message.pretrained = chatMessage.pretrained || ""; // Add default value
                                    message.suggestions = chatMessage.suggestions || [];
                                    message.toolCall = chatMessage.toolCall;
                                }
                                message.hasError = Boolean(error);
                                message.error = error || "";
                            });
                    }) as CallbackFunction,
                    metadataFilter || chatMetadata,
                    messageChatId,
                    productLine,
                    documentType,
                );
            } catch (error: unknown) {
                const errMsg =
                    typeof error === "string"
                        ? error
                        : typeof error === "object" && error !== null && "message" in error
                          ? ((error as { message?: string }).message ?? "Error Happened")
                          : "Error Happened";
                notifications.show({
                    title: "Error",
                    color: "red",
                    message: errMsg,
                });
            } finally {
                db.chats.update(messageChatId, {
                    updatedAt: new Date(),
                    totalMessages: await db.messages.where({ chatId: messageChatId }).count(),
                });
            }
        },
        [continueConversation, navigate, send, chatMetadata],
    );

    return {
        isloading,
        setIsloading,
        send,
        sendMessage,
    };
};
