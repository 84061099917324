import { useAccount, useMsal } from "@azure/msal-react";
import { Box, Group, ScrollArea, Text, UnstyledButton } from "@mantine/core";
import {
    IconBulb,
    IconFile,
    IconFileAnalytics,
    IconFileReport,
    type IconFiles,
    IconMail,
    IconNotes,
    IconPaperclip,
    IconSitemap,
} from "@tabler/icons-react";
import { useRef } from "react";
import SalesforceSvg from "../assets/salesforce.svg";
import type { Reference } from "../db";
import { ARTIFACT_EXTENSIONS, getFullUrl, handleArtifactClick } from "../utils/artifactHandler";

const IconSalesforce = () => {
    return <img src={SalesforceSvg} alt="Salesforce" style={{ width: 14, height: 14 }} />;
};

// Document type mapping for icons and labels
const documentTypeConfig: Record<string, { icon: typeof IconFiles; label: string }> = {
    field_service_reports: { icon: IconFileReport, label: "Field Service Report" },
    salesforce: { icon: IconSalesforce, label: "Salesforce Case" },
    rds_reports: { icon: IconFileAnalytics, label: "RDS Report" },
    ads_good_solutions: { icon: IconBulb, label: "ADS Solution" },
    drawings: { icon: IconNotes, label: "Drawing" },
    mindmaps: { icon: IconSitemap, label: "Mind Map" },
    useful_info: { icon: IconFile, label: "Useful Info" },
    support_emails: { icon: IconMail, label: "Support Email" },
    support_email_attachment: { icon: IconPaperclip, label: "Email Attachment" },
};

const getFileExtension = (url: string): string => {
    // Check if it's a web URL without a file extension
    if (url.startsWith("http") && !url.split("/").pop()?.includes(".")) {
        return "URL";
    }
    const lowerCaseUrl = url.toLowerCase();
    for (const ext of ARTIFACT_EXTENSIONS) {
        if (lowerCaseUrl.endsWith(ext)) {
            return ext.toUpperCase().substring(1);
        }
    }
    return "URL"; // Default to "URL"
};

export const ReferenceViewer = ({ references }: { references: Reference[] }) => {
    const viewport = useRef<HTMLDivElement>(null);

    if (!references.length) return null;

    return (
        <Box h="100%">
            <ScrollArea.Autosize
                viewportRef={viewport}
                scrollbarSize={4}
                type="hover"
                sx={{ height: "100%", maxHeight: "calc(100vh - 280px)" }}
                placeholder=""
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
            >
                <Box
                    sx={(theme) => ({
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: theme.spacing.md,
                        padding: `${theme.spacing.xs}px 0`,
                    })}
                >
                    {references.map((thisReference, index) => (
                        <Box
                            key={`${thisReference.title}-${index}`}
                            sx={{ position: "relative", height: "100%" }}
                        >
                            <ReferenceTooltip reference={thisReference} />
                        </Box>
                    ))}
                </Box>
            </ScrollArea.Autosize>
        </Box>
    );
};

export const ReferenceTooltip = ({
    reference,
}: {
    reference: Reference;
}) => {
    const { instance } = useMsal();
    const account = useAccount();
    const apiKey = account?.idToken;
    const docConfig = documentTypeConfig[reference.dataSourceType];

    return (
        <UnstyledButton
            component="a"
            href={reference.source ? getFullUrl(reference.source) : undefined}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => handleArtifactClick(e, reference.source || "", apiKey, instance)}
            title={reference.title}
            sx={(theme) => ({
                padding: theme.spacing.sm,
                borderRadius: theme.radius.sm,
                backgroundColor:
                    theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
                minHeight: 70,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                position: "relative",
                border: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`,
                "&:hover": {
                    backgroundColor: reference.source
                        ? theme.colorScheme === "dark"
                            ? theme.colors.dark[5]
                            : theme.colors.gray[1]
                        : "inherit",
                    cursor: reference.source ? "pointer" : "default",
                    boxShadow: reference.source ? theme.shadows.xs : "none",
                },
                opacity: reference.source ? 1 : 0.5,
                width: "100%",
                transition: "background-color 150ms ease, box-shadow 150ms ease",
            })}
        >
            <Text
                size="xs"
                weight={500}
                sx={{
                    width: "100%",
                    marginBottom: "auto",
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 8,
                }}
            >
                {typeof reference.index === "number" && (
                    <Text
                        component="span"
                        sx={(theme) => ({
                            color:
                                theme.colorScheme === "dark"
                                    ? theme.colors.dark[0]
                                    : theme.colors.gray[7],
                            backgroundColor:
                                theme.colorScheme === "dark"
                                    ? theme.colors.dark[4]
                                    : theme.colors.gray[2],
                            padding: "2px 6px",
                            borderRadius: theme.radius.sm,
                            fontSize: "9px",
                            letterSpacing: "0.5px",
                            flexShrink: 0,
                            lineHeight: 1.5,
                        })}
                    >
                        #{reference.index}
                    </Text>
                )}
                <Text
                    component="span"
                    sx={{
                        lineHeight: 1.5,
                        flex: 1,
                    }}
                    lineClamp={2}
                >
                    {reference.title}
                </Text>
            </Text>

            <Box
                sx={(theme) => ({
                    marginTop: theme.spacing.xs,
                    paddingTop: theme.spacing.xs,
                    borderTop: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: theme.spacing.xs,
                })}
            >
                {docConfig && (
                    <Group spacing={4} sx={{ flex: 1, minWidth: 0 }}>
                        <docConfig.icon size={14} style={{ flexShrink: 0 }} />
                        <Text
                            size="xs"
                            color="dimmed"
                            sx={{
                                fontSize: "10px",
                                letterSpacing: "0.3px",
                                lineHeight: 1.5,
                            }}
                            truncate
                        >
                            {docConfig.label}
                        </Text>
                    </Group>
                )}
                {getFileExtension(reference.source) && (
                    <Text
                        sx={(theme) => ({
                            padding: "2px 6px",
                            borderRadius: theme.radius.sm,
                            backgroundColor:
                                theme.colorScheme === "dark"
                                    ? theme.colors.dark[4]
                                    : theme.colors.gray[2],
                            color:
                                theme.colorScheme === "dark"
                                    ? theme.colors.dark[0]
                                    : theme.colors.gray[7],
                            fontSize: "10px",
                            letterSpacing: "0.5px",
                            flexShrink: 0,
                            lineHeight: 1.5,
                        })}
                    >
                        {getFileExtension(reference.source)}
                    </Text>
                )}
            </Box>
        </UnstyledButton>
    );
};
