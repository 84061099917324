import { useAccount, useMsal } from "@azure/msal-react";
import { Text, Tooltip } from "@mantine/core";
import type { Reference } from "../db";
import { useApiStreamingState } from "../hooks/useChatCompletion";
import { getFullUrl, handleArtifactClick } from "../utils/artifactHandler";
import { ReferenceTooltip } from "./ReferenceViewer";

interface InlineReferenceProps {
    reference: Reference;
    index: string;
    chatId?: string;
}

export const InlineReference = ({ reference, index, chatId }: InlineReferenceProps) => {
    const { instance } = useMsal();
    const account = useAccount();
    const apiKey = account?.idToken;
    const isStreaming = useApiStreamingState(chatId);

    return (
        <Tooltip
            disabled={isStreaming}
            closeDelay={600}
            openDelay={100}
            withArrow
            position="top"
            styles={{
                tooltip: {
                    pointerEvents: "auto",
                    backgroundColor: "transparent",
                    padding: 0,
                    boxShadow: "none",
                    maxWidth: "350px",
                    minWidth: "200px",
                },
            }}
            label={<ReferenceTooltip reference={reference} />}
        >
            {reference.source ? (
                <Text
                    component="a"
                    href={getFullUrl(reference.source)}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                        e.preventDefault();
                        handleArtifactClick(e, reference.source, apiKey, instance);
                    }}
                    sx={(theme) => ({
                        display: "inline-block",
                        backgroundColor:
                            theme.colorScheme === "dark"
                                ? theme.colors.dark[4]
                                : theme.colors.gray[1],
                        padding: "2px 6px",
                        margin: "2px",
                        borderRadius: theme.radius.sm,
                        cursor: "pointer",
                        textDecoration: "none",
                        fontSize: "0.6rem",
                        color: "inherit",
                        "&:hover": {
                            backgroundColor: theme.colors.purple?.[1],
                            color: "#fff",
                        },
                    })}
                >
                    {index}
                </Text>
            ) : (
                <Text
                    component="span"
                    sx={(theme) => ({
                        display: "inline-block",
                        backgroundColor:
                            theme.colorScheme === "dark"
                                ? theme.colors.dark[4]
                                : theme.colors.gray[1],
                        padding: "2px 6px",
                        margin: "2px",
                        borderRadius: theme.radius.sm,
                        cursor: "pointer",
                        textDecoration: "none",
                        fontSize: "0.6rem",
                        color: "inherit",
                        "&:hover": {
                            backgroundColor: theme.colors.purple?.[1],
                            color: "#fff",
                        },
                    })}
                >
                    {index}
                </Text>
            )}
        </Tooltip>
    );
};
