import { useFeature } from "flagged";
import type React from "react";
import { type ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";
import {
    type ChatVectorIndex,
    type ChatVectorIndexShorthand,
    SF,
    chatVectorIndex,
    updateSettings,
} from "../db";

interface TabContextType {
    selectedTab: ChatVectorIndex;
    selectedTabShorthand: ChatVectorIndexShorthand;
    setSelectedTab: (tab: ChatVectorIndex) => void;
    selectedSearchTab: string;
    setSelectedSearchTab: (tab: string) => void;
}

const defaultContextValue: TabContextType = {
    selectedTab: "combined",
    selectedTabShorthand: SF,
    setSelectedTab: () => {},
    selectedSearchTab: "All",
    setSelectedSearchTab: () => {},
};

const TabContext = createContext(defaultContextValue);

interface TabProviderProps {
    children: ReactNode;
}

export const TabProvider: React.FC<TabProviderProps> = ({ children }) => {
    const isMindamaps = useFeature("MINDMAPS");
    const isOnboarding = useFeature("ONBOARDING");
    const [selectedTab, setSelectedTab] = useState<ChatVectorIndex>(() => {
        // When onboarding is enabled, default to "combined" tab
        // Otherwise, use the saved tab from localStorage or "combined" as fallback
        if (isOnboarding) return "combined" as ChatVectorIndex;
        const savedTab = localStorage.getItem("selectedTab");
        return (savedTab as ChatVectorIndex) || "combined";
    });
    const [selectedSearchTab, setSelectedSearchTab] = useState<string>(() => {
        const preferences = localStorage.getItem("chatPreferences");
        return preferences ? JSON.parse(preferences).searchTab : "All";
    });

    useEffect(() => {
        if (isMindamaps || isOnboarding) setSelectedTab("combined");
    }, [isMindamaps, isOnboarding]);

    useEffect(() => {
        localStorage.setItem("selectedTab", selectedTab);
        setSelectedTab(selectedTab);
        updateSettings("chat_vector_index", selectedTab);
    }, [selectedTab]);

    useEffect(() => {
        localStorage.setItem("selectedSearchTab", selectedSearchTab);
    }, [selectedSearchTab]);

    const selectedTabShorthand: ChatVectorIndexShorthand = isOnboarding
        ? "combined"
        : (chatVectorIndex.find((index) => index.value.includes(selectedTab))?.shorthand ??
          "combined");

    const value = useMemo(
        () => ({
            selectedTab,
            selectedTabShorthand,
            setSelectedTab,
            selectedSearchTab,
            setSelectedSearchTab,
        }),
        [selectedTab, selectedTabShorthand, selectedSearchTab],
    );

    return <TabContext.Provider value={value}>{children}</TabContext.Provider>;
};

export const useTab = () => useContext(TabContext);
