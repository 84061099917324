import { useAccount, useMsal } from "@azure/msal-react";
import {
    Badge,
    Box,
    Card,
    Container,
    Flex,
    Grid,
    Group,
    Loader,
    Paper,
    Stack,
    Text,
    Transition,
} from "@mantine/core";
import { useLocation } from "@tanstack/react-router";
import { useEffect, useRef, useState } from "react";
import { MessageItem } from "src/components/MessageItem";
import { useTab } from "src/contexts/tabContext";
import type { MessageEntity, Reference } from "src/db";
import { useApiStreamingState, useChatCompletion } from "src/hooks/useChatCompletion";
import { handleArtifactClick } from "src/utils/artifactHandler";

export function SearchItems({
    chatId,
    messages,
    metadataFilter,
}: {
    readonly chatId?: string;
    readonly messages?: MessageEntity[];
    readonly metadataFilter?: { key: string; value: string }[];
}) {
    const { instance } = useMsal();
    const account = useAccount();
    const apiKey = account?.idToken;
    const location = useLocation();
    const chatCompletion = useChatCompletion(chatId);
    const isStreaming = useApiStreamingState(chatId);
    const [content, setContent] = useState("");
    const ref = useRef<HTMLInputElement>(null);

    const { selectedSearchTab } = useTab();
    const [references, setReferences] = useState<Reference[]>([]);
    const [productLine, setProductLine] = useState<string>(
        () => localStorage.getItem("selectedProductLine") || "",
    );

    const isLoading = chatCompletion.isloading || isStreaming;

    // Track references from messages
    useEffect(() => {
        if (messages) {
            const allReferences = messages.reduce((acc: Reference[], message) => {
                if (message.references) {
                    acc.push(...message.references);
                }
                return acc;
            }, []);

            setReferences(allReferences);
        }
    }, [messages]);

    const groupedRefByDatasource = references.reduce((acc: { [key: string]: Reference[] }, ref) => {
        const type = ref.dataSourceType || "other";
        if (!acc[type]) {
            acc[type] = [];
        }
        acc[type].push(ref);
        return acc;
    }, {});

    const hasContent = messages?.some((m) => m.content && m.content.trim() !== "");
    const hasReferences = Object.keys(groupedRefByDatasource).length > 0;

    return (
        <Box mt={30}>
            <Container fluid px="xl">
                <Transition mounted={true} transition="slide-down" duration={400}>
                    {(styles) => (
                        <Flex
                            gap="md"
                            align="flex-start"
                            style={{
                                ...styles,
                                minHeight: "auto",
                                transition: "all 0.4s ease-in-out",
                            }}
                        >
                            {/* Initial colon with spinner */}
                            {isLoading && !hasContent && (
                                <Box sx={{ flex: "0 0 35%", maxWidth: "35%", minHeight: 0 }}>
                                    <Paper shadow="xs" p="md">
                                        <Flex align="center" gap="md">
                                            <Loader size="sm" />
                                            <Text>Generating response...</Text>
                                        </Flex>
                                    </Paper>
                                </Box>
                            )}

                            {/* Main content container */}
                            <Flex
                                sx={{
                                    flex: 1,
                                    minHeight: 0,
                                    visibility: hasContent || hasReferences ? "visible" : "hidden",
                                }}
                            >
                                {/* Messages Column - Only shows when hasContent */}
                                {hasContent && (
                                    <Box
                                        sx={{
                                            flex: "0 0 55%",
                                            maxWidth: "55%",
                                            minHeight: 0,
                                            marginRight: "2%",
                                            opacity: hasContent ? 1 : 0,
                                            transition: "opacity 0.2s",
                                        }}
                                    >
                                        <Paper shadow="xs" p="md">
                                            <Stack spacing="xs">
                                                {messages?.map((message, i) => (
                                                    <MessageItem
                                                        key={message.id.toString()}
                                                        message={message}
                                                        // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                                                        sendFeedbackToApi={(() => {}) as any}
                                                        retry={(newMessage: string) => {
                                                            setContent("");
                                                            return chatCompletion.sendMessage(
                                                                newMessage,
                                                                true,
                                                                chatId,
                                                                metadataFilter,
                                                            );
                                                        }}
                                                        nextAssistantMessage={
                                                            messages[i + 1] && i > 1
                                                                ? messages[i + 1]
                                                                : undefined
                                                        }
                                                        inProgress={isStreaming}
                                                    />
                                                ))}
                                            </Stack>
                                        </Paper>
                                    </Box>
                                )}

                                {/* References Columns - Only shows when hasReferences */}
                                {hasReferences && (
                                    <Box
                                        sx={{
                                            flex: "0 0 43%",
                                            maxWidth: "43%",
                                            minHeight: 0,
                                            opacity: hasReferences ? 1 : 0,
                                            transition: "opacity 0.2s",
                                        }}
                                    >
                                        <Grid gutter={4} sx={{ margin: 0 }}>
                                            {/* Second Column */}
                                            <Grid.Col span={6} p={0} pr={2}>
                                                <Paper shadow="xs" p="md">
                                                    <Stack spacing="xs">
                                                        {Object.entries(groupedRefByDatasource).map(
                                                            ([type, refs]) =>
                                                                refs.map(
                                                                    (ref, index) =>
                                                                        index % 2 === 0 && (
                                                                            <Card
                                                                                key={`${ref.title}-${index}`}
                                                                                shadow="sm"
                                                                                p={0}
                                                                                radius="md"
                                                                                withBorder
                                                                                component="a"
                                                                                href={ref.source}
                                                                                sx={{
                                                                                    cursor: "pointer",
                                                                                    "&:hover": {
                                                                                        backgroundColor:
                                                                                            "rgba(0, 0, 0, 0.03)",
                                                                                    },
                                                                                }}
                                                                                onClick={(e) =>
                                                                                    handleArtifactClick(
                                                                                        e,
                                                                                        ref.source,
                                                                                        apiKey,
                                                                                        instance,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <Card.Section>
                                                                                    <img
                                                                                        src={
                                                                                            new URL(
                                                                                                "../assets/basic-report-se.png",
                                                                                                import.meta
                                                                                                    .url,
                                                                                            ).href
                                                                                        }
                                                                                        alt="Base Reports"
                                                                                        style={{
                                                                                            width: "100%",
                                                                                            height: "200px",
                                                                                            objectFit:
                                                                                                "cover",
                                                                                        }}
                                                                                    />
                                                                                </Card.Section>
                                                                                <Box
                                                                                    p="md"
                                                                                    bg="white"
                                                                                >
                                                                                    <Text
                                                                                        size="sm"
                                                                                        weight={500}
                                                                                        mb="xs"
                                                                                    >
                                                                                        {ref.title}
                                                                                    </Text>
                                                                                    <Group
                                                                                        spacing={8}
                                                                                    >
                                                                                        <Badge
                                                                                            color="purple"
                                                                                            variant="light"
                                                                                            sx={{
                                                                                                color: "white",
                                                                                                fontWeight: 500,
                                                                                                fontSize:
                                                                                                    "0.6rem",
                                                                                                height: "0.9rem",
                                                                                                lineHeight:
                                                                                                    "0.7rem",
                                                                                                paddingLeft:
                                                                                                    "0.8rem",
                                                                                                paddingRight:
                                                                                                    "0.8rem",
                                                                                                paddingTop:
                                                                                                    "0.1rem",
                                                                                                paddingBottom:
                                                                                                    "0.1rem",
                                                                                            }}
                                                                                        >
                                                                                            {type
                                                                                                ?.split(
                                                                                                    "_",
                                                                                                )
                                                                                                .join(
                                                                                                    " ",
                                                                                                )}
                                                                                        </Badge>
                                                                                    </Group>
                                                                                </Box>
                                                                            </Card>
                                                                        ),
                                                                ),
                                                        )}
                                                    </Stack>
                                                </Paper>
                                            </Grid.Col>

                                            {/* Third Column */}
                                            <Grid.Col span={6} p={0} pl={2}>
                                                <Paper shadow="xs" p="md">
                                                    <Stack spacing="xs">
                                                        {Object.entries(groupedRefByDatasource).map(
                                                            ([type, refs]) =>
                                                                refs.map(
                                                                    (ref, index) =>
                                                                        index % 2 === 1 && (
                                                                            <Card
                                                                                key={`${ref.title}-${index}`}
                                                                                shadow="sm"
                                                                                p={0}
                                                                                radius="md"
                                                                                withBorder
                                                                                component="a"
                                                                                href={ref.source}
                                                                                sx={{
                                                                                    cursor: "pointer",
                                                                                    "&:hover": {
                                                                                        backgroundColor:
                                                                                            "rgba(0, 0, 0, 0.03)",
                                                                                    },
                                                                                }}
                                                                                onClick={(e) =>
                                                                                    handleArtifactClick(
                                                                                        e,
                                                                                        ref.source,
                                                                                        apiKey,
                                                                                        instance,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <Card.Section>
                                                                                    <img
                                                                                        src={
                                                                                            new URL(
                                                                                                "../assets/basic-report-se.png",
                                                                                                import.meta
                                                                                                    .url,
                                                                                            ).href
                                                                                        }
                                                                                        alt="Base Reports"
                                                                                        style={{
                                                                                            width: "100%",
                                                                                            height: "200px",
                                                                                            objectFit:
                                                                                                "cover",
                                                                                        }}
                                                                                    />
                                                                                </Card.Section>
                                                                                <Box
                                                                                    p="md"
                                                                                    bg="white"
                                                                                >
                                                                                    <Text
                                                                                        size="sm"
                                                                                        weight={500}
                                                                                        mb="xs"
                                                                                    >
                                                                                        {ref.title}
                                                                                    </Text>
                                                                                    <Group
                                                                                        spacing={8}
                                                                                    >
                                                                                        <Badge
                                                                                            color="purple"
                                                                                            variant="light"
                                                                                            sx={{
                                                                                                color: "white",
                                                                                                fontWeight: 500,
                                                                                                fontSize:
                                                                                                    "0.6rem",
                                                                                                height: "0.9rem",
                                                                                                lineHeight:
                                                                                                    "0.7rem",
                                                                                                paddingLeft:
                                                                                                    "0.8rem",
                                                                                                paddingRight:
                                                                                                    "0.8rem",
                                                                                                paddingTop:
                                                                                                    "0.1rem",
                                                                                                paddingBottom:
                                                                                                    "0.1rem",
                                                                                            }}
                                                                                        >
                                                                                            {type
                                                                                                ?.split(
                                                                                                    "_",
                                                                                                )
                                                                                                .join(
                                                                                                    " ",
                                                                                                )}
                                                                                        </Badge>
                                                                                    </Group>
                                                                                </Box>
                                                                            </Card>
                                                                        ),
                                                                ),
                                                        )}
                                                    </Stack>
                                                </Paper>
                                            </Grid.Col>
                                        </Grid>
                                    </Box>
                                )}
                            </Flex>
                        </Flex>
                    )}
                </Transition>
            </Container>
        </Box>
    );
}
