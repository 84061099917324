import type { TourStep } from "./GuidedTour";

/**
 * Defines all steps for the application's guided tour
 */
export const tourSteps: TourStep[] = [
    {
        target: 'button[data-tour="new-chat"]',
        title: "Start a New Chat",
        description: "Click here to start a new conversation with the AI assistant.",
        position: "bottom",
    },
    {
        target: 'input[data-tour="search"]',
        title: "Search Your Chats",
        description: "Quickly find your previous chats by typing here.",
        position: "bottom",
    },
    {
        target: 'div[data-tour="chat-history"]',
        title: "Chat History",
        description: "View and manage all your previous conversations here.",
        position: "right",
    },
    {
        target: 'a[data-tour="documents-viewer"]',
        title: "Documents Viewer",
        description: "Explore all documents for reference & also chat with them.",
        position: "bottom",
    },
    {
        target: "#chat-input",
        title: "Send a Message",
        description:
            "Type your question here (or choose from the suggestions) and press Enter to send it.",
        position: "top",
    },
    {
        target: 'button[data-testid="send-button"]',
        title: "Send Button",
        description: "Click this button to send your message to the AI assistant.",
        position: "left",
    },
    {
        target: 'div[data-tour="database-action"]',
        title: "Database Actions",
        description: "Access functions like import and export or delete chats.",
        position: "top",
    },
];

/**
 * Helper to check if a guided tour should be shown to a user
 * @param tourId Unique identifier for the tour
 * @returns Boolean indicating if the tour should be shown
 */
export function shouldShowTour(tourId = "main-tour"): boolean {
    // Check if tour has been completed before
    const completedTours = JSON.parse(localStorage.getItem("completedTours") || "[]");
    return !completedTours.includes(tourId);
}

/**
 * Helper to mark a tour as completed
 * @param tourId Unique identifier for the tour
 */
export function markTourAsCompleted(tourId = "main-tour"): void {
    const completedTours = JSON.parse(localStorage.getItem("completedTours") || "[]");
    if (!completedTours.includes(tourId)) {
        completedTours.push(tourId);
        localStorage.setItem("completedTours", JSON.stringify(completedTours));
    }
}

/**
 * Helper to reset a tour (mark it as not completed)
 * @param tourId Unique identifier for the tour
 */
export function resetTour(tourId = "main-tour"): void {
    const completedTours = JSON.parse(localStorage.getItem("completedTours") || "[]");
    const updatedTours = completedTours.filter((id: string) => id !== tourId);
    localStorage.setItem("completedTours", JSON.stringify(updatedTours));
}
