import "dexie-export-import";

import Dexie, { type IndexableType, type Table } from "dexie";
import { nanoid } from "nanoid";

export interface DebugEvent {
    timestamp: number;
    type: string;
    data: unknown;
}
interface Settings {
    id: "general";
    openAiApiKey?: string;
    openAiModel?: string;
    debug?: boolean;
    sim_score_threshold?: number;
    sim_k_limit?: number;
    stream?: boolean;
    suggestions?: boolean;
    use_agents?: boolean;
    chat_vector_index?: ChatVectorIndex;
}

export enum ChatRole {
    SYSTEM = "system",
    USER = "user",
    ASSISTANT = "assistant",
}

export const SF = "sf";
export const FSR = "fsr";
export const RDS = "rds";
export const MINDMAPS = "mindmaps";
export const DRAWINGS = "drawings";
export const EMAILS = "emails";
export const COMBINED = "combined";
export const SALESORDERS = "salesorders";
export const ADS_GOOD_SOLUTIONS = "ads_good_solutions";
export const CTS_USER_CHAT = "cts_user_chat";

export type ChatVectorIndex =
    | "salesforce"
    | "cts_knowledge_search_chunkwise"
    | "field_service_reports"
    | "field_service_reports_sg"
    | "combined"
    | "support_emails"
    | "useful_info"
    | "drawings"
    | "salesorders"
    | "rds_reports"
    | "ads_good_solutions"
    | "mindmaps"
    | "cts_user_chat";
export type ChatVectorIndexShorthand =
    | typeof SF
    | typeof FSR
    | typeof COMBINED
    | typeof DRAWINGS
    | typeof EMAILS
    | typeof SALESORDERS
    | "uinfo"
    | typeof RDS
    | typeof MINDMAPS
    | typeof ADS_GOOD_SOLUTIONS

    // TODO This should not be part of this type since it is not a datasource
    // that can be used for the chat.
    | typeof CTS_USER_CHAT;

type FEATURE_FLAG =
    | "SALESFORCE"
    | "FIELD_SERVICE_REPORTS"
    | "COMBINED"
    | "SUPPORT_EMAILS"
    | "USEFUL_INFO"
    | "SALESORDERS"
    | "MINDMAPS"
    | "DRAWINGS"
    | "RDS_REPORTS"
    | "CHAT_WITH_REPORTS"
    | "ADS_GOOD_SOLUTIONS";

export const chatVectorIndex: {
    label: string;
    value: ChatVectorIndex[];
    shorthand: ChatVectorIndexShorthand;
    feature: FEATURE_FLAG;
}[] = [
    {
        label: "Salesforce Cases",
        value: ["salesforce", "cts_knowledge_search_chunkwise"],
        shorthand: SF,
        feature: "SALESFORCE",
    },
    {
        label: "Field Service Reports",
        value: ["field_service_reports", "field_service_reports_sg"],
        shorthand: FSR,
        feature: "FIELD_SERVICE_REPORTS",
    },
    {
        label: "Combined",
        value: ["combined"],
        shorthand: "combined",
        feature: "COMBINED",
    },
    {
        label: "Emails",
        value: ["support_emails"],
        shorthand: "emails",
        feature: "SUPPORT_EMAILS",
    },
    {
        label: "SGT Sales Orders",
        value: ["salesorders"],
        shorthand: SALESORDERS,
        feature: "SALESORDERS",
    },
    {
        label: "SGT Useful Info",
        value: ["useful_info"],
        shorthand: "uinfo",
        feature: "USEFUL_INFO",
    },
    {
        label: "SGT Mindmaps",
        value: ["mindmaps"],
        shorthand: "mindmaps",
        feature: "MINDMAPS",
    },
    {
        label: "Engineering Drawings",
        value: ["drawings"],
        shorthand: DRAWINGS,
        feature: "DRAWINGS",
    },
    {
        label: "RDS Reports",
        value: ["rds_reports"],
        shorthand: RDS,
        feature: "RDS_REPORTS",
    },
    {
        label: "ADS Good Solutions",
        value: ["ads_good_solutions"],
        shorthand: ADS_GOOD_SOLUTIONS,
        feature: "ADS_GOOD_SOLUTIONS",
    },
];

export class ChatEntity {
    id!: string;
    description!: string;
    totalTokens!: number;
    createdAt!: number;
    vectorIndex!: ChatVectorIndex;

    static _(vectorIndex: ChatVectorIndex) {
        const record = new ChatEntity();
        record.id = `${chatVectorIndex.find((v) => v.value.includes(vectorIndex))?.shorthand ?? "combined"}_${nanoid()}`;
        record.description = `Chat-${record.id.slice(0, 8)}`;
        record.totalTokens = 0;
        record.createdAt = new Date().getTime();
        record.vectorIndex = vectorIndex;

        return record;
    }

    setId(id: string) {
        this.id = id;
        return this;
    }

    setDescription(description: string) {
        this.description = (description || "New Chat").trim();
        return this;
    }

    setTotalTokens(totalTokens: number) {
        this.totalTokens = totalTokens;
        return this;
    }

    add() {
        return db.chats.add(this);
    }
}

interface SuggestionOption {
    text: string;
    score: number;
    freq: number;
}

export type SingleSuggestionOption = SuggestionOption & { oldWord: string };

interface Suggestion {
    text: string;
    oldWord?: string;
    offset: number;
    length: number;
    options: SuggestionOption[];
}

export interface KeySuggestion {
    key: string;
    suggestions: Suggestion[];
}
export interface Reference {
    index: number;
    title: string;
    source: string;
    dataSourceType: string;
}

// This is the format of the reference that we get from the API.
export interface ReferenceJson extends Omit<Reference, "dataSourceType"> {
    data_source_type: string;
}

export class MessageEntity {
    id!: IndexableType;
    toolCall = false;
    chatId!: IndexableType;
    responseId!: string;
    content!: string;
    pretrained!: string;
    done?: boolean;
    debug?: DebugEvent[];
    hasError?: boolean;
    error?: string;
    suggestions?: SingleSuggestionOption[];
    role!: ChatRole;
    createdAt!: number;
    repliedId!: IndexableType;
    references: Reference[] = [];

    static _() {
        const record = new MessageEntity();
        record.id = nanoid();
        record.createdAt = new Date().getTime();
        return record;
    }

    setId(id: IndexableType) {
        this.id = id;
        return this;
    }

    setResponseId(responseId: string) {
        this.responseId = responseId;
        return this;
    }

    setChatId(id: IndexableType) {
        this.chatId = id;
        return this;
    }

    setContent(content?: string) {
        this.content = (content ?? "unknown response").trim();
        return this;
    }

    setRole(role: ChatRole) {
        this.role = role;
        return this;
    }

    setRepliedId(repliedId: IndexableType) {
        this.repliedId = repliedId;
        return this;
    }

    setIsLoading() {
        this.done = false;
        return this;
    }

    toJSON(): Omit<
        MessageEntity,
        | "setId"
        | "setResponseId"
        | "setChatId"
        | "setContent"
        | "setRole"
        | "setRepliedId"
        | "setIsLoading"
        | "toJSON"
        | "add"
    > {
        return {
            id: this.id,
            toolCall: this.toolCall,
            chatId: this.chatId,
            responseId: this.responseId,
            content: this.content,
            pretrained: this.pretrained,
            done: this.done,
            debug: this.debug,
            hasError: this.hasError,
            error: this.error,
            suggestions: this.suggestions,
            role: this.role,
            createdAt: this.createdAt,
            repliedId: this.repliedId,
            references: this.references,
        };
    }

    add() {
        return db.messages.add(this.toJSON() as MessageEntity);
    }
}

class Database extends Dexie {
    chats!: Table<ChatEntity>;
    messages!: Table<MessageEntity>;
    settings!: Table<Settings>;

    constructor() {
        super("CTS-KS-7.2");
        this.version(8).stores({
            chats: "id, createdAt",
            messages: "id, chatId, responseId, createdAt",
            settings: "id",
        });

        this.on("populate", async () => {
            db.settings.add({
                id: "general",
            });
        });
    }
}
export const db = new Database();

export const updateSettings = <T extends string>(key: T, value: string) => {
    db.settings.where({ id: "general" }).modify((settings: Record<T, string>) => {
        settings[key] = value;
    });
};
