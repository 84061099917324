import {
    Autocomplete,
    Box,
    Button,
    Card,
    Flex,
    Select,
    SimpleGrid,
    Stack,
    Text,
    Title,
    rem,
} from "@mantine/core";
import { useLocation, useNavigate } from "@tanstack/react-router";
import { useLocalStorage } from "@uidotdev/usehooks";
import type { IndexableType } from "dexie";
import { Feature, useFeature } from "flagged";
import { useEffect, useMemo, useRef, useState } from "react";
import { AiOutlineSend } from "react-icons/ai";
import { MessageItem, sendFeedbackToApi } from "src/components/MessageItem";
import { useTab } from "src/contexts/tabContext";
import type { MessageEntity } from "src/db";
import { useApiStreamingState, useChatCompletion } from "src/hooks/useChatCompletion";
import { useMetadata } from "src/hooks/useMetadata";
import { getFilterDisplayText } from "src/utils/filterDisplayUtils";
import { isReportIdentifier } from "src/utils/metadata";

const DATA_SOURCE_PRODUCT_LINES: Record<string, string[]> = {
    field_service_reports: ["SGT", "AGT", "MGT", "RCE", "TCP"],
    salesforce: ["SGT", "AGT", "MGT"],
    combined: ["SGT", "AGT", "MGT"],
    support_emails: ["SGT", "AGT"],
    drawings: ["SGT"],
    rds_reports: ["SGT", "AGT", "MGT", "IST"],
    useful_info: ["SGT"],
    mindmaps: ["SGT"],
    ads_good_solutions: ["SGT"],
    salesorders: ["SGT"],
};

const BASE_PRODUCT_LINE_OPTIONS = [
    { value: "", label: "Default Filters" },
    { value: "SGT", label: "SGT" },
    { value: "AGT", label: "AGT" },
    { value: "MGT", label: "MGT" },
    { value: "IST", label: "IST" },
    { value: "RCE", label: "RCE" },
    { value: "TCP", label: "TCP" },
];

export function ChatRouteChild({
    selectedQuestions,
    chatId,
    messages,
    files,
    isCompact,
    isChatWithDocument,
    documentType,
    metadataFilter,
}: {
    readonly selectedQuestions: string[];
    readonly chatId?: IndexableType;
    readonly messages?: MessageEntity[];
    readonly files?: string[];
    readonly isCompact?: boolean;
    readonly isChatWithDocument?: boolean;
    readonly documentType?: string;
    readonly metadataFilter?: { key: string; value: string }[];
}) {
    const navigate = useNavigate();
    const { chatMetadata, addChatMetadata } = useMetadata();

    // Feature flag for onboarding
    const onboardingEnabled = useFeature("ONBOARDING");

    // User preferences from local storage
    const [chatPreferences, _setChatPreferences] = useLocalStorage<{
        selectedProductLines: string[];
        selectedDatasources: string[];
    }>("chatPreferences", {
        selectedProductLines: [],
        selectedDatasources: [],
    });

    // Check if preferences exist and navigate to questionnaire if needed
    useEffect(() => {
        if (onboardingEnabled && !chatPreferences.selectedProductLines.length) {
            navigate({
                to: "/questionnaire",
                // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                search: { source: document.location.pathname } as any,
            });
        }
    }, [onboardingEnabled, chatPreferences, navigate]);

    useEffect(() => {
        if (files && files?.length > 0) {
            for (const file of files) {
                if (
                    chatMetadata.find((metadata) => {
                        return isReportIdentifier(metadata.key) && file.startsWith(metadata.value);
                    })
                )
                    return;
                addChatMetadata(file.split("_")[0], "file");
            }
        }
    }, [files, chatMetadata, addChatMetadata]);
    const chatCompletion = useChatCompletion(chatId, isChatWithDocument);
    const [isInitialLoading, _setIsInitialLoading] = useState(false);
    const isStreaming = useApiStreamingState(chatId?.toString());
    const isLoading = chatCompletion.isloading || isInitialLoading || isStreaming;

    const [content, setContent] = useState("");
    const ref = useRef<HTMLInputElement>(null);

    const { selectedTab, setSelectedTab } = useTab();
    const [productLine, setProductLine] = useState<string>(() => {
        // Initialize from localStorage, fallback to empty string
        return localStorage.getItem("selectedProductLine") || "";
    });

    // Set product line and tab based on user preferences if onboarding is enabled
    useEffect(() => {
        if (onboardingEnabled && chatPreferences.selectedProductLines?.length) {
            // Use the first selected product line
            const selectedProductLine =
                chatPreferences.selectedProductLines[0] === "all"
                    ? ""
                    : chatPreferences.selectedProductLines[0];
            setProductLine(selectedProductLine);
        }
    }, [onboardingEnabled, chatPreferences.selectedProductLines]);

    useEffect(() => {
        if (onboardingEnabled && chatPreferences.selectedDatasources?.length) {
            // Convert data source to tab id using a safer approach
            const selectedDataSource = chatPreferences.selectedDatasources[0];

            // Only set tab if it has a value
            if (selectedDataSource) {
                // Handle "All" case specially
                const tabId =
                    selectedDataSource === "All" ? "combined" : selectedDataSource.toLowerCase();

                // Only set if it's a valid data source from our configuration
                if (Object.keys(DATA_SOURCE_PRODUCT_LINES).includes(tabId)) {
                    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                    setSelectedTab(tabId as any);
                }
            }
        }
    }, [onboardingEnabled, chatPreferences.selectedDatasources, setSelectedTab]);

    // Add effect to save to localStorage when productLine changes
    useEffect(() => {
        localStorage.setItem("selectedProductLine", productLine);
    }, [productLine]);

    // Compute product line options based on the selected tab.
    const PRODUCT_LINE_OPTIONS = useMemo(() => {
        const availableCodes = DATA_SOURCE_PRODUCT_LINES[selectedTab];
        if (availableCodes && availableCodes.length > 0) {
            return BASE_PRODUCT_LINE_OPTIONS.filter(
                (option) => option.value === "" || availableCodes.includes(option.value),
            );
        }
        return BASE_PRODUCT_LINE_OPTIONS;
    }, [selectedTab]);

    // When selectedTab changes, if the current productLine doesn't exist in the options, default to all.
    useEffect(() => {
        if (
            productLine !== "" &&
            !PRODUCT_LINE_OPTIONS.some((option) => option.value === productLine)
        ) {
            setProductLine("");
        }
    }, [PRODUCT_LINE_OPTIONS, productLine]);

    const submit = async () => {
        const newContent = content;
        setContent("");
        await chatCompletion.sendMessage(
            newContent,
            false,
            chatId,
            metadataFilter,
            // Only needed if onboarding flag isn't enabled, as onboarding can have multiple products line which the API doesn't support
            onboardingEnabled ? "" : productLine,
            isChatWithDocument ? documentType : undefined,
        );
    };

    // Use either isCompact or isChatWithDocument for compact mode
    const isCompactMode = isCompact || isChatWithDocument;

    const [titleDisplay, setTitleDisplay] = useState("CTS Knowledge Search");

    // Update title based on preferences
    useEffect(() => {
        try {
            const newTitle = getFilterDisplayText(chatPreferences, true);
            setTitleDisplay(newTitle);
            document.title = newTitle;
        } catch (error) {
            console.error("Error updating chat title:", error);
        }
    }, [chatPreferences]);

    return (
        <>
            <Title
                order={3}
                mb={16}
                pl="xl"
                c="purple.8"
                sx={{ display: isChatWithDocument ? "none" : "block" }}
            >
                <Flex align="center">
                    <Text>{titleDisplay}</Text>
                    <Button
                        variant="outline"
                        size="xs"
                        ml={8}
                        onClick={() =>
                            navigate({
                                to: "/questionnaire",
                                // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                                search: { source: document.location.pathname } as any,
                            })
                        }
                    >
                        Edit Preferences
                    </Button>
                </Flex>
            </Title>
            <Stack spacing="xs" px={isCompactMode ? "0" : "xl"} pb="12rem">
                {messages?.map((message, i) => (
                    <MessageItem
                        isCompact={isCompactMode}
                        sendFeedbackToApi={sendFeedbackToApi}
                        retry={(newMessage: string) => {
                            setContent("");
                            return chatCompletion.sendMessage(
                                newMessage,
                                true,
                                chatId,
                                metadataFilter,
                                undefined,
                                isChatWithDocument ? documentType : undefined,
                            );
                        }}
                        key={message.id.toString()}
                        message={message}
                        nextAssistantMessage={
                            messages[i + 1] && i > 1 ? messages[i + 1] : undefined
                        }
                        inProgress={isStreaming}
                    />
                ))}
            </Stack>
            <Box
                py="lg"
                px="xl"
                sx={(theme) =>
                    isCompactMode
                        ? {}
                        : {
                              position: "fixed",
                              bottom: 0,
                              left: 0,
                              right: 0,
                              [`@media (min-width: ${theme.breakpoints.md})`]: {
                                  left: rem(200),
                              },
                              [`@media (min-width: ${theme.breakpoints.lg})`]: {
                                  left: rem(300),
                              },
                              backgroundColor:
                                  theme.colorScheme === "dark"
                                      ? theme.colors.dark[9]
                                      : theme.colors.gray[0],
                          }
                }
            >
                <Stack spacing="0">
                    <SimpleGrid
                        cols={2}
                        spacing="sm"
                        style={{ maxWidth: "100%" }}
                        mb={messages && messages?.length === 0 ? "sm" : "0"}
                    >
                        {!messages?.length
                            ? selectedQuestions.map((question) => (
                                  <Card
                                      shadow="sm"
                                      padding="lg"
                                      radius="md"
                                      withBorder
                                      key={question}
                                      variant="outline"
                                      style={{
                                          cursor: "pointer",
                                          whiteSpace: "pre-wrap",
                                          wordBreak: "break-word",
                                          textAlign: "left",
                                      }}
                                      onClick={() => setContent(question)}
                                  >
                                      {question}
                                  </Card>
                              ))
                            : null}
                    </SimpleGrid>

                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (chatCompletion.isloading || isLoading) return false;
                            submit();
                            (document.activeElement as HTMLElement)?.blur();
                            return false;
                        }}
                    >
                        <Flex gap="sm" style={{ position: "relative" }}>
                            <Flex style={{ flex: 1 }}>
                                <Feature name="PRODUCT_LINE_DROPDOWN">
                                    {!onboardingEnabled && (
                                        <Select
                                            data={PRODUCT_LINE_OPTIONS}
                                            value={productLine}
                                            onChange={(value) => setProductLine(value || "")}
                                            data-tour="datasource-selector"
                                            styles={(theme) => ({
                                                root: {
                                                    width: "160px",
                                                },
                                                input: {
                                                    borderTopRightRadius: 0,
                                                    borderBottomRightRadius: 0,
                                                    borderRight: 0,
                                                    height: "36px",
                                                },
                                            })}
                                        />
                                    )}
                                </Feature>
                                <Autocomplete
                                    ref={ref}
                                    value={content}
                                    style={{ flex: 1 }}
                                    placeholder="Your message here..."
                                    id="chat-input"
                                    maxDropdownHeight={200}
                                    data={messages?.length ? selectedQuestions : []}
                                    onChange={(value) => setContent(value)}
                                    styles={(theme) => ({
                                        input: {
                                            height: "36px",
                                            borderRadius: theme.radius.sm,
                                        },
                                    })}
                                />
                            </Flex>
                            <Button
                                data-testid="send-button"
                                h="auto"
                                variant="filled"
                                onClick={submit}
                                disabled={chatCompletion.isloading || isLoading || !content}
                            >
                                <AiOutlineSend />
                            </Button>
                        </Flex>
                    </form>
                    <Text
                        fw="bold"
                        c="gray.7"
                        mt=".5rem"
                        fz="xs"
                        align="center"
                        sx={{ maxWidth: "80%", margin: "0 auto" }}
                    >
                        CTS Knowledge Search uses Generative AI and is still experimental. Please
                        verify the answers for accuracy before using them.
                    </Text>
                </Stack>
            </Box>
        </>
    );
}

export function ChatRoute() {
    const location = useLocation();
    // Define a proper interface for the search params
    interface SearchParams {
        files?: string[];
        searchTerm?: string;
        // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
        [key: string]: any;
    }
    // Cast the search params to our interface
    const params = location.search as unknown as SearchParams;
    const files = params.files;
    const searchTerm = params.searchTerm;
    const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
    useEffect(() => {
        if (searchTerm) {
            const suggestions = [
                `What are the typical failure modes of ${searchTerm}?`,
                `Explain the most frequent issues with ${searchTerm}`,
                `How to troubleshoot ${searchTerm}`,
                `What are the symptoms for ${searchTerm} damage?`,
            ];
            setSelectedQuestions(suggestions);
        } else {
            setSelectedQuestions([
                "What are the symptoms for bearing damage?",
                "What are common failures in steam turbines?",
                "How do I troubleshoot a servomotor?",
                "What are the most frequent reasons for compressor trip?",
            ]);
        }
    }, [searchTerm]);

    return <ChatRouteChild selectedQuestions={selectedQuestions} files={files} />;
}
