import {
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_SortingState,
    type MRT_Virtualizer,
    MantineReactTable,
    useMantineReactTable,
} from "mantine-react-table";
import { type UIEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useTab } from "src/contexts/tabContext";
import { type ChatVectorIndexShorthand, chatVectorIndex } from "src/db";
import { BACKEND_URL } from "src/hooks/useMetadata";
import { getAPI, postAPI } from "src/utils/fetch";

import { useAccount, useMsal } from "@azure/msal-react";
import { Box, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { metadataSAP } from "../../metadata-gen/metadata-sap";
import { PdfFocusProvider } from "./PDFViewer/context";
import TopToolbarCustomActions from "./TopToolbarCustomActions";

function csvJSON(csv: string) {
    const lines = csv.split("\n");
    const result = [];
    const headers = lines[0].split(",");

    for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const currentline = lines[i].split(",");

        for (let j = 0; j < headers.length; j++) {
            // @ts-ignore
            obj[headers[j]] = currentline[j];
        }

        result.push(obj);
    }

    return result;
}

export type SalesforceDocument = {
    _index: string;
    _id: string;
    _score: number;
    fields: {
        "Author(s)": string;
        "Machine No": string;
        "Account Name": string;
        "Type of Activity": string;
        "Report Name": string;
        "Product Type": string;
        Location: string;
        Date: string;
        "Order No Internal": string;
        Project: string;
        Codeword: string;

        // TODO The following fields were added to match the types used below,
        // but they need to be confirmed with the actual data returned.
        Subject: string;
    };
};

const metaSAPJson = csvJSON(metadataSAP);

export type MetadataDocument = {
    _index: string;
    _id: string;
    _score: number;
    fields: {
        "Author(s)": string;
        "Machine No": string;
        "Customer Name": string;
        "Type of Activity": string;
        report_name: string;
        "Product Type": string;
        Location: string;
        Date: string;
        "Order No Internal": string;
        Project: string;
        Codeword: string;
        DRAWING_NO?: string;
        DRAWING_TITLE?: string;
        source?: string;
    };
};

export type DrawingDocument = {
    _index: string;
    _id: string;
    _score: number;
    fields: {
        DRAWING_NO: string;
        DRAWING_TITLE: string;
        source: string;
    };
};

export type MindmapDocument = {
    _index: string;
    _id: string;
    _score: number;
    fields: {
        mindmap: string;
        title: string;
        source: string;
        ingestion_date: string;
    };
};

export type UsefulInfoDocument = {
    _index: string;
    _id: string;
    _score: number;
    fields: {
        file_name: string;
        extension: string;
        ingestion_date: string;
    };
};

export type SalesorderDocument = {
    _index: string;
    _id: string;
    _score: number;
    fields: {
        "Customer Name": string;
        "TUL Number": string;
        "Product Line": string;
        date_timestamp?: number;
        last_amended_timestamp?: number;
    };
};

// Define the columns for the Salesforce documents
const salesforceColumns: MRT_ColumnDef<SalesforceDocument>[] = [
    {
        id: "sf__metadata.Account Name",
        accessorKey: "fields.Account Name",
        accessorFn: (row) => row.fields?.["Account Name"] || "N/A",
        header: "Customer Name",
        Header: () => "Customer Name",
        filterFn: "includesString",
    },
    {
        id: "sf__metadata.Subject",
        accessorKey: "fields.Subject",
        accessorFn: (row) => row.fields?.Subject || "N/A",
        header: "Subject",
        filterFn: "includesString",
    },
    {
        id: "sf__metadata.Case Number",
        accessorKey: "fields.Case Number",
        accessorFn: (row) => row.fields?.["Case Number"] || "N/A",
        header: "Case Number",
        filterFn: "includesString",
    },
    {
        id: "sf__metadata.Case Owner",
        accessorKey: "fields.Case Owner",
        accessorFn: (row) => row.fields?.["Case Owner"] || "N/A",
        header: "Case Owner",
        filterFn: "includesString",
    },
    {
        id: "sf__metadata.Date/Time Opened",
        accessorKey: "fields.Date/Time Opened",
        accessorFn: (row) => row.fields?.["Date/Time Opened"] || "N/A",
        header: "Date/Time Opened",
        filterFn: "includesString",
    },
    {
        id: "sf__metadata.Machine No",
        accessorKey: "fields.Machine No",
        accessorFn: (row) => row.fields?.["Machine No"] || "N/A",
        header: "Machine No",
        filterFn: "includesString",
    },
    {
        id: "sf__metadata.Product Type",
        accessorKey: "fields.Product Type",
        accessorFn: (row) => row.fields?.["Product Type"] || "N/A",
        header: "Product Type",
        filterFn: "includesString",
    },
];

const fsrColumns: MRT_ColumnDef<MetadataDocument>[] = [
    {
        id: "fsr__metadata.report_name",
        accessorKey: "report_name",
        accessorFn: (originalRow) => {
            return originalRow.fields ? originalRow.fields.report_name || "N/A" : "N/A";
        },
        header: "Report Name",
        filterFn: "includesString",
    },
    {
        id: "fsr__metadata.Customer Name",
        accessorKey: "fields.Customer Name",
        accessorFn: (originalRow) => {
            if (!originalRow.fields) return "";
            const possibleMatch = metaSAPJson.filter(
                (row) => row.UNIT_NAME__C === originalRow.fields.Codeword,
            );
            const valsToAdd = [];
            for (const match of possibleMatch ?? []) {
                valsToAdd.push(
                    ...[
                        match["CUSTOMER ACCOUNT"],
                        match["SAP NO"],
                        match["SAP ORIG SERIAL"],
                        match["SF ORIG SERIAL"],
                        match["SF SERIAL"],
                        match.SFID,
                        match.TRAIN,
                    ],
                );
            }
            return [originalRow.fields["Customer Name"] || "N/A", ...valsToAdd].join(" ");
        },
        filterVariant: "autocomplete",
        enableFilterMatchHighlighting: true,
        enableClickToCopy: false,
        Cell: ({ row }) => (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                }}
            >
                <span>{row.original.fields ? row.original.fields["Customer Name"] : ""}</span>
            </Box>
        ),
        header: "Customer Name",
        filterFn: "includesString",
    },
    {
        id: "fsr__metadata.Machine No",
        accessorKey: "fields.Machine No",
        accessorFn: (originalRow) => {
            if (!originalRow.fields) return "";
            const possibleMatch = metaSAPJson.filter((row) =>
                [
                    row["SAP NO"],
                    row["SF ORIG SERIAL"],
                    row["SAP ORIG SERIAL"],
                    row["SF SERIAL"],
                ].includes(originalRow.fields["Machine No"]),
            );
            const valsToAdd = [];
            for (const match of possibleMatch ?? []) {
                valsToAdd.push(
                    ...[
                        match["CUSTOMER ACCOUNT"],
                        match["SAP NO"],
                        match["SAP ORIG SERIAL"],
                        match["SF ORIG SERIAL"],
                        match["SF SERIAL"],
                        match.SFID,
                        match.TRAIN,
                    ],
                );
            }
            return [originalRow.fields["Machine No"] || "N/A", ...valsToAdd].join(" ");
        },
        header: "Machine No",
        filterFn: "includesString",
        Cell: ({ row }) => (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                }}
            >
                <span>{row.original.fields ? row.original.fields["Machine No"] : ""}</span>
            </Box>
        ),
    },
    {
        id: "fsr__metadata.Date",
        accessorKey: "fields.Date",
        accessorFn: (originalRow) => {
            if (!originalRow.fields) return "";
            const dateValue = originalRow.fields?.Date
                ? new Date(originalRow.fields.Date)
                : new Date("2000-01-01T00:00:00.000Z");
            if (dateValue) {
                dateValue.setHours(0, 0, 0, 0);
            }
            return dateValue;
        },
        header: "Date",
        filterVariant: "date-range",
        sortingFn: "datetime",
        Cell: ({ cell }) => {
            const dateValue = cell.getValue<Date>();
            if (
                dateValue &&
                dateValue.getTime() !== new Date("2000-01-01T00:00:00.000Z").getTime()
            ) {
                return dateValue.toLocaleDateString(undefined, {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                });
            }
            return "N/A";
        },
        Header: ({ column }) => <em>{column.columnDef.header}</em>,
    },
    {
        id: "fsr__metadata.Type of Activity",
        accessorKey: "fields.Type of Activity",
        accessorFn: (originalRow) =>
            originalRow.fields ? originalRow.fields["Type of Activity"] || "N/A" : "N/A",
        header: "Type of Activity",
        filterFn: "includesString",
    },
    {
        id: "fsr__metadata.Codeword",
        accessorKey: "fields.Codeword",
        accessorFn: (originalRow) =>
            originalRow.fields ? originalRow.fields.Codeword || "N/A" : "N/A",
        header: "Codeword",
        filterFn: "includesString",
        size: 300,
    },
    {
        id: "fsr__metadata.Product Type",
        accessorKey: "fields.Product Type",
        accessorFn: (originalRow) =>
            originalRow.fields ? originalRow.fields["Product Type"] || "N/A" : "N/A",
        header: "Product Type",
        filterFn: "includesString",
    },
    {
        id: "fsr__metadata.Location",
        accessorKey: "fields.Location",
        accessorFn: (originalRow) =>
            originalRow.fields ? originalRow.fields.Location || "N/A" : "N/A",
        header: "Location",
        filterFn: "includesString",
    },
    {
        id: "fsr__metadata.Author(s)",
        accessorKey: "fields.Author(s)",
        accessorFn: (originalRow) =>
            originalRow.fields ? originalRow.fields["Author(s)"] || "N/A" : "N/A",
        header: "Author(s)",
        filterFn: "includesString",
        size: 150,
    },
    {
        id: "fsr__metadata.Project",
        accessorKey: "fields.Project",
        accessorFn: (originalRow) =>
            originalRow.fields ? originalRow.fields.Project || "N/A" : "N/A",
        header: "Project",
        filterFn: "includesString",
    },
    {
        id: "fsr__metadata.Order No Internal",
        accessorKey: "fields.Order No Internal",
        accessorFn: (originalRow) =>
            originalRow.fields ? originalRow.fields["Order No Internal"] || "N/A" : "N/A",
        header: "Order No Internal",
        filterFn: "includesString",
    },
];

const drawingsColumns: MRT_ColumnDef<DrawingDocument>[] = [
    {
        id: "drawings__metadata.DRAWING_NO",
        accessorKey: "DRAWING_NO",
        header: "Drawing No",
        // enableColumnFilter: false,
        accessorFn: (originalRow) => (originalRow.fields ? originalRow.fields.DRAWING_NO : "N/A"),
    },
    {
        id: "drawings__metadata.DRAWING_TITLE",
        accessorKey: "DRAWING_TITLE",
        header: "Drawing Title",
        // enableColumnFilter: false,
        filterFn: "includesString",
        accessorFn: (originalRow) =>
            originalRow.fields ? originalRow.fields.DRAWING_TITLE : "N/A",
    },
    {
        id: "drawings__metadata.source",
        accessorKey: "source",
        header: "Source",
        // enableColumnFilter: false,
        filterFn: "includesString",
        accessorFn: (originalRow) => (originalRow.fields ? originalRow.fields.source : "N/A"),
    },
];

const mindmapsColumns: MRT_ColumnDef<MindmapDocument>[] = [
    {
        id: "mindmaps__metadata.mindmap",
        header: "ID",
        accessorKey: "fields.mindmap",
        filterFn: "includesString",
        accessorFn: (originalRow) => (originalRow.fields ? originalRow.fields.mindmap : "N/A"),
    },
    // {
    //     id: "mindmaps__title",
    //     accessorKey: "fields.title",
    //     header: "Title",
    //     // enableColumnFilter: false,
    //     accessorFn: (originalRow) => originalRow.fields ? originalRow.fields.title : "N/A"
    // },
    {
        id: "mindmaps__metadata.source",
        accessorKey: "fields.source",
        header: "Source",
        // enableColumnFilter: false,
        filterFn: "includesString",
        accessorFn: (originalRow) => (originalRow.fields ? originalRow.fields.source : "N/A"),
    },
    {
        id: "mindmaps__metadata.ingestion_date",
        accessorKey: "fields.ingestion_date",
        header: "Ingestion Date",
        // enableColumnFilter: false,
        filterFn: "includesString",
        sortingFn: "datetime",
        accessorFn: (originalRow) =>
            originalRow.fields ? new Date(originalRow.fields.ingestion_date) : undefined,
        Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(),
    },
];

const usefulInfoDocumentColumns: MRT_ColumnDef<UsefulInfoDocument>[] = [
    {
        id: "metadata.file_name",
        header: "File Name",
        accessorFn: (originalRow) => originalRow.fields?.file_name || "N/A",
    },
    {
        id: "metadata.extension",
        header: "File Extension",
        filterFn: "includesString",
        accessorFn: (originalRow) => originalRow.fields?.extension || "N/A",
    },
    {
        id: "metadata.ingestion_date",
        header: "Ingestion Date",
        filterFn: "includesString",
        sortingFn: "datetime",
        accessorFn: (originalRow) =>
            originalRow.fields ? new Date(originalRow.fields.ingestion_date) : undefined,
        Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(),
    },
];

export type ReportSchema = {
    _index: string;
    _id: string;
    _score: number;
    _source: {
        metadata: {
            "Customer Name": string;
            "Machine Number": string;
            "Product Type": string;
            "Product Line": string;
            Date: string;
            file_name: string;

            // TODO The following fields were added to match the types used below,
            // but they need to be confirmed with the actual data returned.
            document_id: string;
            report_period_ini: string;
            report_period_end: string;
        };
    };
};

export type GoodSolutionSchema = {
    _index: string;
    _id: string;
    _score: number;
    _source: {
        metadata: {
            // TODO The following fields were added to match the types used below,
            // but they need to be confirmed with the actual data returned.
            SYMPTOM_CLASS_ID: string;
            SYMPTOM_CLASS: string;
            EquipSerialNumber: string;
            ProductFamily: string;
            Tenant: string;
            Date: string;
        };
    };
};

const rdsColumns: MRT_ColumnDef<ReportSchema>[] = [
    {
        id: "rds__metadata.file_name",
        accessorKey: "_source.metadata.file_name",
        header: "File Name",
        accessorFn: (originalRow) => originalRow._source?.metadata.file_name || "N/A",
    },
    {
        id: "rds__metadata.document_id",
        accessorKey: "_source.metadata.document_id",
        header: "Document Id",
        accessorFn: (originalRow) => originalRow._source?.metadata.document_id || "N/A",
    },
    {
        id: "rds__metadata.Customer Name",
        accessorKey: "_source.metadata.Customer Name",
        header: "Customer Name",
        accessorFn: (originalRow) => originalRow._source?.metadata["Customer Name"] || "N/A",
    },
    {
        id: "rds__metadata.Machine Number",
        accessorKey: "_source.metadata.Machine Number",
        header: "Machine Number",
        accessorFn: (originalRow) => originalRow._source?.metadata["Machine Number"] || "N/A",
    },
    {
        id: "rds__metadata.Product Type",
        accessorKey: "_source.metadata.Product Type",
        header: "Product Type",
        accessorFn: (originalRow) => originalRow._source?.metadata["Product Type"] || "N/A",
    },
    // Product Line
    {
        id: "rds__metadata.Product Line",
        accessorKey: "_source.metadata.Product Line",
        header: "Product Line",
        accessorFn: (originalRow) => originalRow._source?.metadata["Product Line"] || "N/A",
    },
    {
        id: "rds__metadata.report_period_ini",
        accessorKey: "_source.metadata.report_period_ini",
        header: "Period Start",
        accessorFn: (originalRow) => originalRow._source?.metadata.report_period_ini || "N/A",
    },
    {
        id: "rds__metadata.report_period_end",
        accessorKey: "_source.metadata.report_period_end",
        header: "Period End",
        accessorFn: (originalRow) => originalRow._source?.metadata.report_period_end || "N/A",
    },
];

const ads_columns: MRT_ColumnDef<GoodSolutionSchema>[] = [
    {
        id: "rds__metadata.SYMPTOM_CLASS_ID",
        accessorKey: "_source.metadata.SYMPTOM_CLASS_ID",
        header: "Id",
        accessorFn: (originalRow) => originalRow._source?.metadata.SYMPTOM_CLASS_ID || "N/A",
    },
    {
        id: "rds__metadata.SYMPTOM_CLASS",
        accessorKey: "_source.metadata.SYMPTOM_CLASS",
        header: "Sypmton Class",
        accessorFn: (originalRow) => originalRow._source?.metadata.SYMPTOM_CLASS || "N/A",
    },
    {
        id: "rds__metadata.EquipSerialNumber",
        accessorKey: "_source.metadata.EquipSerialNumber",
        header: "Machine No",
        accessorFn: (originalRow) => originalRow._source?.metadata.EquipSerialNumber || "N/A",
    },
    {
        id: "rds__metadata.ProductFamily",
        accessorKey: "_source.metadata.ProductFamily",
        header: "Product Family",
        accessorFn: (originalRow) => originalRow._source?.metadata.ProductFamily || "N/A",
    },
    {
        id: "rds__metadata.Tenant",
        accessorKey: "_source.metadata.Tenant",
        header: "Product Line",
        accessorFn: (originalRow) => originalRow._source?.metadata.Tenant || "N/A",
    },
    {
        id: "rds__metadata.Date",
        accessorKey: "_source.metadata.Date",
        header: "Date",
        accessorFn: (originalRow) => originalRow._source?.metadata.Date || "N/A",
    },
];

const salesorder_columns: MRT_ColumnDef<SalesorderDocument>[] = [
    {
        id: "salesorders___id",
        accessorKey: "_id",
        header: "Salesorder Number",
        accessorFn: (originalRow) => {
            return originalRow._id || "N/A";
        },
    },
    {
        id: "salesorders__metadata.Date",
        accessorKey: "_source.Date",
        header: "Date",
        sortingFn: "datetime",
        accessorFn: (originalRow) =>
            originalRow.fields?.date_timestamp
                ? new Date(originalRow.fields.date_timestamp * 1000)
                : undefined,
        Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(),
    },
    {
        id: "salesorders__metadata.Customer Name",
        accessorKey: "_source.Customer Name",
        header: "Customer Name",
        accessorFn: (originalRow) => originalRow.fields?.["Customer Name"] || "N/A",
    },
    {
        id: "salesorders__metadata.TUL Number",
        accessorKey: "_source.TUL Number",
        header: "TUL Number",
        accessorFn: (originalRow) => {
            const metadata = originalRow.fields || {};
            return metadata["TUL Number"] || "N/A";
        },
    },
    {
        id: "salesorders__metadata.last_amended_timestamp",
        accessorKey: "_source.Last Amended",
        header: "Last amended",
        sortingFn: "datetime",
        accessorFn: (originalRow) =>
            originalRow.fields?.last_amended_timestamp
                ? new Date(originalRow.fields.last_amended_timestamp * 1000)
                : undefined,
        Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(),
    },
    {
        id: "salesorders__metadata.Product Line",
        accessorKey: "_source.Product Line",
        header: "Product Line",
        accessorFn: (originalRow) => {
            const metadata = originalRow.fields || {};
            return metadata["Product Line"];
        },
    },
];

const columnsMap: Partial<
    Record<
        ChatVectorIndexShorthand,
        MRT_ColumnDef<SalesforceDocument | MetadataDocument | DrawingDocument>[]
    >
> = {
    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
    fsr: fsrColumns as any, // as MRT_ColumnDef<SalesforceDocument>[],

    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
    sf: salesforceColumns as any, // as MRT_ColumnDef<MetadataDocument>[],

    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
    drawings: drawingsColumns as any, // as MRT_ColumnDef<DrawingDocument>[],

    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
    mindmaps: mindmapsColumns as any, // as MRT_ColumnDef<MetadataDocument>[],

    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
    rds: rdsColumns as any, // as MRT_ColumnDef<SalesforceDocument>[],

    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
    uinfo: usefulInfoDocumentColumns as any, // as MRT_ColumnDef<UsefulInfoDocument>[],

    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
    ads_good_solutions: ads_columns as any, // as MRT_ColumnDef<GoodSolutionSchema>[],

    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
    salesorders: salesorder_columns as any, // as MRT_ColumnDef<SalesorderDocument>[],
};

const MetadataDocuments = ({ index }: { index: ChatVectorIndexShorthand }) => {
    const columns = useMemo(
        // () => (index === "fsr" ? fsrColumns : salesforceColumns),
        () => columnsMap[index] ?? [],
        [index],
    );

    const rowVirtualizerInstanceRef =
        useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);

    const [globalFilter, setGlobalFilter] = useState<string>();

    useEffect(() => {
        // Reset all filters when the index prop changes
        setColumnFilters([]);
        setGlobalFilter(undefined);
        setSorting([]);
        setColumnFilters([]);

        // Reset scroll position
        if (rowVirtualizerInstanceRef.current) {
            rowVirtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, []);

    const { instance } = useMsal();
    const account = useAccount();
    const apiKey = account?.idToken;

    const [scrollId, setScrollId] = useState<string | null>(null);

    // @ts-ignore TODO This invocation is incorrect, query key and query fn should be passed separately,
    // but there is no `positionExpandColumn` option.
    const { data, fetchNextPage, isFetching, isLoading } = useInfiniteQuery({
        queryKey: ["metadata-documents", index, sorting, columnFilters, globalFilter],
        queryFn: async ({ pageParam = null }) => {
            const PATH = `v1/search/${
                chatVectorIndex?.find((ind) => ind.shorthand === index)?.value[0]
            }/metadata/docs?max_result=25`;

            let response: Response;
            if (pageParam) {
                const url = new URL("v1/search/scroll", BACKEND_URL);
                url.searchParams.set("_scroll_id", pageParam);
                response = await getAPI({ url: url.toString(), apiKey, instance });
            } else if (globalFilter) {
                const url = new URL("v1/search/text", BACKEND_URL);
                response = await postAPI({
                    url: url.toString(),
                    apiKey,
                    instance,
                    payload: {
                        query: globalFilter,
                        metadata_filter:
                            columnFilters
                                ?.filter((val) =>
                                    Array.isArray(val.value)
                                        ? val.value.filter(Boolean).length > 0
                                        : Boolean(val.value),
                                )
                                .map((filter) => ({
                                    key: filter.id.split("__")[1] ?? filter.id,
                                    value: filter.value,
                                })) ?? [],
                    },
                });
            } else {
                const url = new URL(PATH, BACKEND_URL);
                // Reset scroll_id and add filters for the first request
                response = await postAPI({
                    url: url.toString(),
                    apiKey,
                    instance,
                    payload:
                        columnFilters
                            ?.filter((val) =>
                                Array.isArray(val.value)
                                    ? val.value.filter(Boolean).length > 0
                                    : Boolean(val.value),
                            )
                            .map((filter) => ({
                                key: filter.id.split("__")[1] ?? filter.id,
                                value: filter.value,
                                // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                            })) ?? ([] as any),
                });
            }
            const data = await response.json();
            setScrollId(data._scroll_id);
            return data;
        },
        getNextPageParam: () => scrollId,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        renderDetailPanel: ({ row }) =>
            index === "drawings" ? (
                <Box
                    sx={{
                        display: "grid",
                        margin: "auto",
                        gridTemplateColumns: "1fr 1fr",
                        width: "100%",
                    }}
                >
                    <Text>Address: {row.original.address}</Text>
                    <Text>City: {row.original.city}</Text>
                    <Text>State: {row.original.state}</Text>
                    <Text>Country: {row.original.country}</Text>
                </Box>
            ) : null,
        positionExpandColumn: "first",
    });

    const flatData = useMemo(
        () =>
            // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
            data?.pages.flatMap((page: any) =>
                page.hits.hits.map((doc) => ({
                    ...doc,
                    fields: doc._source.metadata,
                })),
            ) ?? [],
        [data],
    );

    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
    const totalDBRowCount = (data as any)?.pages?.[0]?.hits?.total?.value ?? 0;

    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    flatData.length < totalDBRowCount
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, flatData.length, totalDBRowCount],
    );

    useEffect(() => {
        if (rowVirtualizerInstanceRef.current) {
            try {
                rowVirtualizerInstanceRef.current.scrollToIndex(0);
            } catch (e) {
                console.error(e);
            }
        }
    }, []);

    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);

    const [opened] = useDisclosure(false);
    const { setSelectedTab } = useTab();
    useEffect(() => {
        setSelectedTab("field_service_reports");
    }, [setSelectedTab]);

    const selectedQuestions = [];
    const [rowVirtualizerRef, setRowVirtualizerRef] = useState<MRT_Virtualizer<
        HTMLDivElement,
        HTMLTableRowElement
    > | null>(null);
    const tableContainerRef = useRef<HTMLDivElement>(null); //we can get access to the underlying TableContainer element and react to its scroll events

    const table = useMantineReactTable({
        columns,
        data: flatData ?? [],
        enableColumnOrdering: true,
        enablePinning: true,
        enableBottomToolbar: false,
        renderToolbarAlertBannerContent: () => <></>,
        enableColumnResizing: true,
        enableColumnFilterModes: false,
        enableGlobalFilter: false,
        enableFullScreenToggle: false,
        // TODO This used to specify a filter function, but this property doesn't actually exist.
        // It should be converted into using `filterFns`.
        // filterFn: "includesString",
        enablePagination: false,
        enableRowNumbers: true,
        enableClickToCopy: true,
        getRowId: (row) => {
            if (!row.fields) return undefined;
            if (row.fields.report_name) return row.fields.report_name;
            return `${row._id}`;
        },
        renderTopToolbarCustomActions: () => (
            <TopToolbarCustomActions
                // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                table={table as any}
                index={index}
                // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                data={data as any}
                columnFilters={columnFilters}
                // TODO This is a required prop but was not set previously, so it is currently set to false,
                // but needs to be revisited.
                isLoading={false}
            />
        ),
        mantineToolbarAlertBannerProps: {
            style: {
                display: "none",
                background: "#f0f0f0",
                fontWeight: "bold",
            },
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        enableSelectAll: false,
        selectAllMode: "page",
        enableGrouping: true,
        enableRowVirtualization: true,
        enableDensityToggle: false,
        mantineTableContainerProps: {
            ref: tableContainerRef, //get access to the table container element
            sx: { padding: 0, borderRadius: 0, maxHeight: "80vh" },
            onScroll: (
                event: UIEvent<HTMLDivElement>, //add an event listener to the table container element
            ) => fetchMoreOnBottomReached(event.target as HTMLDivElement),
        },
        mantinePaperProps: { sx: { borderRadius: 0, zIndex: 102 } },
        onSortingChange: setSorting,
        state: { isLoading, sorting },
        initialState: {
            showGlobalFilter: true,
            density: "xs",
            showColumnFilters: true,
        },
        rowVirtualizerInstanceRef: ((instance) => {
            setRowVirtualizerRef(instance);
            // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
        }) as any,
        rowVirtualizerProps: {
            overscan: 10,
            onScroll: (event) => {
                const { scrollOffset, scrollHeight, clientHeight } = event;
                if (scrollOffset + clientHeight >= scrollHeight - 200) {
                    // Load more data when user scrolls to bottom
                    // Implement your data fetching logic here
                }
            },
            // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
        } as any,
    });

    useEffect(() => {
        if (rowVirtualizerRef) {
            rowVirtualizerRef.scrollToIndex(0);
        }
    }, [rowVirtualizerRef]);

    return (
        <PdfFocusProvider>
            <MantineReactTable table={table} />
        </PdfFocusProvider>
    );
};

export default MetadataDocuments;
