import { type ReactNode, createContext, useContext, useState } from "react";

interface ApiHealthContextType {
    isApiHealthy: boolean;
    setIsApiHealthy: (healthy: boolean) => void;
}

const ApiHealthContext = createContext<ApiHealthContextType | undefined>(undefined);

export function ApiHealthProvider({ children }: { children: ReactNode }) {
    const [isApiHealthy, setIsApiHealthy] = useState(true);

    return (
        <ApiHealthContext.Provider value={{ isApiHealthy, setIsApiHealthy }}>
            {children}
        </ApiHealthContext.Provider>
    );
}

export function useApiHealthContext() {
    const context = useContext(ApiHealthContext);
    if (context === undefined) {
        throw new Error("useApiHealthContext must be used within an ApiHealthProvider");
    }
    return context;
}
