import { createFileRoute } from "@tanstack/react-router";
import { ChatRoute } from "../../containers/ChatsRoute";

export const Route = createFileRoute("/_layout/chats/$chatId")({
    component: DocumentsChat,
});

export default function DocumentsChat() {
    // We don't need to pass any props to ChatRoute - it will use the chatId from the URL params
    // and other parameters from the search string internally
    return <ChatRoute />;
}
