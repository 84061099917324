// TopToolbarCustomActions.tsx
import type { IPublicClientApplication } from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import { PdfFocusProvider } from "@llamaindex/pdf-viewer";
import { Anchor, Button, Drawer, Grid, ScrollArea, Stack, Tabs, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useNavigate } from "@tanstack/react-router";
import { useLiveQuery } from "dexie-react-hooks";
import { Feature } from "flagged";
import type { MRT_ColumnFiltersState, MRT_TableInstance } from "mantine-react-table";
import { nanoid } from "nanoid";
import { memo, useEffect, useLayoutEffect, useMemo, useState } from "react";
import type { InfiniteData } from "react-query";
import { ChatRouteChild } from "src/containers/ChatRoute";
import { useTab } from "src/contexts/tabContext";
import { db } from "src/db";
import { CHAT_COMPLETIONS_URL } from "src/hooks/useChatCompletion";
import { REPORT_INDICES, TAB_INDEX_MAPPING } from "src/utils/constants";
import { getMetadataKey } from "src/utils/metadata";
import { getPdfUrl } from "../utils/artifactHandler";
import type { MetadataDocument } from "./MetadataDocuments";
import { ViewPdf } from "./PDFViewer/ViewPdf";

export const AsyncPdfViewer = memo(
    ({
        pdfUrl,
        apiKey,
        row,
        instance,
    }: {
        pdfUrl: string;
        apiKey?: string;
        row: string;
        instance: IPublicClientApplication;
    }) => {
        const [pdfUrlWithApiKey, setPdfUrlWithApiKey] = useState<string | null>(null);

        useEffect(() => {
            (async () => {
                const url = await getPdfUrl(pdfUrl, apiKey, instance);
                setPdfUrlWithApiKey(url);
            })();
        }, [pdfUrl, apiKey, instance]);

        useLayoutEffect(() => {
            if (
                pdfUrlWithApiKey &&
                // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                (document.querySelector(".zoomSelect") as any)?.value === "100%"
            ) {
                setTimeout(() => {
                    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                    (document.querySelector(".zoomIn") as any)?.click();
                }, 400);
            }
        }, [pdfUrlWithApiKey]);

        if (pdfUrlWithApiKey) {
            return <ViewPdf file={{ id: row, url: pdfUrlWithApiKey }} />;
        }
    },
);

interface TableData {
    _id: string;
    _source: {
        metadata: Record<string, string>;
    };
}

const PDFList = memo(
    ({
        rows,
        apiKey,
        instance,
        metadataDocumentsLive,
    }: {
        rows: string[];
        apiKey?: string;
        instance: IPublicClientApplication;
        metadataDocumentsLive: MetadataDocument[];
    }) => {
        return (
            <Tabs defaultValue={rows[0]} orientation="vertical" placement="right" mah="90vh">
                <Tabs.List>
                    {rows.map((row) => (
                        <Tabs.Tab key={row} value={row}>
                            {row.split("_")[0]}
                        </Tabs.Tab>
                    ))}
                </Tabs.List>
                {rows.map((row) => {
                    const source = metadataDocumentsLive.findLast((doc: MetadataDocument) =>
                        doc._id.includes(row.split("_")[0]),
                    )?.fields.source;
                    const pdfUrl = `${CHAT_COMPLETIONS_URL}${source.startsWith("/") ? "" : "/"}${source}`;
                    return (
                        <Tabs.Panel key={row} value={row} w="auto">
                            <PdfFocusProvider>
                                <div style={{ height: "90vh", width: "100%" }}>
                                    <AsyncPdfViewer
                                        instance={instance}
                                        pdfUrl={pdfUrl}
                                        apiKey={apiKey}
                                        row={row}
                                    />
                                </div>
                            </PdfFocusProvider>
                        </Tabs.Panel>
                    );
                })}
            </Tabs>
        );
    },
);

const TopToolbarCustomActions = ({
    table,
    index,
    data,
    columnFilters,
    isLoading,
}: {
    table: MRT_TableInstance<TableData>;
    index: string;
    data: InfiniteData<{ hits: { hits: TableData[] } }> | undefined;
    columnFilters: MRT_ColumnFiltersState;
    isLoading: boolean;
}) => {
    const MAX_REPORTS = 100;
    const filteredRows = Object.keys(table.getFilteredRowModel().rowsById);
    const hasFilters = Boolean(columnFilters.length);
    let rows =
        hasFilters && filteredRows.length <= MAX_REPORTS ? filteredRows.slice(0, MAX_REPORTS) : [];
    rows = rows.map((str) => str.split("_")[0]);
    const navigate = useNavigate();
    // @ts-ignore
    // biome-ignore lint/correctness/useExhaustiveDependencies: sending the array as is causes constant change
    const newChatId = useMemo(() => {
        const filteredRowIds = filteredRows.slice(0, MAX_REPORTS).map((id) => id.split("_")[0]);
        const id = `${index}_${nanoid()}_${filteredRowIds.join(",")}`;
        return id;
    }, [index, filteredRows.join(",")]);

    const useMessages = (chatId: string) =>
        useLiveQuery(() => {
            if (!chatId) return [];
            return db.messages.where("chatId").equals(chatId).sortBy("createdAt");
        }, [chatId]);

    const messages = useMessages(newChatId);
    const [opened, { open, close }] = useDisclosure(false);
    const { setSelectedTab } = useTab();
    const { instance } = useMsal();
    const account = useAccount();
    const apiKey = account?.idToken;

    const flatData = useMemo(
        () =>
            data?.pages.flatMap((page) =>
                page.hits.hits.map((doc) => ({
                    ...doc,
                    fields: doc._source.metadata,
                })),
            ) ?? [],
        [data],
    );

    useEffect(() => {
        // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
        setSelectedTab(TAB_INDEX_MAPPING[index] as any);
    }, [index, setSelectedTab]);

    const clickHandler = () => {
        if (hasFilters) {
            // Create a new unique chat ID for this session
            // and ensure a new history entry is created
            navigate({
                to: `/documents?ids=${newChatId}`,
                replace: false, // Creates a new browser history entry
            });
            open();
        } else {
            navigate({
                to: `/chats?index=${index}`,
                replace: false, // Creates a new browser history entry
            });
            close();
        }
    };

    return (
        <>
            <Drawer
                styles={{
                    header: {
                        display: "none",
                    },
                    body: {
                        minHeight: "90vh",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                    },
                }}
                opened={opened}
                onClose={() => {
                    navigate({ to: "/documents" });
                    close();
                }}
                title={null}
                size="100%"
            >
                <Grid gutter="0" style={{ height: "100%" }}>
                    <Grid.Col
                        span={5}
                        h="100%"
                        style={{
                            justifyContent: "flex-end",
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                        }}
                    >
                        <Stack
                            spacing={0}
                            sx={{
                                position: "fixed",
                                top: ".5rem",
                                left: ".5rem",
                                zIndex: 1000,
                                background: "white",
                                overflowY: "hidden",
                                "max-width": "30%",
                                "text-overflow": "ellipsis",
                                "max-height": "4.5rem",
                            }}
                        >
                            <Anchor
                                c="purple"
                                onClick={() => {
                                    navigate({ to: "/documents" });
                                    close();
                                }}
                            >
                                Back to Document Selection
                            </Anchor>
                            <Title title={rows.join(", ")} bg="white" order={4}>
                                Conversation with {rows.length} Reports:{" "}
                                {rows.join(", ").slice(0, 150)}
                            </Title>
                        </Stack>
                        <ScrollArea
                            styles={{
                                viewport: {
                                    alignContent: "end",
                                },
                            }}
                            style={{ height: "100%", alignContent: "bottom" }}
                            pt="100px"
                            sx={{ alignContent: "bottom" }}
                        >
                            <ChatRouteChild
                                isChatWithDocument
                                documentType={index}
                                isCompact
                                files={rows}
                                selectedQuestions={[]}
                                chatId={newChatId}
                                metadataFilter={rows.map((row) => {
                                    return {
                                        key: getMetadataKey(index),
                                        value: row,
                                    };
                                })}
                                messages={messages}
                            />
                        </ScrollArea>
                    </Grid.Col>
                    <Grid.Col span={7} sx={{ overflow: "scroll", maxHeight: "90vh" }}>
                        <PDFList
                            rows={rows}
                            // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                            metadataDocumentsLive={flatData ?? ([] as any)}
                            instance={instance}
                            apiKey={apiKey}
                        />
                    </Grid.Col>
                </Grid>
            </Drawer>
            <Feature name="CHAT_WITH_REPORTS">
                {REPORT_INDICES.includes(index) ? (
                    <Button
                        loading={isLoading}
                        onClick={() => {
                            if (hasFilters) {
                                open(); // Open the drawer
                                clickHandler(); // Navigate to the new chat
                            } else {
                                clickHandler();
                            }
                        }}
                    >
                        {Object.keys(rows).length > 0
                            ? "Search inside selected reports"
                            : "Start a conversation"}
                    </Button>
                ) : null}
            </Feature>
        </>
    );
};

export default TopToolbarCustomActions;
