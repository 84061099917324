import { useLiveQuery } from "dexie-react-hooks";
import { useEffect, useState } from "react";
import { useTour } from "src/contexts/tourContext";
import { useApiHealth } from "src/hooks/useApiHealth";
import { useMetadata } from "src/hooks/useMetadata";
import { useNotifications } from "src/hooks/useNotifications";
import { useSyncUserRoles } from "src/hooks/useSyncUserRoles";
import { useAdmin, useNotAuthorizedUser } from "src/utils/useAdmin";
import { GuidedTour } from "./GuidedTour";
import { tourSteps } from "./GuidedTourSteps";

import { AuthenticatedTemplate, useAccount, useMsal } from "@azure/msal-react";
import {
    ActionIcon,
    Anchor,
    AppShell,
    Box,
    Burger,
    Button,
    Center,
    Container,
    Flex,
    Group,
    Header,
    MediaQuery,
    Navbar,
    Notification,
    ScrollArea,
    Text,
    TextInput,
    Title,
    Tooltip,
    rem,
    useMantineTheme,
} from "@mantine/core";
import {
    IconDatabase,
    IconExclamationCircle,
    IconLogout,
    IconMessage2Plus,
    IconSearch,
    IconX,
} from "@tabler/icons-react";
import { Link, Outlet, useNavigate, useRouter } from "@tanstack/react-router";

import { IconDotsVertical } from "@tabler/icons-react";
import { useLocalStorage } from "@uidotdev/usehooks";
import { Feature } from "flagged";
import { SiemensLogo } from "../assets/SiemensLogo";
import { useTab } from "../contexts/tabContext";
import { chatVectorIndex, db } from "../db";
import { loginRequest } from "../utils/authConfig";
import { getFilterDisplayText } from "../utils/filterDisplayUtils";
import { AdminMenu } from "./AdminMenu";
import { Chats } from "./Chats";
import { DatabaseModal } from "./DatabaseModal";
import { IngestionStatusOverviewAnchor } from "./IngestionStatusOverview";

interface ToDesktop {
    send: (channel: string, ...args: unknown[]) => void;
    receive: (channel: string, func: (...args: unknown[]) => void) => void;
}

interface AppNotification {
    id: string;
    level: string;
    content: string;
    timestamp: number;
}

declare global {
    interface Window {
        todesktop?: ToDesktop;
    }
}

export function Layout({ withSidebar = true }) {
    const isAdmin = useAdmin();
    const theme = useMantineTheme();
    const { resetChatMetadata } = useMetadata();
    const [opened, setOpened] = useState(true);
    const [loading, setLoading] = useState(false);
    const { selectedTabShorthand, selectedTab } = useTab();
    const navigate = useNavigate();
    const router = useRouter();
    const [search, setSearch] = useState("");
    const { isTourOpen, completeTour } = useTour();
    const [acquireToken, setAcquireToken] = useLocalStorage("acquireToken", false);
    const [preferenceDisplay, setPreferenceDisplay] = useState("My Preferences");
    const { data: apiHealth } = useApiHealth();

    // Call the hook to sync user roles on app start
    useSyncUserRoles();

    const { instance, inProgress } = useMsal();
    const account = useAccount();
    const handleLogoutRedirect = () => {
        // Clear any session data
        sessionStorage.clear();
        // Log out and redirect to login page
        instance.logoutRedirect({
            postLogoutRedirectUri: window.location.origin,
        });
    };

    const getNotificationColor = (severity: string) => {
        switch (severity) {
            case "error":
                return theme.colors.red[6];
            case "warn":
                return theme.colors.yellow[6];
            case "info":
                return theme.colors.blue[6];
            default:
                return theme.colors.dark[6];
        }
    };

    const chats = useLiveQuery(() => db.chats.orderBy("createdAt").reverse().toArray());
    const isNotAuthorized = useNotAuthorizedUser();
    const { data: systemNotifications } = useNotifications();
    const [visibleNotifications, setVisibleNotifications] = useState<Array<AppNotification>>([]);

    useEffect(() => {
        if (acquireToken) {
            setAcquireToken(false);
            instance.acquireTokenRedirect(loginRequest());
            instance.loginRedirect(loginRequest());
        }
    }, [acquireToken, instance, setAcquireToken]);

    useEffect(() => {
        if (!systemNotifications || systemNotifications.length === 0) return;

        // Update to add timestamp to notifications
        const updatedNotifications = systemNotifications.map((notification) => ({
            ...notification,
            timestamp: Date.now(),
        }));

        const dismissedNotifications = JSON.parse(
            localStorage.getItem("dismissedNotifications") || "[]",
        );
        const filteredNotifications = updatedNotifications.filter(
            (notification) => !dismissedNotifications.includes(notification.id),
        );
        setVisibleNotifications(filteredNotifications);
    }, [systemNotifications]);

    const handleDismissNotification = (id: string) => {
        const dismissedNotifications = JSON.parse(
            localStorage.getItem("dismissedNotifications") || "[]",
        );
        dismissedNotifications.push(id);
        localStorage.setItem("dismissedNotifications", JSON.stringify(dismissedNotifications));
        setVisibleNotifications((prev) => prev.filter((n) => n.id !== id));
    };

    // Get the chatPreferences hook directly
    const [chatPreferencesData] = useLocalStorage<{
        selectedProductLines: string[];
        selectedDatasources: string[];
    }>("chatPreferences", {
        selectedProductLines: [],
        selectedDatasources: [],
    });

    // Update preference display when chatPreferences change
    useEffect(() => {
        // Get the display text using our shared utility function
        const displayText = getFilterDisplayText(chatPreferencesData);
        setPreferenceDisplay(displayText);
    }, [chatPreferencesData]);

    useEffect(() => {
        if (inProgress === "handleRedirect" || (!instance && inProgress !== "none")) return;

        if (!account) {
            instance
                .loginRedirect(loginRequest())
                .catch((error) => console.error("Login redirect error:", error));
            return;
        }

        if (account && isNotAuthorized) {
            navigate({ to: "/unauthorized" });
        }
    }, [inProgress, account, instance, isNotAuthorized, navigate]);

    return (
        <AppShell
            navbarOffsetBreakpoint="md"
            layout="default"
            header={
                <Header
                    bg="white"
                    height={80}
                    p={`0 ${rem(30)}`}
                    sx={{ borderColor: theme.colors.gray[1] }}
                >
                    <Flex align="center" h="100%" justify="space-between">
                        <Link to="/" style={{ textDecoration: "none" }}>
                            <SiemensLogo />
                        </Link>
                        <Link to="/" style={{ textDecoration: "none" }}>
                            <Title order={4} c="purple.8" ml="xl" lh={2}>
                                CTS Knowledge Search
                            </Title>
                        </Link>
                        <Container
                            sx={{
                                position: "absolute",
                                left: "47.5%",
                                transform: "translateX(-50%)",
                                width: "50%",
                            }}
                        >
                            {/* API Health Check Notification */}
                            {apiHealth?.isHealthy === false && (
                                <Notification
                                    color="red"
                                    icon={<IconExclamationCircle size="1.3rem" />}
                                    withCloseButton={false}
                                    styles={(theme) => ({
                                        root: {
                                            backgroundColor: theme.colors.red[6],
                                            borderColor: theme.colors.red[6],
                                            marginBottom: theme.spacing.xs,
                                        },
                                        description: {
                                            color: "white",
                                            fontWeight: 700,
                                        },
                                        icon: {
                                            color: "white",
                                        },
                                        closeButton: {
                                            color: "white",
                                            "&:hover": {
                                                backgroundColor: "transparent",
                                            },
                                        },
                                    })}
                                >
                                    System is unreachable. Please make sure you have connectivity to
                                    Siemens Energy network. If problem persists contact CTSKS
                                    administrators.
                                </Notification>
                            )}

                            {visibleNotifications?.map((notification) => (
                                <Notification
                                    key={`${notification.id || notification.content}-${notification.timestamp || Date.now()}`}
                                    color={
                                        notification.level === "error"
                                            ? "red"
                                            : notification.level === "warn"
                                              ? "yellow"
                                              : "blue"
                                    }
                                    icon={<IconExclamationCircle size="1.3rem" />}
                                    onClose={() => handleDismissNotification(notification.id)}
                                    sx={{
                                        display:
                                            visibleNotifications.indexOf(notification) === 0
                                                ? "flex"
                                                : "none",
                                    }}
                                    styles={(theme) => ({
                                        root: {
                                            backgroundColor: getNotificationColor(
                                                notification.level,
                                            ),
                                            borderColor: getNotificationColor(notification.level),
                                            borderWidth: 1,
                                            borderStyle: "solid",
                                        },
                                        description: {
                                            color: "white",
                                            fontWeight: 700,
                                        },
                                        icon: {
                                            marginRight: theme.spacing.sm,
                                            color: "white",
                                        },
                                        closeButton: {
                                            color: "white",
                                            "&:hover": {
                                                backgroundColor: "transparent",
                                            },
                                        },
                                    })}
                                >
                                    {notification.content}
                                </Notification>
                            ))}
                        </Container>
                        <Group ml="auto" sx={{ display: isNotAuthorized ? "none" : "" }}>
                            <Anchor
                                onClick={() => navigate({ to: "/documents" })}
                                data-tour="documents-viewer"
                            >
                                Documents Viewer
                            </Anchor>
                            <Anchor onClick={() => navigate({ to: "/" })}>
                                Start a Conversation
                            </Anchor>
                            <Feature name="DATASOURCE_DETAILS">
                                <IngestionStatusOverviewAnchor />
                            </Feature>
                            <AdminMenu isAdmin={isAdmin} />
                            <Group spacing="md" position="right">
                                <Group
                                    spacing="xs"
                                    sx={(theme) => ({
                                        cursor: "pointer",
                                        padding: "6px 12px",
                                        borderRadius: theme.radius.md,
                                        border: `1px solid ${theme.colors.gray[3]}`,
                                        backgroundColor: theme.fn.rgba(theme.colors.violet[0], 0.3),
                                        transition: "all 0.2s ease",
                                        "&:hover": {
                                            backgroundColor: theme.fn.rgba(
                                                theme.colors.violet[1],
                                                0.5,
                                            ),
                                            borderColor: theme.colors.violet[3],
                                            boxShadow: `0 2px 4px ${theme.fn.rgba(theme.colors.dark[9], 0.05)}`,
                                        },
                                    })}
                                    onClick={() =>
                                        navigate({
                                            to: "/questionnaire",
                                            search: !router.state.location.pathname.includes(
                                                "questionnaire",
                                            )
                                                ? ({
                                                      source: router.state.location.pathname,
                                                      // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                                                  } as any)
                                                : {},
                                        })
                                    }
                                >
                                    <Text size="xs" weight={600} color={theme.colors.violet[7]}>
                                        {preferenceDisplay}
                                    </Text>
                                    <IconDotsVertical
                                        size={12}
                                        stroke={1.5}
                                        color={theme.colors.violet[7]}
                                    />
                                </Group>
                            </Group>
                        </Group>
                        <Group>
                            {account && isNotAuthorized ? (
                                <Group spacing="xs">
                                    <Text size="sm" color="dimmed">
                                        Logged in as: {account.username}
                                    </Text>
                                    <Button
                                        leftIcon={<IconLogout size={16} />}
                                        variant="light"
                                        onClick={handleLogoutRedirect}
                                        color="red"
                                    >
                                        Logout
                                    </Button>
                                </Group>
                            ) : null}
                        </Group>
                    </Flex>
                </Header>
            }
            navbar={
                withSidebar && !isNotAuthorized ? (
                    <Navbar
                        sx={{ borderColor: theme.colors.gray[1] }}
                        width={{ md: rem(200), lg: rem(300) }}
                        hiddenBreakpoint="md"
                        hidden={!opened}
                    >
                        <Navbar.Section
                            sx={() => ({
                                padding: rem(4),
                            })}
                        >
                            <Box
                                sx={{
                                    padding: 4,
                                    marginTop: ".5rem",
                                    marginBottom: ".25rem",
                                }}
                            >
                                <Button
                                    fullWidth
                                    variant="light"
                                    bg="purple.1"
                                    c="white"
                                    leftIcon={<IconMessage2Plus size={16} />}
                                    onClick={async () => {
                                        // const chatId = await ChatEntity._(selectedTab).add();
                                        resetChatMetadata();
                                        navigate({ to: `/?tab=${selectedTab}` });
                                        // document.querySelector("#chat-input").focus();
                                    }}
                                    data-tour="new-chat"
                                >
                                    New Chat
                                </Button>
                            </Box>
                        </Navbar.Section>
                        <Navbar.Section
                            sx={() => ({
                                padding: `${rem(2)} ${rem(4)}`,
                            })}
                        >
                            <TextInput
                                variant="default"
                                // radius={0}
                                placeholder="Search"
                                value={search}
                                onChange={(event) =>
                                    setSearch(event.currentTarget.value.toLowerCase())
                                }
                                sx={{ paddingInline: 4 }}
                                icon={<IconSearch opacity={0.8} size={20} />}
                                rightSection={
                                    !!search && (
                                        <ActionIcon onClick={() => setSearch("")}>
                                            <IconX opacity={0.5} size={20} />{" "}
                                        </ActionIcon>
                                    )
                                }
                                data-tour="search"
                            />
                        </Navbar.Section>
                        <Navbar.Section grow component={ScrollArea} data-tour="chat-history">
                            {chatVectorIndex.map((index) =>
                                selectedTabShorthand === index.shorthand ? (
                                    <Chats
                                        key={index.shorthand}
                                        chats={chats}
                                        search={search}
                                        type={index.shorthand}
                                    />
                                ) : null,
                            )}
                        </Navbar.Section>{" "}
                        <Navbar.Section p="xs">
                            <Center sx={{ gap: "1rem" }} data-tour="database-action">
                                <DatabaseModal>
                                    <Tooltip label="Database">
                                        <ActionIcon sx={{ flex: 1 }} size="xl">
                                            <IconDatabase color="currentColor" size={20} />
                                        </ActionIcon>
                                    </Tooltip>
                                </DatabaseModal>
                                <Tooltip label="Logout">
                                    <ActionIcon
                                        sx={{ flex: 1 }}
                                        size="xl"
                                        onClick={handleLogoutRedirect}
                                        title={
                                            instance.getActiveAccount()?.username
                                                ? instance.getActiveAccount()?.username
                                                : "Unknown"
                                        }
                                    >
                                        <IconLogout color="currentColor" />
                                    </ActionIcon>
                                </Tooltip>
                            </Center>
                        </Navbar.Section>
                    </Navbar>
                ) : undefined
            }
            padding={0}
        >
            <GuidedTour
                steps={tourSteps}
                onComplete={completeTour}
                onClose={completeTour}
                isOpen={isTourOpen}
            />

            <MediaQuery largerThan="md" styles={{ display: "none" }}>
                <Burger
                    opened={opened}
                    onClick={() => setOpened((o) => !o)}
                    size="sm"
                    color={theme.colors.gray[6]}
                    className="app-region-no-drag"
                    sx={{ position: "fixed", top: 16, right: 16, zIndex: 100 }}
                />
            </MediaQuery>
            <AuthenticatedTemplate>
                <Outlet />
            </AuthenticatedTemplate>
        </AppShell>
    );
}
