import type { IPublicClientApplication } from "@azure/msal-browser";
import { notifications } from "@mantine/notifications";
import { CHAT_COMPLETIONS_URL } from "../hooks/useChatCompletion";
import { loginRequest } from "../utils/authConfig";

export const getFullUrl = (url: string): string => {
    if (url.startsWith("http")) {
        return url;
    }
    const hrefWithoutSandbox = url
        .replace("sandbox:", "")
        .replace("http://example.com", "")
        .replace("https://example.com", "");

    const decodedHref = decodeURIComponent(hrefWithoutSandbox);
    // Handle special case for storage file URLs
    const finalHref = decodedHref.includes("object_name=/")
        ? decodedHref.replace("object_name=/", "object_name=")
        : decodedHref;

    return `${CHAT_COMPLETIONS_URL}${finalHref.startsWith("/") ? "" : "/"}${finalHref}`;
};

export const ARTIFACT_EXTENSIONS = [
    ".pdf",
    ".html",
    ".doc",
    ".docx",
    ".ppt",
    ".pptx",
    ".xls",
    ".xlsx",
    ".msg",
    ".csv",
    ".json",
    ".txt",
];

export const isArtifactURL = (url?: string): boolean => {
    return ARTIFACT_EXTENSIONS.some((extension) => url?.toLowerCase().endsWith(extension));
};

export const getPdfUrl = async (
    pdfUrl: string,
    apiKey: string | undefined,
    instance: IPublicClientApplication,
): Promise<string | null> => {
    try {
        const token = `Bearer ${apiKey}`;
        const fetchResponse = await fetch(pdfUrl, {
            headers: {
                Authorization: token,
                Accept: "application/pdf",
            },
        });

        if (fetchResponse.status === 201) {
            return fetchResponse.text();
        }
        await instance.loginRedirect(loginRequest());
        notifications.show({
            title: "Error opening PDF",
            message: `Could not open PDF from URL: ${pdfUrl}`,
            color: "red",
        });
        return null;
    } catch (error) {
        console.error(`Error getting PDF URL: ${pdfUrl}`, error);
        return null;
    }
};

export const handleArtifactClick = async (
    e: React.MouseEvent<HTMLAnchorElement>,
    source: string,
    apiKey: string | undefined,
    instance: IPublicClientApplication,
) => {
    if (!source) {
        e.preventDefault();
        return;
    }
    const fullUrl = getFullUrl(source);
    if (isArtifactURL(source)) {
        e.preventDefault();
        if (fullUrl.startsWith(CHAT_COMPLETIONS_URL)) {
            await openS3FromUrl(fullUrl, apiKey, instance);
        } else {
            window.open(fullUrl, "_blank");
        }
    }
};

export const openS3FromUrl = async (
    pdfUrl: string,
    apiKey: string | undefined,
    instance: IPublicClientApplication,
) => {
    try {
        const url = await getPdfUrl(pdfUrl, apiKey, instance);
        if (url) {
            const newWindow = window.open(url, "_blank", "");
            if (newWindow) {
                newWindow.onload = () => {
                    URL.revokeObjectURL(url);
                };
            } else {
                const a = document.createElement("a");
                a.href = url;
                a.target = "_blank";
                a.rel = "noopener noreferrer";
                a.click();
            }
        }
    } catch (error) {
        console.error(`Error opening S3 from URL: ${pdfUrl}`, error);
    }
};
