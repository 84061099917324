import { useFeature } from "flagged";
import { useEffect, useState } from "react";
import {
    markTourAsCompleted,
    resetTour as resetTourUtil,
    shouldShowTour,
} from "../components/GuidedTourSteps";

const TOUR_ID = "main-tour";

export function useGuidedTour() {
    const [isTourOpen, setIsTourOpen] = useState(false);
    const isGuidedTourEnabled = useFeature("GUIDED_TOUR");

    useEffect(() => {
        // Check if the tour should be shown and if the feature is enabled
        if (isGuidedTourEnabled && shouldShowTour(TOUR_ID)) {
            // Small delay to ensure the UI is fully rendered
            const timer = setTimeout(() => {
                setIsTourOpen(true);
            }, 1500);

            return () => clearTimeout(timer);
        }
    }, [isGuidedTourEnabled]);

    const completeTour = () => {
        // Mark the tour as completed
        markTourAsCompleted(TOUR_ID);
        setIsTourOpen(false);
    };

    const resetTour = () => {
        // Only reset if the feature is enabled
        if (!isGuidedTourEnabled) return;

        // Reset the tour to show it again
        resetTourUtil(TOUR_ID);
        // Set timeout to ensure DOM elements are available when the tour starts
        setTimeout(() => {
            setIsTourOpen(true);
        }, 300);
    };

    return {
        isTourOpen,
        completeTour,
        resetTour,
    };
}
