import { useFeatures } from "flagged";
import { useEffect } from "react";
import MetadataFeedback from "src/components/MetadataFeedback";

import { Box, Flex, Title, useMantineTheme } from "@mantine/core";
import { useLocation, useNavigate } from "@tanstack/react-router";

export const FeedbackRoute = () => {
    const navigate = useNavigate();
    const theme = useMantineTheme();

    const features = useFeatures();

    return (
        <Flex p="xl" direction="column" gap="md">
            <Flex justify="space-between">
                <Title lh={2} c="purple.8" order={2} transform="uppercase">
                    Feedback
                </Title>
            </Flex>
            <Box>
                <MetadataFeedback />
            </Box>
        </Flex>
    );
};
