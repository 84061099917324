import { useAccount } from "@azure/msal-react";
import { Box, Button, Card, Chip, Container, Group, Radio, Stack, Text } from "@mantine/core";
import { createFileRoute, useLocation, useNavigate } from "@tanstack/react-router";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useEffect, useRef, useState } from "react";
import { useUser } from "src/hooks/useUser";

interface Category {
    id: string;
    title: string;
    name?: string;
    subcategories: string[];
}

interface RadioCategoryItemProps {
    category: Category;
    selected: boolean;
    onSelect: (categoryId: string) => void;
    index: number;
    disabled?: boolean;
    dataSources?: Array<{ id: string; name: string }>;
    selectedDataSources: Record<string, boolean>;
    onDataSourceToggle: (dataSourceId: string, selected: boolean) => void;
    availableDataSourcesForProductLine: Set<string>;
}

function RadioCategoryItem({
    category,
    selected,
    onSelect,
    index,
    disabled = false,
    dataSources = [],
    selectedDataSources,
    onDataSourceToggle,
    availableDataSourcesForProductLine,
}: RadioCategoryItemProps) {
    const handleCardClick = () => {
        if (!disabled) {
            onSelect(category.id);
        }
    };

    const filteredDataSources = dataSources.filter(
        (ds) => category.id === "all" || availableDataSourcesForProductLine.has(ds.id),
    );

    return (
        <div>
            <Card
                shadow="sm"
                p="lg"
                radius="md"
                withBorder
                onClick={handleCardClick}
                sx={(theme) => ({
                    cursor: disabled ? "not-allowed" : "pointer",
                    borderColor: selected ? theme.colors.blue[5] : theme.colors.gray[3],
                    padding: theme.spacing.lg,
                    opacity: disabled ? 0.6 : 1,
                })}
            >
                <Box mb={8}>
                    <Group spacing={10}>
                        <Radio
                            // @ts-ignore TODO This property does not exist on the available RadioProps type,
                            // to be checked: Is this needed at all?
                            radius="xs"
                            size="xs"
                            checked={selected}
                            disabled={disabled}
                            onChange={(event) => {
                                event.stopPropagation();
                                if (!disabled) {
                                    onSelect(category.id);
                                }
                            }}
                            styles={(theme) =>
                                ({
                                    input: {
                                        backgroundColor:
                                            theme.colorScheme === "dark"
                                                ? theme.colors.dark[6]
                                                : theme.white,
                                    },
                                    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                                }) as any
                            }
                        />
                        <Text size="sm" weight={600}>
                            {category.title}
                        </Text>
                        {disabled && (
                            <Text size="xs" color="dimmed" italic>
                                (Not available for selected product line)
                            </Text>
                        )}
                    </Group>
                </Box>

                {selected && filteredDataSources.length > 0 && (
                    <Group ml={24} spacing={8} mt={12}>
                        {filteredDataSources.map((dataSource) => {
                            const isSelected = selectedDataSources[dataSource.id] === true;

                            return (
                                <Chip
                                    key={dataSource.id}
                                    checked={isSelected}
                                    onChange={() => {
                                        onDataSourceToggle(dataSource.id, !isSelected);
                                    }}
                                    size="xs"
                                    radius="sm"
                                    variant="filled"
                                    color="violet"
                                    styles={{
                                        root: {
                                            cursor: "pointer",
                                        },
                                        label: {
                                            cursor: "pointer",
                                            fontWeight: isSelected ? 600 : 400,
                                        },
                                    }}
                                >
                                    {dataSource.name}
                                </Chip>
                            );
                        })}
                    </Group>
                )}
            </Card>
        </div>
    );
}

function Questionnaire() {
    const [selectedProductLine, setSelectedProductLine] = useState<string>("");
    const [dataSourceSelections, setDataSourceSelections] = useState<Record<string, boolean>>({});
    const [loading, setLoading] = useState(false);
    const [productLineDataSources, setProductLineDataSources] = useState<
        Record<string, Set<string>>
    >({});
    const navigate = useNavigate();
    const { data: user, isLoading: userLoading } = useUser();
    const account = useAccount();
    const location = useLocation();
    const { source } = location.search as { source?: string };

    const initialized = useRef(false);
    const productLineInitialized = useRef(false);

    interface UserDatasource {
        id: string;
        name: string;
        product_line_filter?: string[];
    }

    const [productLines, setProductLines] = useLocalStorage<Category[]>("availableProductLines", [
        { id: "all", title: "Default Filters", subcategories: [] },
    ]);

    const [userDatasources, setUserDatasources] = useLocalStorage<UserDatasource[]>(
        "datasources",
        [],
    );

    const [chatPreferences, setChatPreferences] = useLocalStorage<{
        selectedProductLines: string[];
        selectedDatasources: string[];
    }>("chatPreferences", {
        selectedProductLines: [],
        selectedDatasources: [],
    });

    const actualDatasources = userDatasources;

    useEffect(() => {
        if (initialized.current) return;
        if (!user || !user.datasources) return;

        initialized.current = true;
        setUserDatasources(user.datasources as UserDatasource[]);

        const uniqueProductLines = [
            ...new Set(user.datasources.flatMap((ds) => ds.product_line_filter) || []),
        ].sort();

        const productLineToDataSources: Record<string, Set<string>> = {};

        productLineToDataSources.all = new Set(user.datasources.map((ds) => ds.id));

        for (const line of uniqueProductLines) {
            productLineToDataSources[line] = new Set(
                user.datasources
                    .filter((ds) => ds.product_line_filter.includes(line))
                    .map((ds) => ds.id),
            );
        }

        setProductLineDataSources(productLineToDataSources);

        const productLinesData = [
            {
                id: "all",
                title: "Default Filters",
                subcategories: [],
            },
            ...uniqueProductLines.map((line) => {
                const availableSources = user.datasources
                    .filter((ds) => ds.product_line_filter.includes(line))
                    .map((ds) =>
                        ds.name
                            .replace(" Cases", "")
                            .replace(" Reports", "")
                            .replace(" Database", ""),
                    );

                return {
                    id: line,
                    title: line,
                    subcategories: availableSources,
                };
            }),
        ];

        setProductLines(productLinesData);
    }, [user, setUserDatasources, setProductLines]);

    useEffect(() => {
        if (productLineInitialized.current) return;
        if (!chatPreferences || Object.keys(productLineDataSources).length === 0) return;

        productLineInitialized.current = true;

        const initialProductLine =
            chatPreferences.selectedProductLines?.length > 0
                ? chatPreferences.selectedProductLines[0]
                : "all";

        setSelectedProductLine(initialProductLine);

        const initialSelections: Record<string, boolean> = {};

        if (productLineDataSources[initialProductLine]) {
            if (chatPreferences.selectedDatasources?.length > 0) {
                for (const dsId of chatPreferences.selectedDatasources) {
                    if (productLineDataSources[initialProductLine].has(dsId)) {
                        initialSelections[dsId] = true;
                    }
                }

                if (Object.keys(initialSelections).length === 0) {
                    for (const dsId of productLineDataSources[initialProductLine]) {
                        initialSelections[dsId] = true;
                    }
                }
            } else {
                for (const dsId of productLineDataSources[initialProductLine]) {
                    initialSelections[dsId] = true;
                }
            }
        }

        setDataSourceSelections(initialSelections);
    }, [chatPreferences, productLineDataSources]);

    const handleProductLineSelect = (categoryId: string) => {
        if (categoryId === selectedProductLine) return;

        setSelectedProductLine(categoryId);

        if (!productLineDataSources[categoryId]) {
            setDataSourceSelections({});
            return;
        }

        const freshSelections: Record<string, boolean> = {};
        for (const dsId of productLineDataSources[categoryId]) {
            freshSelections[dsId] = true;
        }

        setDataSourceSelections(freshSelections);
    };

    const handleDataSourceToggle = (dataSourceId: string, isChecked: boolean) => {
        setDataSourceSelections((prev) => {
            const newSelections = { ...prev };
            newSelections[dataSourceId] = isChecked;
            return newSelections;
        });
    };

    const handleFinish = () => {
        const selectedDataSources = Object.keys(dataSourceSelections).filter(
            (key) => dataSourceSelections[key] === true,
        );

        setChatPreferences({
            selectedProductLines: selectedProductLine ? [selectedProductLine] : [],
            selectedDatasources: selectedDataSources,
        });

        setLoading(true);
        setTimeout(() => {
            if (source) {
                navigate({ to: source });
            } else {
                navigate({ to: "/" });
            }
            setLoading(false);
        }, 100);
    };

    const getAvailableDataSourcesForProductLine = (productLine: string): Set<string> => {
        return productLineDataSources[productLine] || new Set();
    };

    return (
        <main className="mx-auto p-4">
            <Container size="xs">
                <div>
                    <Text size="xl" weight={600}>
                        Would you like to filter the search results by product line and data
                        sources?
                    </Text>
                    <Text size="sm" color="dimmed" mt={24} mb={24}>
                        This is an optional filter to narrow down the search results, based on the
                        product line and data sources you are interested in.
                    </Text>

                    <Stack spacing="sm">
                        {productLines.map((category, index) => (
                            <RadioCategoryItem
                                key={category.id}
                                category={category}
                                selected={selectedProductLine === category.id}
                                onSelect={handleProductLineSelect}
                                index={index}
                                dataSources={actualDatasources}
                                selectedDataSources={dataSourceSelections}
                                onDataSourceToggle={handleDataSourceToggle}
                                availableDataSourcesForProductLine={getAvailableDataSourcesForProductLine(
                                    category.id,
                                )}
                            />
                        ))}
                    </Stack>

                    <Group position="right" mt={24}>
                        <Button
                            onClick={handleFinish}
                            disabled={loading || !selectedProductLine}
                            loading={loading}
                            sx={(theme) => ({
                                "&:disabled": {
                                    backgroundColor: theme.colors.gray[2],
                                    color: theme.colors.gray[5],
                                },
                            })}
                        >
                            {loading ? "Submitting..." : "Save Preferences"}
                        </Button>
                    </Group>
                </div>

                <style>
                    {`
                    @keyframes revealBottom {
                      from {
                        opacity: 0;
                        transform: translateY(20px);
                      }
                      to {
                        opacity: 1;
                        transform: translateY(0);
                      }
                    }
                  `}
                </style>
            </Container>
        </main>
    );
}

export const Route = createFileRoute("/_layout-nosidebar/questionnaire")({
    component: Questionnaire,
});
