import { Box, Button, Container, Text, Title } from "@mantine/core";
import { Link, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";

export function NotFound() {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate({ to: "/" });
        }, 1000);

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <Container>
            <Box ta="center" py="xl">
                <Title>Page Not Found</Title>
                <Text mb="md">
                    Sorry, we couldn't find what you were looking for. Redirecting to the
                    homepage...
                </Text>
                <Button component={Link} to="/">
                    Return to Home
                </Button>
            </Box>
        </Container>
    );
}
