export const defaultModel = "gpt-3.5-turbo";

export const availableModels = [];

export const REPORT_INDICES = ["fsr", "rds"];

export const METADATA_KEY_MAPPING: Record<string, string> = {
    rds: "metadata.file_name",
    fsr: "metadata.report_name",
};

export const TAB_INDEX_MAPPING: Record<string, string> = {
    fsr: "field_service_reports",
    sf: "salesforce",
    rds: "rds_reports",
    mindmaps: "mindmaps",
    drawings: "drawings",
    emails: "support_emails",
    uinfo: "useful_info",
    combined: "combined",
    ads_good_solutions: "ads_good_solutions",
};

export const TRAINING_URL =
    "https://siemens-energy-learning.sabacloud.com/Saba/Web_spf/EU5P1PRD001/app/me/learningeventdetail/cours000000000184778)";
