import {
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_SortingState,
    type MRT_Virtualizer,
    MantineReactTable,
    useMantineReactTable,
} from "mantine-react-table";
import { type UIEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { CTS_USER_CHAT } from "src/db";
import { BACKEND_URL } from "src/hooks/useMetadata";
import { getAPI, postAPI } from "src/utils/fetch";
import { useAdmin } from "src/utils/useAdmin";

import { useAccount, useMsal } from "@azure/msal-react";
import { Anchor } from "@mantine/core";

import { metadataSAP } from "../../metadata-gen/metadata-sap";
import FeedbackModal from "./FeedbackModal";
import { PdfFocusProvider } from "./PDFViewer/context";
import TopToolbarCustomActions from "./TopToolbarCustomActions";

function csvJSON(csv: string) {
    const lines = csv.split("\n");
    const result = [];
    const headers = lines[0].split(",");

    for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const currentline = lines[i].split(",");

        for (let j = 0; j < headers.length; j++) {
            // @ts-ignore
            obj[headers[j]] = currentline[j];
        }

        result.push(obj);
    }

    return result;
}

const OPENSEARCH_DASHBOARD_URL = import.meta.env.VITE_OPENSEARCH_DASHBOARD_URL;

const metaSAPJson = csvJSON(metadataSAP);

export type FeedbackSchema = {
    _index: string;
    _id: string;
    _score: number;
    _source: {
        timestamp: string;
        conversation_id: string;
        user: string;
        source: string;
        question: string;
        cts_answer: {
            answer: string;
            feedback_type: string;
            comments: string;
        };
    };
};

const feedbackColumns: MRT_ColumnDef<FeedbackSchema>[] = [
    {
        id: "feedback__timestamp",
        accessorKey: "_source.timestamp",
        accessorFn: (originalRow) => {
            if (!originalRow._source) return "";
            const dateValue = originalRow._source?.timestamp
                ? new Date(originalRow._source.timestamp)
                : new Date("2000-01-01T00:00:00.000Z");
            if (dateValue) {
                dateValue.setHours(0, 0, 0, 0);
            }
            return dateValue;
        },
        header: "Timestamp",
        filterVariant: "date-range",
        sortingFn: "datetime",
        Cell: ({ cell }) => {
            const dateValue = cell.getValue<Date>();
            if (
                dateValue &&
                dateValue.getTime() !== new Date("2000-01-01T00:00:00.000Z").getTime()
            ) {
                return dateValue.toLocaleDateString(undefined, {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                });
            }
            return "N/A";
        },
        Header: ({ column }) => <em>{column.columnDef.header}</em>,
    },
    {
        id: "feedback__conversation_id",
        accessorKey: "_source.conversation_id",
        accessorFn: (originalRow) => {
            return originalRow._source ? originalRow._source.conversation_id || "N/A" : "N/A";
        },
        header: "Conversation ID",
        filterFn: "includesString",
    },
    {
        id: "feedback__user",
        accessorKey: "_source.user",
        accessorFn: (originalRow) => {
            return originalRow._source ? originalRow._source.user || "N/A" : "N/A";
        },
        header: "User",
        filterFn: "includesString",
    },
    {
        id: "feedback__source",
        accessorKey: "_source.source",
        accessorFn: (originalRow) => {
            return originalRow._source ? originalRow._source.source || "N/A" : "N/A";
        },
        header: "Source",
        filterFn: "includesString",
    },
    {
        id: "feedback__cts_answer.feedback_type",
        accessorKey: "_source.cts_answer.feedback_type",
        accessorFn: (originalRow) => {
            return originalRow._source
                ? originalRow._source.cts_answer.feedback_type || "N/A"
                : "N/A";
        },
        header: "Feedback Type",
        filterFn: "includesString",
    },
    {
        id: "feedback__cts_answer.comments",
        accessorKey: "_source.cts_answer.comments",
        accessorFn: (originalRow) => {
            return originalRow._source ? originalRow._source.cts_answer.comments || "N/A" : "N/A";
        },
        header: "Comments",
        filterFn: "includesString",
    },
    {
        id: "feedback__question",
        accessorKey: "_source.question",
        accessorFn: (originalRow) => {
            return originalRow._source ? originalRow._source.question || "N/A" : "N/A";
        },
        header: "Question",
        filterFn: "includesString",
    },
    // {
    //   id: "feedback__cts_answer.answer",
    //   accessorKey: "_source.cts_answer.answer",
    //   accessorFn: (originalRow) => {
    //     return originalRow._source
    //       ? originalRow._source.cts_answer.answer || "N/A"
    //       : "N/A";
    //   },
    //   header: "Answer",
    //   filterFn: "includesString",
    // },
];

const columnsMap: Record<"cts_user_chat", MRT_ColumnDef<FeedbackSchema>[]> = {
    cts_user_chat: feedbackColumns as MRT_ColumnDef<FeedbackSchema>[],
};

const MetadataFeedback = () => {
    // biome-ignore lint/correctness/useExhaustiveDependencies: CTS_USER_CHAT is intentionally included
    const columns = useMemo(() => columnsMap[CTS_USER_CHAT] ?? [], [CTS_USER_CHAT]);

    const rowVirtualizerInstanceRef =
        useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);

    const isAdmin = useAdmin();
    if (!isAdmin) {
        throw Error("Unauthorized");
    }

    const { instance } = useMsal();
    const account = useAccount();
    const apiKey = account?.idToken;

    const [scrollId, setScrollId] = useState<string | null>(null);

    // @ts-ignore TODO This invocation is incorrect, query key and query fn should be passed separately,
    // but there is no `positionExpandColumn` option.
    const { data, fetchNextPage, isFetching, isLoading } = useInfiniteQuery({
        queryKey: ["metadata-feedback", CTS_USER_CHAT, sorting, columnFilters],
        queryFn: async ({ pageParam = null }) => {
            const PATH = `v1/search/${CTS_USER_CHAT}/metadata/docs?max_result=25`;
            // biome-ignore lint/suspicious/noExplicitAny: Allow 'any' here for flexibility
            let response: any;
            if (pageParam) {
                const url = new URL("v1/search/scroll", BACKEND_URL);
                url.searchParams.set("_scroll_id", pageParam);
                response = await getAPI({
                    url: url.toString(),
                    apiKey,
                    instance,
                });
            } else {
                const url = new URL(PATH, BACKEND_URL);
                const columnFiltersPayload =
                    columnFilters
                        ?.filter((val) =>
                            Array.isArray(val.value)
                                ? val.value.filter(Boolean).length > 0
                                : Boolean(val.value),
                        )
                        .map((filter) => {
                            const key = filter.id.split("__")[1] ?? filter.id;

                            // If the key is 'timestamp' and the value is an array, map through it and replace undefined or null with an empty string
                            let value = filter.value;
                            if (key === "timestamp" && Array.isArray(value)) {
                                value = value.map((item) => (item == null ? "" : item)); // Using == to check for both null and undefined
                            }

                            return {
                                key: key,
                                value: value,
                            };
                        }) ?? [];

                // Reset scroll_id and add filters for the first requestq
                response = await postAPI({
                    url: url.toString(),
                    apiKey,
                    instance,
                    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                    payload: columnFiltersPayload as any,
                });
            }
            const data = await response.json();
            setScrollId(data._scroll_id);
            return data;
        },
        getNextPageParam: () => scrollId,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        // renderDetailPanel: ({ row }) =>
        // 	index === "drawings" ? (
        // 		<Box
        // 			sx={{
        // 				display: "grid",
        // 				margin: "auto",
        // 				gridTemplateColumns: "1fr 1fr",
        // 				width: "100%",
        // 			}}
        // 		>
        // 			<Text>Address: {row.original.address}</Text>
        // 			<Text>City: {row.original.city}</Text>
        // 			<Text>State: {row.original.state}</Text>
        // 			<Text>Country: {row.original.country}</Text>
        // 		</Box>
        // 	) : null,
        positionExpandColumn: "first",
    });

    const flatData = useMemo(
        () =>
            // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
            data?.pages.flatMap((page: any) =>
                page.hits.hits.map((doc) => ({
                    ...doc,
                    fields: doc._source.metadata,
                })),
            ) ?? [],
        [data],
    );

    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
    const totalDBRowCount = (data as any)?.pages?.[0]?.hits?.total?.value ?? 0;

    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    flatData.length < totalDBRowCount
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, flatData.length, totalDBRowCount],
    );
    // biome-ignore lint/correctness/useExhaustiveDependencies: sorting is required for correct behavior
    useEffect(() => {
        if (rowVirtualizerInstanceRef.current) {
            try {
                rowVirtualizerInstanceRef.current.scrollToIndex(0);
            } catch (e) {
                console.error(e);
            }
        }
    }, [sorting]);

    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);

    const [rowVirtualizerRef, setRowVirtualizerRef] = useState<MRT_Virtualizer<
        HTMLDivElement,
        HTMLTableRowElement
    > | null>(null);
    const tableContainerRef = useRef<HTMLDivElement>(null); //we can get access to the underlying TableContainer element and react to its scroll events

    const [selectedRow, setSelectedRow] = useState<FeedbackSchema | null>(null); // The modal will be open with this content when it is not null

    const table = useMantineReactTable({
        columns,
        data: flatData ?? [],
        enableColumnOrdering: true,
        enablePinning: true,
        enableBottomToolbar: false,
        renderToolbarAlertBannerContent: () => <></>,
        enableColumnResizing: true,
        enableColumnFilterModes: false,
        // TODO This used to specify a filter function, but this property doesn't actually exist.
        // It should be converted into using `filterFns`.
        // filterFn: "includesString",
        enablePagination: false,
        enableRowNumbers: true,
        enableClickToCopy: false,
        getRowId: (row) => {
            if (!row.fields) return undefined;
            return `${row._id}`;
        },
        renderTopToolbarCustomActions: () => (
            <>
                <TopToolbarCustomActions
                    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                    table={table as any}
                    index={CTS_USER_CHAT}
                    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                    data={data as any}
                    columnFilters={columnFilters}
                    // TODO This is a required prop but was not set previously, so it is currently set to false,
                    // but needs to be revisited.
                    isLoading={false}
                />
                <Anchor href={OPENSEARCH_DASHBOARD_URL} target="_blank">
                    Opensearch Dashboard
                </Anchor>
            </>
        ),
        mantineToolbarAlertBannerProps: {
            style: {
                display: "none",
                background: "#f0f0f0",
                fontWeight: "bold",
            },
        },
        onColumnFiltersChange: setColumnFilters,
        enableGlobalFilter: false,
        enableFullScreenToggle: false,
        enableSelectAll: false,
        selectAllMode: "page",
        enableGrouping: true,
        enableRowVirtualization: true,
        enableDensityToggle: false,
        mantineTableBodyRowProps: ({ row }) => ({
            onClick: () => setSelectedRow(row.original as FeedbackSchema),
            sx: {
                cursor: "pointer",
            },
        }),
        mantineTableContainerProps: {
            ref: tableContainerRef, //get access to the table container element
            sx: { padding: 0, borderRadius: 0, maxHeight: "80vh" },
            onScroll: (
                event: UIEvent<HTMLDivElement>, //add an event listener to the table container element
            ) => fetchMoreOnBottomReached(event.target as HTMLDivElement),
        },
        mantinePaperProps: { sx: { borderRadius: 0, zIndex: 102 } },
        onSortingChange: setSorting,
        state: { isLoading, sorting },
        initialState: {
            showGlobalFilter: true,
            density: "xs",
            showColumnFilters: true,
        },
        rowVirtualizerInstanceRef: ((instance) => {
            setRowVirtualizerRef(instance);
            // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
        }) as any,
        rowVirtualizerProps: {
            overscan: 10,
            onScroll: (event) => {
                const { scrollOffset, scrollHeight, clientHeight } = event;
                if (scrollOffset + clientHeight >= scrollHeight - 200) {
                    // Load more data when user scrolls to bottom
                    // Implement your data fetching logic here
                }
            },
            // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
        } as any,
    });
    // biome-ignore lint/correctness/useExhaustiveDependencies: sorting and rowVirtualizerRef are required
    useEffect(() => {
        if (rowVirtualizerRef) {
            rowVirtualizerRef.scrollToIndex(0);
        }
    }, [sorting, rowVirtualizerRef]);

    return (
        <PdfFocusProvider>
            <MantineReactTable table={table} />
            <FeedbackModal rowData={selectedRow} onClose={() => setSelectedRow(null)} />
        </PdfFocusProvider>
    );
};

export default MetadataFeedback;
