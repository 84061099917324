import { useLocation } from "@tanstack/react-router";

export function useChatId() {
    const location = useLocation();
    const pathname = location.pathname;

    const chatIdMatch = pathname.match(/\/(chats|documents|search)\/([^/]+)/);
    const chatId = chatIdMatch ? chatIdMatch[2] : null;

    return chatId;
}
