// Utility functions for handling filter display logic across components

/**
 * Interface for data source items from localStorage
 */
export interface DataSource {
    id: string;
    name?: string;
    title?: string;
    product_line_filter: string | string[];
}

/**
 * Interface for chat preferences structure
 */
export interface ChatPreferences {
    selectedProductLines?: string[];
    selectedDatasources?: string[];
}

/**
 * Generate display text for filters based on selected product lines and data sources
 * @param chatPreferences The user's selected preferences
 * @param isForTitle Whether the text is for a title (true) or button label (false)
 * @returns The formatted display text
 */
export function getFilterDisplayText(chatPreferences: ChatPreferences, isForTitle = false): string {
    try {
        let productLine = chatPreferences.selectedProductLines?.[0];
        if (!productLine) productLine = "Default Filters";
        if (productLine === "all") productLine = "Default Filters";

        const selectedSources = chatPreferences.selectedDatasources || [];

        // Get user datasources from localStorage
        const userDatasources = JSON.parse(
            localStorage.getItem("datasources") || "[]",
        ) as DataSource[];

        // Create a map of data source IDs to their human-readable titles
        const dataSourceMap: Record<string, string> = {};
        for (const ds of userDatasources) {
            dataSourceMap[ds.id] = ds.name || ds.title || ds.id; // Default to ID if name and title are undefined
        }

        // Get readable names for selected sources
        const sourceNames = selectedSources
            .map((sourceId: string) => dataSourceMap[sourceId] || sourceId)
            .map((name: string) =>
                name.replace(" Database", "").replace(" Cases", "").replace(" Reports", ""),
            );

        // For title display (ChatRoute)
        if (isForTitle) {
            const baseText = "CTS Knowledge Search";

            if (productLine === "Default Filters") {
                // Don't add anything when Default Filters are applied
                return baseText;
            }

            // Product line filters applied - find all possible data sources for this product line
            const allProductLineSources = userDatasources
                .filter((ds) => {
                    // Check if the data source belongs to this product line
                    return (
                        ds.product_line_filter === productLine ||
                        (Array.isArray(ds.product_line_filter) &&
                            ds.product_line_filter.includes(productLine))
                    );
                })
                .map((ds) => ds.id);

            // Check if all sources for this product line are selected
            const hasAllSourcesForProductLine =
                allProductLineSources.length > 0 &&
                allProductLineSources.every((id: string) => selectedSources.includes(id));

            // If all sources for this product line are selected or no sources are selected,
            // just show the product line name
            if (selectedSources.length === 0 || hasAllSourcesForProductLine) {
                // Just show the product line name
                return `${baseText} - ${productLine}`;
            }

            if (sourceNames.length === 1) {
                return `${baseText} - ${productLine} (${sourceNames[0]})`;
            }

            if (sourceNames.length <= 3) {
                return `${baseText} - ${productLine} (${sourceNames.join(", ")})`;
            }

            return `${baseText} - ${productLine} (${sourceNames.length} sources)`;
        }

        // For preference button display (Layout)
        if (productLine === "Default Filters") {
            return "Preferences: Default Filters";
        }

        // For specific product lines, use the same logic as title display
        // Find all possible data sources for this product line
        const allProductLineSources = userDatasources
            .filter((ds) => {
                // Check if the data source belongs to this product line
                return (
                    ds.product_line_filter === productLine ||
                    (Array.isArray(ds.product_line_filter) &&
                        ds.product_line_filter.includes(productLine))
                );
            })
            .map((ds) => ds.id);

        // Check if all sources for this product line are selected
        const hasAllSourcesForProductLine =
            allProductLineSources.length > 0 &&
            allProductLineSources.every((id: string) => selectedSources.includes(id));

        // If all sources for this product line are selected or no sources are selected,
        // just show the product line name
        if (selectedSources.length === 0 || hasAllSourcesForProductLine) {
            // Just show the product line name
            return `Preferences: ${productLine}`;
        }

        if (sourceNames.length === 1) {
            return `Preferences: ${productLine} (${sourceNames[0]})`;
        }

        if (sourceNames.length <= 3) {
            return `Preferences: ${productLine} (${sourceNames.join(", ")})`;
        }

        return `Preferences: ${productLine} (${sourceNames.length} sources)`;
    } catch (error) {
        console.error("Error generating filter display text:", error);
        return isForTitle ? "CTS Knowledge Search" : "Preferences";
    }
}
