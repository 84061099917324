import { Box, Flex, SegmentedControl, Title, useMantineTheme } from "@mantine/core";
import { useNavigate } from "@tanstack/react-router";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useFeatures } from "flagged";
import { useEffect, useState } from "react";
import MetadataDocuments from "src/components/MetadataDocuments";
import { type ChatVectorIndexShorthand, type FSR, type SF, chatVectorIndex } from "src/db";
import { type DatasourceIngestionStatus, useIngestionStatus } from "src/hooks/useIngestionStatus";

const titles: Record<ChatVectorIndexShorthand, string> = {
    fsr: "Field Service Reports",
    sf: "Salesforce Cases",
    drawings: "Engineering Drawings",
    rds: "RDS Reports",
    mindmaps: "SGT Mindmaps",
    combined: "Combined",
    emails: "Emails",
    uinfo: "SGT Useful Info",
    salesorders: "SGT Salesorders",
    ads_good_solutions: "Good Solutions",

    // TODO This is not shown in the Document Viewer since it is not a datasource,
    // but it is part of the ChatVectorIndexShorthand type, but it should be removed here.
    cts_user_chat: "CTS User Chat",
};

export const DocumentsRoute = ({
    index,
    setSelectedIndex,
}: {
    index: ChatVectorIndexShorthand;
    setSelectedIndex: React.Dispatch<React.SetStateAction<ChatVectorIndexShorthand>>;
}) => {
    const navigate = useNavigate();
    const theme = useMantineTheme();
    const [filterResetKey, setFilterResetKey] = useState(0);
    const [storedUserDatasources, setStoredUserDatasources] = useLocalStorage<string[]>(
        "userDatasources",
        [],
    );

    useEffect(() => {
        if (window.location.hash === "#/documents") navigate({ to: `/documents?tab=${index}` });
    }, [index, navigate]);

    const features = useFeatures();

    const { data: ingestionStatus } = useIngestionStatus();
    const datasourceIdentifier = chatVectorIndex.find((value) => value.shorthand === index)
        ?.value[0];
    const ingestionStatusForDatasource = Array.isArray(ingestionStatus?.data_sources)
        ? ingestionStatus?.data_sources.find((value) => value.id === datasourceIdentifier)
        : undefined;

    const ingestionStatusText = (
        status: DatasourceIngestionStatus | undefined,
    ): string | undefined => {
        if (!status) return undefined;

        return status.total_count > 0
            ? `${status.ingestion_status} (${status.total_count} documents)`
            : `${status.ingestion_status}`;
    };
    const normalizeString = (str: string) => str.toLowerCase().replace(/[_\s]/g, "");

    const getAvailableIndicesInUserRoles = () => {
        return chatVectorIndex.filter((vectorIndex) =>
            storedUserDatasources.some(
                (userDs) =>
                    normalizeString(userDs).includes(normalizeString(vectorIndex.label)) ||
                    normalizeString(vectorIndex.label).includes(normalizeString(userDs)),
            ),
        );
    };

    return (
        <Flex p="xl" direction="column" gap="md">
            <Flex justify="space-between">
                <Flex align={"baseline"} gap={"xs"} wrap={"wrap"}>
                    <Title lh={2} c="purple.8" order={2} transform="uppercase">
                        {titles[index]}
                    </Title>
                    {ingestionStatusForDatasource && (
                        <span>{ingestionStatusText(ingestionStatusForDatasource)}</span>
                    )}
                </Flex>
                <Box mt="xs">
                    <SegmentedControl
                        color={theme.colors.purple[7]}
                        styles={{
                            label: {
                                fontWeight: "bold",
                            },
                        }}
                        fullWidth
                        value={index}
                        onChange={async (value: typeof SF | typeof FSR) => {
                            setSelectedIndex(value);
                            navigate({ to: `/documents?tab=${value}` });
                            setFilterResetKey((prev) => prev + 1);
                        }}
                        data={getAvailableIndicesInUserRoles()
                            .filter((index) => Boolean(features[index.feature]))
                            .filter(
                                (item) =>
                                    item.shorthand !== "combined" && item.shorthand !== "emails",
                            )
                            .map((item) => ({
                                label: item.label,
                                value: item.shorthand,
                            }))}
                    />
                </Box>
            </Flex>
            <Box>
                <MetadataDocuments index={index} key={filterResetKey} />
            </Box>
        </Flex>
    );
};
