import type { IPublicClientApplication } from "@azure/msal-browser";
import * as Sentry from "@sentry/react";
import { v4 as uuidv4 } from "uuid";
import { BACKEND_URL } from "../hooks/useMetadata";
import { loginRequest } from "./authConfig";

interface FetchParams {
    url: string;
    method: "GET" | "POST" | "PUT" | "DELETE";
    payload?: Record<string, unknown>;
    apiKey?: string;
    instance: IPublicClientApplication;
}

// Simple health check cache
let healthCheckCache = {
    isHealthy: true,
    timestamp: 0,
};

const CACHE_DURATION = 30000; // 30 seconds cache for health check results (should be enough until the user logs in to Zscaler)

const createHeaders = (apiKey?: string) => {
    if (!apiKey) {
        throw new Error("No API key provided");
    }
    const transactionId = uuidv4();

    Sentry.withScope((scope) => {
        scope.setTag("transaction_id", transactionId);
    });
    return {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiKey}`,
        "X-Transaction-ID": transactionId,
    };
};

const handleResponse = async (
    response: Response,
    instance: IPublicClientApplication,
    options: RequestInit,
) => {
    if (!response.ok) {
        if (response.status === 401) {
            await instance.acquireTokenSilent({
                scopes: [],
            });
            await instance.loginRedirect(loginRequest());
            const newResponse = await fetch(response.url, options);
            return newResponse;
        }
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
};

export const postAPI = async ({ url, payload, apiKey, instance }: Omit<FetchParams, "method">) => {
    const isHealthy = await checkApiHealth(apiKey);
    if (!isHealthy) {
        throw new Error(
            "API is unreachable. Please check your network connection and ensure Zscaler is enabled.",
        );
    }

    const headers = createHeaders(apiKey);
    const options = {
        headers,
        method: "POST",
        body: JSON.stringify(payload),
    };

    const response = await fetch(url, options);
    return handleResponse(response, instance, options);
};

export const getAPI = async ({
    url,
    apiKey,
    instance,
}: Omit<FetchParams, "method" | "payload">) => {
    const isHealthy = await checkApiHealth(apiKey);
    if (!isHealthy) {
        throw new Error(
            "API is unreachable. Please check your network connection and ensure Zscaler is enabled.",
        );
    }

    const headers = createHeaders(apiKey);
    const options = {
        headers,
        method: "GET",
    };

    const response = await fetch(url, options);
    return handleResponse(response, instance, options);
};

export const deleteAPI = async ({
    url,
    apiKey,
    instance,
}: Omit<FetchParams, "method" | "payload">) => {
    const isHealthy = await checkApiHealth(apiKey);
    if (!isHealthy) {
        throw new Error(
            "API is unreachable. Please check your network connection and ensure Zscaler is enabled.",
        );
    }

    const headers = createHeaders(apiKey);
    const options = {
        headers,
        method: "DELETE",
    };

    const response = await fetch(url, options);
    return handleResponse(response, instance, options);
};

export const fetchWithAuth = async ({ url, method, payload, apiKey, instance }: FetchParams) => {
    const isHealthy = await checkApiHealth(apiKey);
    if (!isHealthy) {
        throw new Error(
            "API is unreachable. Please check your network connection and ensure Zscaler is enabled.",
        );
    }

    const headers = createHeaders(apiKey);
    const options = {
        headers,
        method,
        body: payload ? JSON.stringify(payload) : undefined,
    };

    const response = await fetch(url, options);
    return handleResponse(response, instance, options);
};

// Quick health check function
const checkApiHealth = async (apiKey?: string): Promise<boolean> => {
    // If we've detected a healthy state, trust it for the whole session
    if (healthCheckCache.isHealthy) {
        return true;
    }

    // For unhealthy state, check cache duration
    if (Date.now() - healthCheckCache.timestamp < CACHE_DURATION) {
        return false;
    }

    try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 5000);

        const response = await fetch(BACKEND_URL, {
            method: "HEAD",
            headers: {
                Authorization: `Bearer ${apiKey}`,
            },
            signal: controller.signal,
        });

        clearTimeout(timeoutId);
        const isHealthy = response.ok;

        // Update cache
        healthCheckCache = {
            isHealthy,
            timestamp: Date.now(),
        };

        return isHealthy;
    } catch (error) {
        // Update cache with unhealthy state
        healthCheckCache = {
            isHealthy: false,
            timestamp: Date.now(),
        };
        return false;
    }
};
