import { Box, Button, Group, Paper, Portal, Text, Title, keyframes } from "@mantine/core";
import { useEffect, useRef, useState } from "react";

export interface TourStep {
    target: string;
    title: string;
    description: string;
    position?: "top" | "right" | "bottom" | "left";
}

interface GuidedTourProps {
    steps: TourStep[];
    onComplete: () => void;
    isOpen: boolean;
    onClose: () => void;
}

const pulse = keyframes({
    "0%": { boxShadow: "0 0 0 0 rgba(100, 30, 140, 0.4)" },
    "70%": { boxShadow: "0 0 0 10px rgba(100, 30, 140, 0)" },
    "100%": { boxShadow: "0 0 0 0 rgba(100, 30, 140, 0)" },
});

// Add a fade transition for smooth appearance/disappearance
const fadeIn = keyframes({
    from: { opacity: 0 },
    to: { opacity: 1 },
});

export function GuidedTour({ steps, onComplete, isOpen, onClose }: GuidedTourProps) {
    const [currentStep, setCurrentStep] = useState(0);
    const [targetElement, setTargetElement] = useState<DOMRect | null>(null);
    const [previousTarget, setPreviousTarget] = useState<Element | null>(null);
    const [isTargetFound, setIsTargetFound] = useState(false);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    // Replace state with ref for retries
    const retriesRef = useRef(0);

    // Check if we're on the homepage
    const isHomePage = useRef(false);

    // Determine if we're on the homepage when the component mounts or updates
    useEffect(() => {
        // Check if current path is homepage
        const currentPath = window.location.pathname;
        isHomePage.current =
            currentPath === "/" || currentPath === "/index.html" || currentPath === "";

        if (!isHomePage.current && isOpen) {
            // If not on homepage but tour is open, complete the tour
            console.log("Tour attempted to open on non-homepage. Closing tour.");
            onComplete();
        }
    }, [isOpen, onComplete]);

    useEffect(() => {
        if (!isOpen) return;

        // Reset target found state when changing steps
        setIsTargetFound(false);
        setIsTooltipVisible(false);

        // Clean up any previously highlighted elements from the last step
        if (previousTarget) {
            previousTarget.removeAttribute("data-tour-highlight");
            (previousTarget as HTMLElement).style.pointerEvents = "";
        }

        // Reset retries on step change
        retriesRef.current = 0;

        const findAndHighlightTarget = () => {
            // Slight delay to ensure DOM is ready
            const timeoutId = setTimeout(() => {
                const targetSelector = steps[currentStep].target;
                console.log("Looking for tour target:", targetSelector);

                // Try to find the target element
                const target = document.querySelector(targetSelector);

                if (target) {
                    console.log("Found target element:", target);
                    const rect = target.getBoundingClientRect();
                    setTargetElement(rect);
                    setPreviousTarget(target);

                    // Add highlight effect to the target element and increase z-index
                    target.setAttribute("data-tour-highlight", "true");

                    // Make sure the element is visible - scroll to it if needed
                    const viewportHeight = window.innerHeight;
                    if (rect.top < 0 || rect.bottom > viewportHeight) {
                        target.scrollIntoView({ behavior: "smooth", block: "center" });
                    }

                    // Make sure the target element is clickable
                    (target as HTMLElement).style.pointerEvents = "auto";

                    // First mark the target as found (keeps overlay visible)
                    setIsTargetFound(true);

                    // Then show the tooltip with a slight delay for smooth transition
                    setTimeout(() => {
                        setIsTooltipVisible(true);
                    }, 50);
                } else if (retriesRef.current < 5) {
                    // If target not found, try a more generic selector for Mantine components
                    console.log(
                        "Target not found with selector:",
                        targetSelector,
                        "Retrying...",
                        `(${retriesRef.current + 1}/5)`,
                    );

                    // For datasource selector specifically, try to find it by other means if needed
                    if (targetSelector.includes("datasource-selector") && retriesRef.current >= 2) {
                        console.log("Trying alternative selector for datasource-selector");
                        // Try more generic selector that might catch Mantine's structure
                        const alternativeTarget = document.querySelector(
                            '.mantine-Select-root[data-tour="datasource-selector"]',
                        );
                        if (alternativeTarget) {
                            console.log(
                                "Found alternative target for datasource-selector",
                                alternativeTarget,
                            );
                            const rect = alternativeTarget.getBoundingClientRect();
                            setTargetElement(rect);
                            setPreviousTarget(alternativeTarget);
                            alternativeTarget.setAttribute("data-tour-highlight", "true");

                            const viewportHeight = window.innerHeight;
                            if (rect.top < 0 || rect.bottom > viewportHeight) {
                                alternativeTarget.scrollIntoView({
                                    behavior: "smooth",
                                    block: "center",
                                });
                            }

                            (alternativeTarget as HTMLElement).style.pointerEvents = "auto";

                            // First mark the target as found (keeps overlay visible)
                            setIsTargetFound(true);

                            // Then show the tooltip with a slight delay for smooth transition
                            setTimeout(() => {
                                setIsTooltipVisible(true);
                            }, 50);

                            return;
                        }
                    }

                    // Increase retries and try again with a longer delay
                    retriesRef.current += 1;
                    setTimeout(findAndHighlightTarget, 500 * retriesRef.current);
                } else {
                    // Max retries reached, skip this step or close the tour
                    console.log("Max retries reached for target:", targetSelector);

                    // Skip to next step if there is one, otherwise close the tour
                    if (currentStep < steps.length - 1) {
                        setCurrentStep(currentStep + 1);
                    } else {
                        onClose();
                    }
                }
            }, 100);

            return () => clearTimeout(timeoutId);
        };

        const cleanup = findAndHighlightTarget();

        return () => {
            cleanup();
            // Clean up highlight effect on all elements
            const highlightedElements = document.querySelectorAll('[data-tour-highlight="true"]');
            // Convert NodeList to Array to make it iterable with for...of
            const elementsArray = Array.from(highlightedElements);
            for (const el of elementsArray) {
                el.removeAttribute("data-tour-highlight");
                (el as HTMLElement).style.pointerEvents = "";
            }
        };
    }, [currentStep, isOpen, steps, previousTarget, onClose]);

    // When the tour closes, ensure all highlighted elements are cleaned up
    useEffect(() => {
        if (!isOpen) {
            const highlightedElements = document.querySelectorAll('[data-tour-highlight="true"]');
            // Convert NodeList to Array to make it iterable with for...of
            const elementsArray = Array.from(highlightedElements);
            for (const el of elementsArray) {
                el.removeAttribute("data-tour-highlight");
                (el as HTMLElement).style.pointerEvents = "";
            }
        }
    }, [isOpen]);

    const handleNext = () => {
        // Hide tooltip immediately during transition, but keep overlay
        setIsTooltipVisible(false);

        if (currentStep < steps.length - 1) {
            // Scroll to top of page before advancing to elements that might be at the top
            if (
                steps[currentStep + 1].target === "#chat-input" ||
                steps[currentStep + 1].target === 'button[data-testid="send-button"]'
            ) {
                // These elements might be at the bottom of the page, so scroll there
                window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
            }

            // Small delay to allow scrolling to complete
            setTimeout(() => {
                setCurrentStep((prev) => prev + 1);
            }, 300);
        } else {
            // Last step, complete the tour
            onComplete();
        }
    };

    const handleSkip = () => {
        // Complete the tour
        onComplete();
    };

    // Don't render anything if tour is closed or not on homepage
    if (!isOpen || !isHomePage.current) return null;

    const step = steps[currentStep];

    // Calculate tooltip position based on the target element
    const getTooltipPosition = () => {
        if (!targetElement) return { top: 0, left: 0 };

        const position = step.position || "bottom";
        const padding = 10;
        const tooltipWidth = 300;
        const tooltipHeight = 200; // Approximate height of tooltip

        // Get viewport dimensions
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        let top = 0;
        let left = 0;

        // Calculate initial position based on specified position
        switch (position) {
            case "top":
                top = targetElement.top - tooltipHeight - padding;
                left = targetElement.left + targetElement.width / 2 - tooltipWidth / 2;
                break;
            case "right":
                top = targetElement.top + targetElement.height / 2 - tooltipHeight / 2;
                left = targetElement.right + padding;
                break;
            case "left":
                top = targetElement.top + targetElement.height / 2 - tooltipHeight / 2;
                left = targetElement.left - tooltipWidth - padding;
                break;
            default: // bottom
                top = targetElement.bottom + padding;
                left = targetElement.left + targetElement.width / 2 - tooltipWidth / 2;
        }

        // Adjust if tooltip would go off-screen
        if (left < padding) {
            left = padding;
        } else if (left + tooltipWidth > viewportWidth - padding) {
            left = viewportWidth - tooltipWidth - padding;
        }

        if (top < padding) {
            // If tooltip would go off the top, place it below the element instead
            top = targetElement.bottom + padding;
        } else if (top + tooltipHeight > viewportHeight - padding) {
            // If tooltip would go off the bottom, place it above the element
            top = targetElement.top - tooltipHeight - padding;

            // If still off-screen, position at the bottom with some padding
            if (top < padding) {
                top = viewportHeight - tooltipHeight - padding * 2;
            }
        }

        // Ensure the element is visible in the viewport
        const scrollOptions = {
            behavior: "smooth" as ScrollBehavior,
            block: "center" as ScrollLogicalPosition,
        };

        // If the element is not fully visible, scroll to it
        if (targetElement.top < 0 || targetElement.bottom > viewportHeight) {
            setTimeout(() => {
                const el = document.querySelector(steps[currentStep].target);
                if (el) el.scrollIntoView(scrollOptions);
            }, 300);
        }

        return { top, left };
    };

    const tooltipPosition = getTooltipPosition();

    return (
        <Portal>
            {/* Overlay - always visible when target is found */}
            {isTargetFound && targetElement && (
                <>
                    {/* Top overlay */}
                    <Box
                        sx={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            right: 0,
                            height: Math.max(0, targetElement.top),
                            background: "rgba(0, 0, 0, 0.5)",
                            zIndex: 999,
                            transition: "all 0.3s ease",
                            animation: `${fadeIn} 0.3s ease`,
                        }}
                        onClick={handleSkip}
                    />
                    {/* Left overlay */}
                    <Box
                        sx={{
                            position: "fixed",
                            top: targetElement.top,
                            left: 0,
                            width: targetElement.left,
                            height: targetElement.height,
                            background: "rgba(0, 0, 0, 0.5)",
                            zIndex: 999,
                            transition: "all 0.3s ease",
                            animation: `${fadeIn} 0.3s ease`,
                        }}
                        onClick={handleSkip}
                    />
                    {/* Right overlay */}
                    <Box
                        sx={{
                            position: "fixed",
                            top: targetElement.top,
                            left: targetElement.left + targetElement.width,
                            right: 0,
                            height: targetElement.height,
                            background: "rgba(0, 0, 0, 0.5)",
                            zIndex: 999,
                            transition: "all 0.3s ease",
                            animation: `${fadeIn} 0.3s ease`,
                        }}
                        onClick={handleSkip}
                    />
                    {/* Bottom overlay */}
                    <Box
                        sx={{
                            position: "fixed",
                            top: targetElement.top + targetElement.height,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: "rgba(0, 0, 0, 0.5)",
                            zIndex: 999,
                            transition: "all 0.3s ease",
                            animation: `${fadeIn} 0.3s ease`,
                        }}
                        onClick={handleSkip}
                    />

                    {/* Element highlight outline */}
                    <Box
                        sx={{
                            position: "fixed",
                            top: targetElement.top - 4,
                            left: targetElement.left - 4,
                            width: targetElement.width + 8,
                            height: targetElement.height + 8,
                            border: "2px solid #641E8C",
                            borderRadius: "4px",
                            zIndex: 999,
                            animation: `${pulse} 2s infinite`,
                            transition: "all 0.3s ease",
                            backgroundColor: "rgba(100, 30, 140, 0.05)",
                        }}
                    />
                </>
            )}

            {/* Tooltip - only visible when isTooltipVisible is true */}
            {isTargetFound && targetElement && isTooltipVisible && (
                <Paper
                    shadow="md"
                    p="md"
                    withBorder
                    sx={{
                        position: "fixed",
                        top: tooltipPosition.top,
                        left: tooltipPosition.left,
                        width: 320,
                        zIndex: 1000,
                        backgroundColor: "white",
                        transition: "all 0.3s ease",
                        animation: `${fadeIn} 0.3s ease`,
                    }}
                >
                    <Title order={4} c="#641E8C">
                        {step.title}
                    </Title>
                    <Text size="sm" mt="xs">
                        {step.description}
                    </Text>
                    <Group position="apart" mt="lg">
                        <Button
                            variant="subtle"
                            onClick={handleSkip}
                            sx={{
                                color: "#641E8C",
                                "&:hover": { backgroundColor: "rgba(100, 30, 140, 0.1)" },
                            }}
                        >
                            Skip Tour
                        </Button>
                        <Button
                            onClick={handleNext}
                            sx={{
                                backgroundColor: "#641E8C",
                                "&:hover": { backgroundColor: "#4F1971" },
                            }}
                        >
                            {currentStep < steps.length - 1 ? "Next" : "Finish"}
                        </Button>
                    </Group>
                    <Text size="xs" color="dimmed" align="center" mt={8}>
                        Step {currentStep + 1} of {steps.length}
                    </Text>
                </Paper>
            )}
        </Portal>
    );
}
