import { useAccount, useMsal } from "@azure/msal-react";
import {
    Box,
    Button,
    Checkbox,
    Flex,
    Group,
    Modal,
    Paper,
    ScrollArea,
    SimpleGrid,
    Stack,
    Text,
    TextInput,
    Textarea,
    Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import {
    type MRT_ColumnDef,
    type MRT_ColumnFilterFnsState,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
    MantineReactTable,
    useMantineReactTable,
} from "mantine-react-table";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { type InfiniteData, useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import { getAPI, postAPI } from "src/utils/fetch";
import { useAdmin } from "src/utils/useAdmin";

export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;

export type User = {
    id: string;
    org_code: string;
    email: string;
    roles: string[];
};

type UserApiResponse = {
    data: Array<User>;
};

interface QueryParams {
    columnFilterFns: MRT_ColumnFilterFnsState;
    columnFilters: MRT_ColumnFiltersState;
    globalFilter: string;
    sorting: MRT_SortingState;
    pagination: MRT_PaginationState;
}

interface MutationParams {
    data: Partial<User>;
}

interface Role {
    id: string;
    description: string;
    permissions: string[];
}

interface RolesResponse {
    data: Role[];
}

// Create a custom hook for user operations
const useUserOperations = () => {
    const isAdmin = useAdmin();
    const { instance } = useMsal();
    const account = useAccount();
    const apiKey = account?.idToken;
    const queryClient = useQueryClient();

    // GET operation
    const getUsers = ({
        columnFilterFns,
        columnFilters,
        globalFilter,
        sorting,
        pagination,
    }: QueryParams) => {
        if (!isAdmin) {
            return {
                data: undefined,
                isLoading: false,
                error: new Error("Unauthorized"),
                refetch: async () => {},
            };
        }

        const fetchURL = new URL("/v1/auth/users", BACKEND_URL);
        fetchURL.searchParams.set("start", `${pagination.pageIndex * pagination.pageSize}`);
        fetchURL.searchParams.set("size", `${pagination.pageSize}`);
        fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
        fetchURL.searchParams.set("filterModes", JSON.stringify(columnFilterFns ?? {}));
        fetchURL.searchParams.set("globalFilter", globalFilter ?? "");
        fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []));

        return useInfiniteQuery<UserApiResponse>({
            queryKey: ["users", pagination],
            queryFn: async () => {
                const response = await getAPI({ url: fetchURL.href, apiKey, instance });
                const data = await response.json();
                return data;
            },
            keepPreviousData: true,
            enabled: isAdmin,
        });
    };

    // POST operation
    const updateUser = async ({ data }: MutationParams) => {
        const response = await postAPI({
            url: `${BACKEND_URL}/v1/auth/users`,
            apiKey,
            instance,
            payload: data,
        });

        if (!response.ok) {
            throw new Error("Failed to update user");
        }

        // Parse the response to get the updated user
        const updatedUser = (await response.json()) as User;
        // Update the cache with the new user data
        queryClient.setQueryData<InfiniteData<UserApiResponse> | undefined>(
            ["users"],
            (oldData) => {
                // If we have the URL-specific query key data, update that too
                const queryKeys = queryClient.getQueryCache().findAll(["users"]);
                for (const query of queryKeys) {
                    const queryKey = query.queryKey;
                    if (
                        Array.isArray(queryKey) &&
                        typeof queryKey[1] === "object" &&
                        queryKey[1] !== null &&
                        Object.keys(queryKey[1]).length > 1
                    ) {
                        // This is a URL-specific query key
                        queryClient.setQueryData<InfiniteData<UserApiResponse>>(
                            queryKey,
                            (urlSpecificData) => {
                                if (!urlSpecificData) return urlSpecificData;

                                // Just override the user value with our updated user
                                return {
                                    ...urlSpecificData,
                                    pages: urlSpecificData.pages.map((page) =>
                                        // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                                        (page as any).map((user) =>
                                            user.id === updatedUser.id ? updatedUser : user,
                                        ),
                                    ),
                                };
                            },
                        );
                    }
                }

                return oldData;
            },
        );

        // Still invalidate the query to trigger a background refetch
        // queryClient.invalidateQueries(["users"]);

        return updatedUser;
    };

    return {
        getUsers,
        updateUser,
    };
};

const useRoles = () => {
    const { instance } = useMsal();
    const account = useAccount();
    const apiKey = account?.idToken;

    return useQuery<Role[], Error>({
        queryKey: ["roles"],
        queryFn: async () => {
            const response = await getAPI({
                url: `${BACKEND_URL}/v1/auth/roles`,
                apiKey,
                instance,
            });
            const jsonData = await response.json();
            return jsonData;
        },
    });
};

const ManageUsers = () => {
    const { instance } = useMsal();
    const account = useAccount();
    const { data: roles = [], isLoading: isLoadingRoles, refetch: fetchRoles } = useRoles();
    const [roleOptions, setRoleOptions] = useState<{ value: string; label: string }[]>([]);

    const columns = useMemo<MRT_ColumnDef<User>[]>(
        () => [
            {
                accessorKey: "id" as const,
                header: "ID",
                accessorFn: (row) => (row ? row.id?.toString() : "") ?? "",
            },
            {
                accessorKey: "org_code" as const,
                header: "Org Code",
                accessorFn: (row) => (row ? row.org_code?.toString() : "") ?? "",
            },
            {
                accessorKey: "email" as const,
                header: "Email",
                accessorFn: (row) => (row ? row.email?.toString() : "") ?? "",
            },
            {
                accessorKey: "roles" as const,
                header: "Roles",
                accessorFn: (row) => (row ? row.roles?.join(", ") : "") ?? "",
            },
        ],
        [],
    );

    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    const [columnFilterFns, setColumnFilterFns] = useState<MRT_ColumnFilterFnsState>(
        Object.fromEntries(columns.map(({ accessorKey }) => [accessorKey, "contains"])),
    ); //default to "contains" for all columns
    const [globalFilter, setGlobalFilter] = useState("");
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const { getUsers, updateUser } = useUserOperations();

    // Use the GET operation
    const { data, isLoading, refetch } = getUsers({
        columnFilterFns,
        columnFilters,
        globalFilter,
        pagination,
        sorting,
        // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
    }) as any;

    // Safely extract users from the API response with proper type handling
    const fetchedUsers = useMemo(() => {
        if (!data || !data.pages) return [];

        return data.pages.flatMap((page) => {
            if (!page) return [];

            // Handle the response structure correctly
            if (page && Array.isArray(page)) {
                return page;
            }

            // Fallback if the structure is different
            return [];
        });
    }, [data]);

    // Extract unique roles from users
    const userRoles = useMemo(() => {
        return new Set(fetchedUsers.flatMap((user) => user.roles || []));
    }, [fetchedUsers]);

    const allRoles = useMemo(
        () => [...new Set([...roles.map((r) => r.id), ...userRoles])],
        [roles, userRoles],
    );

    useEffect(() => {
        if (allRoles.length > 0) {
            const roleOptions = allRoles.map((roleId) => {
                const role = roles.find((r) => r.id === roleId);
                return {
                    value: roleId,
                    label: role?.description ? `${roleId} - ${role.description}` : roleId,
                };
            });

            // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
            setRoleOptions(roleOptions as any);
        }
    }, [roles, allRoles]);

    const tableContainerRef = useRef<HTMLDivElement>(null);
    const [isRefetching, setIsRefetching] = useState(false);

    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement?.scrollHeight && containerRefElement?.scrollTop) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                // When scrolled to within 200px of the bottom, load more
                if (scrollHeight - scrollTop - clientHeight < 200 && !isLoading) {
                    refetch();
                }
            }
        },
        [isLoading, refetch],
    );

    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [modalOpened, setModalOpened] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const form = useForm({
        initialValues: {
            id: "",
            email: "",
            org_code: "",
            roles: [] as string[],
        },
        validate: {
            roles: (value) => (value.length === 0 ? "At least one role is required" : null),
        },
    });

    const handleOpenModal = (user: User) => {
        if (isLoadingRoles) {
            notifications.show({
                title: "Loading",
                message: "Please wait while roles are being loaded...",
                loading: true,
            });
            return;
        }

        if (roleOptions.length === 0) {
            fetchRoles();
        }

        // Find the most up-to-date user data
        const currentUser = fetchedUsers.find((u) => u.id === user.id) || user;

        setSelectedUser(currentUser);
        form.setValues({
            id: currentUser.id,
            email: currentUser.email,
            roles: currentUser.roles,
        });
        setModalOpened(true);
    };

    const handleSubmit = async (values: typeof form.values) => {
        setIsSubmitting(true);
        try {
            if (!selectedUser) {
                throw new Error("No user selected");
            }

            const updatedUser = await updateUser({
                data: {
                    id: selectedUser.id,
                    email: selectedUser.email,
                    org_code: selectedUser.org_code,
                    roles: [...new Set([...values.roles])],
                },
            });

            // Update the local state with the returned user data
            setSelectedUser(updatedUser);

            // Update the fetchedUsers array with the updated user data
            // This ensures the table shows the updated data immediately
            const userIndex = fetchedUsers.findIndex((u) => u.id === updatedUser.id);
            if (userIndex !== -1) {
                const updatedUsers = [...fetchedUsers];
                updatedUsers[userIndex] = updatedUser;
                // We can't directly update fetchedUsers since it's from useMemo
                // But the cache update in updateUser function will trigger a re-render
            }

            notifications.show({
                title: "Success",
                message: "User roles updated successfully",
                color: "green",
            });

            setModalOpened(false);
        } catch (error) {
            notifications.show({
                title: "Error",
                message: error instanceof Error ? error.message : "Failed to update user roles",
                color: "red",
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const [createRoleModalOpened, setCreateRoleModalOpened] = useState(false);
    const [isCreatingRole, setIsCreatingRole] = useState(false);

    const createRoleForm = useForm({
        initialValues: {
            id: "",
            description: "",
            permissions: [] as string[], // If you need to handle permissions
        },
        validate: {
            id: (value) => (!value ? "Role ID is required" : null),
            description: (value) => (!value ? "Description is required" : null),
        },
    });

    const handleCreateRole = async (values: typeof createRoleForm.values) => {
        setIsCreatingRole(true);
        try {
            const response = await postAPI({
                url: `${BACKEND_URL}/v1/auth/roles`,
                apiKey: account?.idToken,
                instance,
                payload: values,
            });

            if (!response.ok) {
                throw new Error("Failed to create role");
            }

            notifications.show({
                title: "Success",
                message: "Role created successfully",
                color: "green",
            });
            setRoleOptions([]);
            setCreateRoleModalOpened(false);
            createRoleForm.reset();
        } catch (error) {
            notifications.show({
                title: "Error",
                message: error instanceof Error ? error.message : "Failed to create role",
                color: "red",
            });
        } finally {
            setIsCreatingRole(false);
        }
    };

    const [roleSearchTerm, setRoleSearchTerm] = useState("");

    const filteredRoles = useMemo(() => {
        if (!roleSearchTerm) return roles;
        return roles.filter(
            (role) =>
                role.id.toLowerCase().includes(roleSearchTerm.toLowerCase()) ||
                role.description?.toLowerCase().includes(roleSearchTerm.toLowerCase()),
        );
    }, [roles, roleSearchTerm]);

    // Group roles by category for better organization
    const rolesByCategory = useMemo(() => {
        const categories: Record<string, Role[]> = {};

        // Extract category from role ID (e.g., "admin_role" -> "admin")
        for (const role of roles) {
            const category = role.id.split("_")[0] || "other";
            if (!categories[category]) {
                categories[category] = [];
            }
            categories[category].push(role);
        }

        return categories;
    }, [roles]);

    // Count of selected roles by category
    const selectedRolesByCategory = useMemo(() => {
        const counts: Record<string, { total: number; selected: number }> = {};

        for (const [category, categoryRoles] of Object.entries(rolesByCategory)) {
            counts[category] = {
                total: categoryRoles.length,
                selected: categoryRoles.filter((role) => form.values.roles.includes(role.id))
                    .length,
            };
        }

        return counts;
    }, [rolesByCategory, form.values.roles]);

    // Filter roles by search term within categories
    const filteredRolesByCategory = useMemo(() => {
        const filtered: Record<string, Role[]> = {};

        for (const [category, categoryRoles] of Object.entries(rolesByCategory)) {
            const filteredCategoryRoles = categoryRoles.filter(
                (role) =>
                    role.id.toLowerCase().includes(roleSearchTerm.toLowerCase()) ||
                    role.description?.toLowerCase().includes(roleSearchTerm.toLowerCase()),
            );

            if (filteredCategoryRoles.length > 0) {
                filtered[category] = filteredCategoryRoles;
            }
        }

        return filtered;
    }, [rolesByCategory, roleSearchTerm]);

    // The table component with proper TypeScript typing
    const table = useMantineReactTable<User>({
        columns,
        data: fetchedUsers ?? [],
        enableColumnOrdering: true,
        enablePinning: true,
        enableBottomToolbar: false,
        renderToolbarAlertBannerContent: () => <></>,
        enableColumnResizing: true,
        enableFilters: true,
        enablePagination: false,
        enableRowNumbers: true,
        enableClickToCopy: false,
        getRowId: (row: User) => row.id,
        mantineToolbarAlertBannerProps: {
            style: {
                display: "none",
                background: "#f0f0f0",
                fontWeight: "bold",
            },
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        enableSelectAll: false,
        selectAllMode: "page",
        enableGrouping: true,
        enableDensityToggle: false,
        mantineTableContainerProps: {
            ref: tableContainerRef,
            sx: { maxHeight: "80vh" },
        },
        mantineTableBodyRowProps: ({ row }) => ({
            onClick: () => handleOpenModal(row.original),
            sx: { cursor: "pointer" },
        }),
        state: {
            isLoading: isLoading || isLoadingRoles,
            showProgressBars: isRefetching,
            columnFilters,
            globalFilter,
            sorting,
        },
        initialState: {
            showGlobalFilter: true,
            density: "xs",
            showColumnFilters: true,
        },
    });

    return (
        <>
            <Flex p="xl" direction="column" gap="md">
                <Flex gap="md" align="center">
                    <Title lh={2} c="purple.8" order={2} transform="uppercase">
                        Manage Users
                    </Title>
                    <Button onClick={() => setCreateRoleModalOpened(true)} variant="filled">
                        Create New Role
                    </Button>
                </Flex>
            </Flex>

            <MantineReactTable table={table} />

            <Modal
                opened={createRoleModalOpened}
                onClose={() => {
                    setCreateRoleModalOpened(false);
                    createRoleForm.reset();
                }}
                title="Create New Role"
                size="md"
            >
                <Box component="form" onSubmit={createRoleForm.onSubmit(handleCreateRole)}>
                    <Stack spacing="md">
                        <TextInput
                            required
                            label="Role ID"
                            placeholder="e.g., admin_role"
                            {...createRoleForm.getInputProps("id")}
                        />

                        <Textarea
                            required
                            label="Description"
                            placeholder="Describe the purpose of this role"
                            minRows={3}
                            {...createRoleForm.getInputProps("description")}
                        />

                        <Button type="submit" loading={isCreatingRole} fullWidth>
                            Create Role
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            <Modal
                opened={modalOpened}
                onClose={() => setModalOpened(false)}
                title={`Edit User Roles: ${selectedUser?.email || ""}`}
                size="lg"
                styles={{
                    body: {
                        // Set minimum height to prevent layout shifts
                        minHeight: 500,
                    },
                }}
            >
                <form onSubmit={form.onSubmit(handleSubmit)}>
                    <Stack spacing="md">
                        <TextInput label="Email" value={selectedUser?.email || ""} disabled />
                        <SimpleGrid cols={2}>
                            <TextInput label="User ID" value={selectedUser?.id || ""} disabled />
                            <TextInput
                                label="Org Code"
                                value={selectedUser?.org_code || ""}
                                disabled
                            />
                        </SimpleGrid>

                        <Box>
                            <Text weight={500} mb="xs">
                                Roles
                            </Text>
                            <TextInput
                                placeholder="Search roles..."
                                mb="sm"
                                onChange={(event) => setRoleSearchTerm(event.currentTarget.value)}
                                icon={<span>🔍</span>}
                            />
                            <ScrollArea type="auto" style={{ height: 300 }}>
                                <Stack spacing="xs">
                                    {Object.entries(
                                        roleSearchTerm ? filteredRolesByCategory : rolesByCategory,
                                    ).map(([category, categoryRoles]) => (
                                        <Box key={category}>
                                            <Text weight={500} mb="xs">
                                                {category.toUpperCase()}
                                            </Text>
                                            {categoryRoles.map((role) => (
                                                <Paper
                                                    key={role.id}
                                                    p="xs"
                                                    withBorder
                                                    sx={(theme) => ({
                                                        backgroundColor: form.values.roles.includes(
                                                            role.id,
                                                        )
                                                            ? theme.colorScheme === "dark"
                                                                ? theme.colors.blue[9]
                                                                : theme.colors.blue[0]
                                                            : undefined,
                                                        transition: "background-color 200ms ease",
                                                        "&:hover": {
                                                            backgroundColor:
                                                                form.values.roles.includes(role.id)
                                                                    ? theme.colorScheme === "dark"
                                                                        ? theme.colors.blue[8]
                                                                        : theme.colors.blue[1]
                                                                    : theme.colorScheme === "dark"
                                                                      ? theme.colors.dark[5]
                                                                      : theme.colors.gray[0],
                                                        },
                                                        cursor: "pointer",
                                                    })}
                                                    onClick={() => {
                                                        const isSelected =
                                                            form.values.roles.includes(role.id);
                                                        form.setFieldValue(
                                                            "roles",
                                                            isSelected
                                                                ? form.values.roles.filter(
                                                                      (r) => r !== role.id,
                                                                  )
                                                                : [...form.values.roles, role.id],
                                                        );
                                                    }}
                                                >
                                                    <Group position="apart" noWrap>
                                                        <Box
                                                            sx={{
                                                                flexGrow: 1,
                                                                cursor: "pointer",
                                                                userSelect: "none",
                                                            }}
                                                        >
                                                            <Checkbox
                                                                label={
                                                                    <Text
                                                                        weight={500}
                                                                        size="sm"
                                                                        sx={{ cursor: "pointer" }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                        }}
                                                                    >
                                                                        {role.id}
                                                                    </Text>
                                                                }
                                                                checked={form.values.roles.includes(
                                                                    role.id,
                                                                )}
                                                                onChange={(e) => {
                                                                    e.stopPropagation();
                                                                    const isSelected =
                                                                        e.currentTarget.checked;
                                                                    form.setFieldValue(
                                                                        "roles",
                                                                        isSelected
                                                                            ? [
                                                                                  ...form.values
                                                                                      .roles,
                                                                                  role.id,
                                                                              ]
                                                                            : form.values.roles.filter(
                                                                                  (r) =>
                                                                                      r !== role.id,
                                                                              ),
                                                                    );
                                                                }}
                                                                styles={{
                                                                    root: {
                                                                        width: "100%",
                                                                        cursor: "pointer",
                                                                    },
                                                                    body: {
                                                                        width: "100%",
                                                                        cursor: "pointer",
                                                                    },
                                                                    label: {
                                                                        width: "100%",
                                                                        cursor: "pointer",
                                                                    },
                                                                    input: { cursor: "pointer" },
                                                                }}
                                                            />
                                                            {role.description && (
                                                                <Text
                                                                    size="xs"
                                                                    color="dimmed"
                                                                    ml={30}
                                                                    lineClamp={2}
                                                                    sx={{ cursor: "pointer" }}
                                                                >
                                                                    {role.description}
                                                                </Text>
                                                            )}
                                                        </Box>
                                                    </Group>
                                                </Paper>
                                            ))}
                                        </Box>
                                    ))}
                                </Stack>
                            </ScrollArea>
                            <Group position="apart" mt="xs">
                                <Text size="sm" color="dimmed">
                                    {form.values.roles.length}{" "}
                                    {form.values.roles.length === 1 ? "role" : "roles"} selected
                                </Text>
                                <Group spacing="xs">
                                    <Button
                                        variant="default"
                                        size="xs"
                                        onClick={() => form.setFieldValue("roles", [])}
                                        disabled={form.values.roles.length === 0}
                                    >
                                        Clear All
                                    </Button>
                                    <Button
                                        variant="outline"
                                        size="xs"
                                        onClick={() =>
                                            form.setFieldValue(
                                                "roles",
                                                roles.map((r) => r.id),
                                            )
                                        }
                                        disabled={form.values.roles.length === roles.length}
                                    >
                                        Select All
                                    </Button>
                                </Group>
                            </Group>
                        </Box>

                        <Group position="right" mt="md">
                            <Button
                                type="button"
                                variant="outline"
                                onClick={() => setModalOpened(false)}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" loading={isSubmitting}>
                                Save Changes
                            </Button>
                        </Group>
                    </Stack>
                </form>
            </Modal>
        </>
    );
};

export default ManageUsers;
