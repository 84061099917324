import { useAccount, useMsal } from "@azure/msal-react";
import { Button, Flex, List, Modal, Stack, Text, TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { useLiveQuery } from "dexie-react-hooks";
import { type ReactElement, cloneElement, memo, useEffect, useState } from "react";
import { BACKEND_URL } from "src/hooks/useMetadata";
import { TRAINING_URL } from "src/utils/constants";
import { postAPI } from "src/utils/fetch";
import { db } from "../db";

export const SettingsModal = memo(function SettingsModal({
    children,
}: {
    children: ReactElement;
}) {
    const [opened, { open, close }] = useDisclosure(false);
    const [submitting, setSubmitting] = useState(false);

    const [value, setValue] = useState("");

    const settings = useLiveQuery(async () => {
        return db.settings.where({ id: "general" }).first();
    });

    const { instance } = useMsal();
    const account = useAccount();
    const apiKey = account?.idToken;

    useEffect(() => {
        if (settings?.openAiApiKey) {
            setValue(settings.openAiApiKey);
        }
    }, [settings]);

    return (
        <>
            {cloneElement(children, { onClick: open })}
            <Modal opened={opened} onClose={close} title="Request Access" size="lg">
                <Stack>
                    <form
                        onSubmit={async (event) => {
                            try {
                                setSubmitting(true);
                                event.preventDefault();
                                const PATH = "v1/user/invite";
                                const response = await postAPI({
                                    url: `${BACKEND_URL}/${PATH}`,
                                    payload: { reason: value },
                                    apiKey,
                                    instance,
                                });
                                if (response.status >= 300) {
                                    throw new Error(
                                        "Failed to request access, Please try again later or contact our product owner",
                                    );
                                }
                                notifications.show({
                                    title: "Saved",
                                    message:
                                        "Your request has been sent successfully, we will get back to you soon.",
                                });
                                // open the redirect link 2 seconds after submitting request
                                setTimeout(() => {
                                    const newWindow = window.open(
                                        TRAINING_URL,
                                        "_blank",
                                        "noopener,noreferrer",
                                    );

                                    if (newWindow) newWindow.opener = null;
                                }, 2000);
                            } catch (error: unknown) {
                                const err = error as {
                                    message?: string;
                                    response?: { data?: { error?: { messag?: string } } };
                                };
                                notifications.show({
                                    title: "Error",
                                    color: "red",
                                    message:
                                        err.message ??
                                        err.response?.data?.error?.messag ??
                                        "No internet connection.",
                                });
                            } finally {
                                setSubmitting(false);
                            }
                        }}
                    >
                        <Flex gap="xs" align="end">
                            <TextInput
                                label="Reason to use the tool"
                                placeholder="Example: I need it to help me with my daily customer support work..."
                                sx={{ flex: 1 }}
                                value={value}
                                onChange={(event) => setValue(event.currentTarget.value)}
                                formNoValidate
                            />
                            <Button type="submit" loading={submitting}>
                                Save
                            </Button>
                        </Flex>
                    </form>
                    <List withPadding>
                        <List.Item>
                            <Text size="sm" color="dimmed">
                                We'd review your request as soon as possible and get back to you,
                                thanks for requesting access.
                            </Text>
                        </List.Item>
                    </List>
                    {/* <Alert color="orange" title="Warning">
            The displayed cost was not updated yet to reflect the costs for each
            model. Right now it will always show the cost for GPT-3.5.
          </Alert> */}
                </Stack>
            </Modal>
        </>
    );
});
