import { Box, keyframes, useMantineTheme } from "@mantine/core";

const bounce = keyframes({
    "0%": { transform: "translateY(0)" },
    "50%": { transform: "translateY(-5px)" },
    "100%": { transform: "translateY(0)" },
});

export const TypingIndicator = () => {
    const theme = useMantineTheme();

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                gap: 4,
                height: 24,
                padding: "8px 0px",
                margin: "4px 0",
            }}
        >
            {[0, 1, 2].map((i) => (
                <Box
                    key={i}
                    sx={{
                        width: 5,
                        height: 5,
                        backgroundColor: theme.black,
                        borderRadius: "50%",
                        animation: `${bounce} 0.8s ease infinite`,
                        animationDelay: `${i * 0.15}s`,
                    }}
                />
            ))}
        </Box>
    );
};
