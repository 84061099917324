import { createFileRoute } from "@tanstack/react-router";
import { DocumentsRoute } from "../../containers/DocumentsRoute";

export const Route = createFileRoute("/_layout-nosidebar/salesforce")({
    component: Salesforce,
});

export default function Salesforce() {
    return <DocumentsRoute index="sf" setSelectedIndex={() => {}} />;
}
