import { Box, Flex, useMantineTheme } from "@mantine/core";
import { useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import ManageRoles from "src/components/manageRoles";

export const ManageRolesRoute = () => {
    const navigate = useNavigate();
    const theme = useMantineTheme();

    useEffect(() => {
        if (window.location.hash === "#/manageRoles") navigate({ to: "/manageRoles" });
    }, [navigate]);

    return (
        <Flex p="xl" direction="column" gap="md">
            <Box>
                <ManageRoles />
            </Box>
        </Flex>
    );
};
