import { useAccount, useMsal } from "@azure/msal-react";
import {
    ActionIcon,
    Box,
    Button,
    Flex,
    Menu,
    Modal,
    MultiSelect,
    Stack,
    Text,
    TextInput,
    Textarea,
    Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconDots, IconEdit, IconTrash } from "@tabler/icons-react";
import {
    type MRT_ColumnDef,
    type MRT_ColumnFilterFnsState,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
    MantineReactTable,
    useMantineReactTable,
} from "mantine-react-table";
import { useMemo, useRef, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { deleteAPI, getAPI, postAPI } from "src/utils/fetch";
import { useAdmin } from "src/utils/useAdmin";

export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;

export type Role = {
    id: string;
    description: string;
    datasources: Array<Datasources>;
};

export type Datasource = {
    id: string;
    product_lines?: string[];
};

export type Datasources = {
    id: string;
    description: string;
    datasources: Datasource[];
};

type RolesApiResponse = {
    data: Array<Role>;
};

interface QueryParams {
    columnFilterFns: MRT_ColumnFilterFnsState;
    columnFilters: MRT_ColumnFiltersState;
    globalFilter: string;
    sorting: MRT_SortingState;
    pagination: MRT_PaginationState;
}

interface MutationParams {
    data: Partial<Role>;
}

interface RolesResponse {
    data: Role[];
}

const useRoleOperations = () => {
    const isAdmin = useAdmin();
    const { instance } = useMsal();
    const account = useAccount();
    const apiKey = account?.idToken;

    const createRole = async ({ data }: MutationParams) => {
        const response = await postAPI({
            url: `${BACKEND_URL}/v1/auth/roles`,
            apiKey,
            instance,
            payload: data,
        });

        if (!response.ok) {
            throw new Error("Failed to update user");
        }

        return response.json();
    };

    const deleteRole = async ({ data }: MutationParams) => {
        const response = await deleteAPI({
            url: `${BACKEND_URL}/v1/auth/roles?role_id=${data.id}`,
            apiKey,
            instance,
        });

        if (!response.ok) {
            throw new Error("Failed to delete role");
        }

        return response.json();
    };

    return {
        createRole,
        deleteRole,
    };
};

const ManageRoles = () => {
    const columns = useMemo<MRT_ColumnDef<Role>[]>(
        () => [
            {
                accessorKey: "id",
                header: "ID",
                accessorFn: (row) => (row ? row.id?.toString() : "") ?? "",
            },
            {
                accessorKey: "description",
                header: "Description",
                accessorFn: (row) => (row ? row.description?.toString() : "") ?? "",
            },
            {
                accessorKey: "datasources",
                header: "Datasources",
                accessorFn: (row) => {
                    if (!row?.datasources) return "";
                    return (
                        row.datasources
                            .map((obj) => obj?.id)
                            .filter(Boolean)
                            .join(", ") || ""
                    );
                },
            },
        ],
        [],
    );

    const isAdmin = useAdmin();
    const { instance } = useMsal();
    const account = useAccount();
    const apiKey = account?.idToken;

    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    const [columnFilterFns, setColumnFilterFns] = useState<MRT_ColumnFilterFnsState>(
        Object.fromEntries(columns.map(({ accessorKey }) => [accessorKey, "contains"])),
    );
    const [globalFilter, setGlobalFilter] = useState("");
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const fetchURL = useMemo(() => {
        const url = new URL("/v1/auth/roles", BACKEND_URL);
        url.searchParams.set("start", `${pagination.pageIndex * pagination.pageSize}`);
        url.searchParams.set("size", `${pagination.pageSize}`);
        url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
        url.searchParams.set("filterModes", JSON.stringify(columnFilterFns ?? {}));
        url.searchParams.set("globalFilter", globalFilter ?? "");
        url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
        return url;
    }, [columnFilterFns, columnFilters, globalFilter, pagination, sorting]);

    const { data, isLoading, refetch } = useInfiniteQuery<RolesApiResponse>({
        queryKey: ["roles", fetchURL.href],
        queryFn: async () => {
            const response = await getAPI({ url: fetchURL.href, apiKey, instance });
            const data = await response.json();
            return data;
        },
        keepPreviousData: true,
        enabled: isAdmin,
    });

    // Define the response type for datasources
    type DatasourceResponse = {
        id: string;
        name: string;
        description: string;
        product_line_filter: string[];
        managed_access: boolean;
    };

    const { data: datasourcesData, isLoading: datasourcesLoading } = useInfiniteQuery<
        DatasourceResponse[]
    >({
        queryKey: ["datasources"],
        queryFn: async () => {
            const response = await getAPI({
                url: `${BACKEND_URL}/v1/auth/datasources`,
                apiKey,
                instance,
            });
            const data = await response.json();
            return data;
        },
        keepPreviousData: true,
        enabled: isAdmin,
    });

    const { createRole, deleteRole } = useRoleOperations();

    const fetchedRoles = data?.pages.flatMap((page) => (Array.isArray(page) ? page : [])) ?? [];

    const datasourcesOptions = useMemo(() => {
        // The response is an array directly, not nested in pages.data
        const datasources = datasourcesData?.pages?.[0] || [];

        if (datasources.length === 0) return [];

        return datasources.map((datasource: DatasourceResponse) => ({
            value: datasource.id,
            label: `${datasource.id} - ${datasource.name || ""}`,
        }));
    }, [datasourcesData]);

    const tableContainerRef = useRef<HTMLDivElement>(null);
    const [isRefetching, setIsRefetching] = useState(false);

    const fetchMoreOnBottomReached = async (containerRefElement?: HTMLDivElement) => {
        if (containerRefElement) {
            const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
            if (
                scrollHeight - scrollTop - clientHeight < 400 &&
                !isRefetching &&
                data?.pages[data.pages.length - 1]?.data?.length === pagination.pageSize
            ) {
                setIsRefetching(true);
                await setPagination((old) => ({
                    ...old,
                    pageIndex: old.pageIndex + 1,
                }));
                setIsRefetching(false);
            }
        }
    };

    const [selectedRole, setSelectedRole] = useState<Role | null>(null);
    const [modalOpened, setModalOpened] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const form = useForm({
        initialValues: {
            id: "",
            description: "",
            datasources: [] as string[],
        },
    });

    const handleOpenModal = (role: Role) => {
        if (isLoading) {
            notifications.show({
                title: "Loading",
                message: "Please wait while roles are being loaded...",
                loading: true,
            });
            return;
        }
        setSelectedRole(role);
        form.setValues({
            id: role.id,
        });
        setModalOpened(true);
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            if (!selectedRole) {
                throw new Error("No role selected");
            }

            await deleteRole({
                data: {
                    id: selectedRole.id,
                },
            });

            notifications.show({
                title: "Success",
                message: "Role deleted successfully",
                color: "green",
            });
            setModalOpened(false);

            await new Promise((resolve) => setTimeout(resolve, 1000));
            await refetch();
        } catch (error) {
            notifications.show({
                title: "Error",
                message: error instanceof Error ? error.message : "Failed to delete role",
                color: "red",
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const [createRoleModalOpened, setCreateRoleModalOpened] = useState(false);
    const [isCreatingRole, setIsCreatingRole] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);

    const createRoleForm = useForm({
        initialValues: {
            id: "",
            description: "",
            datasources: [] as string[],
        },
        validate: {
            id: (value) => (!value ? "Role ID is required" : null),
            description: (value) => (!value ? "Description is required" : null),
        },
    });

    const handleOpenCreateModal = () => {
        setIsEditMode(false);
        createRoleForm.reset();
        setCreateRoleModalOpened(true);
    };

    const handleOpenEditModal = (role: Role) => {
        if (isLoading) {
            notifications.show({
                title: "Loading",
                message: "Please wait while roles are being loaded...",
                loading: true,
            });
            return;
        }
        setIsEditMode(true);
        const datasourceIds = role.datasources?.map((ds) => ds.id) || [];

        createRoleForm.setValues({
            id: role.id,
            description: role.description,
            datasources: datasourceIds,
        });
        setCreateRoleModalOpened(true);
    };

    const handleCreateOrUpdateRole = async (values: typeof createRoleForm.values) => {
        setIsCreatingRole(true);
        try {
            const url = `${BACKEND_URL}/v1/auth/roles`;
            const datasourcesPayload = values.datasources.map((id) => ({ id }));
            const data = await createRole({
                data: {
                    id: values.id,
                    description: values.description,
                    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                    datasources: datasourcesPayload as any,
                },
            });

            if (!data) {
                throw new Error(`Failed to ${isEditMode ? "update" : "create"} role`);
            }
            notifications.show({
                title: "Success",
                message: `Role ${isEditMode ? "updated" : "created"} successfully`,
                color: "green",
            });
            setCreateRoleModalOpened(false);
            createRoleForm.reset();

            await new Promise((resolve) => setTimeout(resolve, 1000));
            await refetch();
        } catch (error) {
            notifications.show({
                title: "Error",
                message:
                    error instanceof Error
                        ? error.message
                        : `Failed to ${isEditMode ? "update" : "create"} role`,
                color: "red",
            });
        } finally {
            setIsCreatingRole(false);
        }
    };

    const table = useMantineReactTable({
        // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
        columns: columns as any,
        data: fetchedRoles ?? [],
        enableColumnOrdering: true,
        enablePinning: true,
        enableBottomToolbar: false,
        renderToolbarAlertBannerContent: () => <></>,
        enableColumnResizing: true,
        enableFilters: true,
        enablePagination: false,
        enableRowNumbers: true,
        enableClickToCopy: false,
        getRowId: (row) => row.id,
        mantineToolbarAlertBannerProps: {
            style: {
                display: "none",
                background: "#f0f0f0",
                fontWeight: "bold",
            },
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        enableSelectAll: false,
        selectAllMode: "page",
        enableGrouping: true,
        enableDensityToggle: false,
        mantineTableContainerProps: {
            ref: tableContainerRef,
            sx: { maxHeight: "80vh" },
            onScroll: (event) => fetchMoreOnBottomReached(event.currentTarget),
        },
        enableRowActions: true,
        positionActionsColumn: "last",
        renderRowActions: ({ row }) => (
            <Menu shadow="md" width={200}>
                <Menu.Target>
                    <ActionIcon>
                        <IconDots size="1rem" />
                    </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Item
                        icon={<IconEdit size="1rem" />}
                        onClick={() => handleOpenEditModal(row.original)}
                    >
                        Edit Role
                    </Menu.Item>
                    <Menu.Item
                        color="red"
                        icon={<IconTrash size="1rem" />}
                        onClick={() => handleOpenModal(row.original)}
                    >
                        Delete Role
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        ),
        mantineTableBodyRowProps: ({ row }) => ({
            sx: {
                cursor: isLoading ? "wait" : "default",
                opacity: isLoading ? 0.7 : 1,
            },
        }),
        state: {
            isLoading: isLoading || isLoading,
            showProgressBars: isRefetching,
            columnFilters,
            globalFilter,
            sorting,
        },
        initialState: {
            showGlobalFilter: true,
            density: "xs",
            showColumnFilters: true,
        },
    });

    return (
        <>
            <Flex p="xl" direction="column" gap="md">
                <Flex gap="md" align="center">
                    <Title lh={2} c="purple.8" order={2} transform="uppercase">
                        Manage Roles
                    </Title>
                    <Button onClick={handleOpenCreateModal} variant="filled">
                        Create New Role
                    </Button>
                </Flex>
            </Flex>

            <MantineReactTable table={table} />

            <Modal
                opened={createRoleModalOpened}
                onClose={() => {
                    setCreateRoleModalOpened(false);
                    createRoleForm.reset();
                }}
                title={isEditMode ? `Edit Role ${createRoleForm.values.id}` : "Create New Role"}
                size="md"
            >
                <Box component="form" onSubmit={createRoleForm.onSubmit(handleCreateOrUpdateRole)}>
                    <Stack spacing="md">
                        <TextInput
                            required={!isEditMode}
                            label="Role ID"
                            placeholder="e.g., admin_role"
                            disabled={isEditMode}
                            {...createRoleForm.getInputProps("id")}
                        />

                        <Textarea
                            required
                            label="Description"
                            placeholder="Describe the purpose of this role"
                            minRows={3}
                            {...createRoleForm.getInputProps("description")}
                        />
                        <MultiSelect
                            required
                            label="Datasources"
                            placeholder="Select datasource"
                            data={datasourcesOptions}
                            {...createRoleForm.getInputProps("datasources")}
                            withinPortal
                            searchable
                            clearable
                            nothingFound="No datasource found"
                            disabled={isLoading || datasourcesLoading}
                        />
                        <Button type="submit" loading={isCreatingRole} fullWidth>
                            {isEditMode ? "Update Role" : "Create Role"}
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            <Modal
                opened={modalOpened}
                onClose={() => {
                    setModalOpened(false);
                    setSelectedRole(null);
                    form.reset();
                }}
                title={`Delete Role ${selectedRole?.id || ""}?`}
                size="md"
            >
                <Box>
                    <Text mb="md">
                        Are you sure you want to delete this role? This action cannot be undone.
                    </Text>
                    <Flex gap="md" justify="flex-end">
                        <Button variant="outline" onClick={() => setModalOpened(false)}>
                            Cancel
                        </Button>
                        <Button color="red" loading={isSubmitting} onClick={handleSubmit}>
                            Delete
                        </Button>
                    </Flex>
                </Box>
            </Modal>
        </>
    );
};

export default ManageRoles;
