import { useAccount, useMsal } from "@azure/msal-react";
import { useQuery } from "react-query";
import { getAPI } from "src/utils/fetch";

export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;

interface UserInfo {
    id: string;
    org_code: string;
    email: string;
    roles: string[];
}

interface Message {
    content: string;
    level: string;
    id: string;
}

interface Datasource {
    id: string;
    name: string;
    description: string;
    product_line_filter: string[];
}

interface UserResponse {
    info: UserInfo;
    messages: Message[];
    datasources: Datasource[];
}

export const useUser = () => {
    const { instance } = useMsal();
    const account = useAccount();
    const apiKey = account?.idToken;

    return useQuery<UserResponse, Error>({
        queryKey: ["user"],
        queryFn: async () => {
            const response = await getAPI({
                url: `${BACKEND_URL}/v1/user`,
                apiKey,
                instance,
            });
            const data = await response.json();
            return data;
        },
        enabled: !!apiKey,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
    });
};
