import { Button, Modal, Stack, TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { type ReactElement, cloneElement, memo, useEffect, useState } from "react";
import { type ChatEntity, db } from "../db";

export const EditChatModal = memo(function EditChatModal({
    chat,
    children,
}: {
    chat: ChatEntity;
    children: ReactElement;
}) {
    const [opened, { open, close }] = useDisclosure(false);
    const [submitting, setSubmitting] = useState(false);

    const [value, setValue] = useState("");
    useEffect(() => {
        setValue(chat?.description ?? "");
    }, [chat]);

    return (
        <>
            {cloneElement(children, { onClick: open })}
            <Modal opened={opened} onClose={close} title="Edit Chat" withinPortal>
                <form
                    onSubmit={async (event) => {
                        try {
                            setSubmitting(true);
                            event.preventDefault();
                            await db.chats.where({ id: chat.id }).modify((chat) => {
                                chat.description = value;
                            });
                            notifications.show({
                                title: "Saved",
                                message: "",
                            });
                            close();
                        } catch (error: unknown) {
                            // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                            const err = error as any;
                            if (err?.message === "Network Error") {
                                notifications.show({
                                    title: "Error",
                                    color: "red",
                                    message: "No internet connection.",
                                });
                            }
                            const message = err.response?.data?.error?.message;
                            if (message) {
                                notifications.show({
                                    title: "Error",
                                    color: "red",
                                    message,
                                });
                            }
                        } finally {
                            setSubmitting(false);
                        }
                    }}
                >
                    <Stack>
                        <TextInput
                            label="Name"
                            value={value}
                            onChange={(event) => setValue(event.currentTarget.value)}
                            formNoValidate
                            data-autofocus
                        />
                        <Button type="submit" loading={submitting}>
                            Save
                        </Button>
                    </Stack>
                </form>
            </Modal>
        </>
    );
});
