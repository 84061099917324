import { Anchor, Menu } from "@mantine/core";
import { useNavigate } from "@tanstack/react-router";
import type React from "react";

interface AdminMenuProps {
    isAdmin: boolean;
}

export const AdminMenu: React.FC<AdminMenuProps> = ({ isAdmin }) => {
    const navigate = useNavigate();

    if (!isAdmin) return null;

    return (
        <Menu>
            <Menu.Target>
                <Anchor>Admin Area</Anchor>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Label>Reports</Menu.Label>
                <Menu.Item onClick={() => navigate({ to: "/feedback" })}>Users Feedback</Menu.Item>
                <Menu.Divider />
                <Menu.Label>User Management</Menu.Label>
                <Menu.Item onClick={() => navigate({ to: "/manageUsers" })}>Manage Users</Menu.Item>
                <Menu.Item onClick={() => navigate({ to: "/manageRoles" })}>Manage Roles</Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
};
