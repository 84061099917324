import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;

interface MetadataMap {
    [key: string]: string;
}

export const HELP_PREFIX = "/";

export const metadataObj: MetadataMap = {
    customer: "Customer Name",
    "@": "Customer Name",
    codeword: "Codeword",
    product: "Product Type",
    machine: "Machine No",
    location: "Location",
    order: "Order No Internal",
    activity: "Type of Activity",
    project: "Project",
    author: "Author(s)",
    file: "report_name",
    // "help": "__HELP__"
};

export const metadataMap = new Map(Object.entries(metadataObj));

export const metadataUniqueValues = [...new Set(Object.values(metadataObj))];

export const useMetadata = () => {
    const [chatMetadata, setChatMetadata] = useState<Record<string, string>[]>([]);

    const removeChatMetadata = (name: string) => {
        setChatMetadata([...chatMetadata.filter((prevValue) => prevValue.value !== name)]);
    };

    const addChatMetadata = (name: string, prefix: string) => {
        const newMetadata = {
            value: name,
            key: metadataObj[prefix.replace(":", "")],
        };
        if (!chatMetadata.some((metadata) => metadata.key === newMetadata.key)) {
            setChatMetadata((prevChatMetadata) => {
                const updatedChatMetadata = prevChatMetadata.filter(
                    (metadata) => metadata.key !== newMetadata.key,
                );
                return [...updatedChatMetadata, newMetadata];
            });
        } else {
            // Allow multiple values for metadata called 'file' or 'report_name'
            setChatMetadata((prevChatMetadata) => {
                if (
                    newMetadata.key === metadataObj.file ||
                    newMetadata.key === "metadata.report_name"
                ) {
                    // If the metadata is 'file' or 'report_name', append the new value
                    return prevChatMetadata.some((meta) => meta.value === newMetadata.value)
                        ? prevChatMetadata
                        : [...prevChatMetadata, newMetadata];
                }
                // For other metadata, replace the existing value
                return prevChatMetadata.map((metadata) => {
                    if (metadata.key === newMetadata.key) {
                        return newMetadata;
                    }
                    return metadata;
                });
            });
        }
    };

    const resetChatMetadata = () => {
        setChatMetadata([]);
    };

    useEffect(() => {
        const transactionId = uuidv4();

        Sentry.withScope((scope) => {
            scope.setTag("transaction_id", transactionId);
        });
    }, []);

    return {
        resetChatMetadata,
        chatMetadata,
        removeChatMetadata,
        addChatMetadata,
    };
};
