import { useAccount, useMsal } from "@azure/msal-react";
import { type UseQueryResult, useQuery } from "react-query";
import { getAPI } from "src/utils/fetch";
import { BACKEND_URL } from "./useMetadata";

export interface SearchStatusResponse {
    data_sources: DatasourceIngestionStatus[];
}

export interface DatasourceIngestionStatus {
    id: string;
    total_count: number;
    ingestion_status: string;
    product_lines: ProductLineCount[];
    latest_document_timestamp: number;
}

export interface ProductLineCount {
    count: number;
    product_line: string;
}

export const useIngestionStatus = (): UseQueryResult<SearchStatusResponse, Error> => {
    const { instance } = useMsal();
    const account = useAccount();
    const apiKey = account?.idToken;

    return useQuery<SearchStatusResponse, Error>({
        queryKey: "datasource_ingestion_status",
        queryFn: async () => {
            const response = await getAPI({
                url: `${BACKEND_URL}/v1/search/status`,
                apiKey,
                instance,
            });
            return await response.json();
        },
        enabled: !!apiKey,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
    });
};
