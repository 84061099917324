import { Button, Modal, Stack, Text } from "@mantine/core";
import { memo } from "react";
import { type MessageEntity, db } from "../db";

export const DeleteMessageEntityItemModal = memo(function DeleteMessageEntityItemModal({
    message,
    isOpen,
    close,
}: {
    message: MessageEntity;
    isOpen?: boolean;
    close?: () => void;
}) {
    return (
        <Modal
            data-testid="delete-modal"
            opened={Boolean(isOpen)}
            onClose={close || (() => {})}
            title="Delete Message"
        >
            <form
                onSubmit={async (event) => {
                    try {
                        event.preventDefault();

                        const ids = [message.id];
                        if (message.repliedId) ids.push(message.repliedId);
                        for (const id of ids) {
                            await db.messages.where({ id }).delete();
                        }
                    } catch (error) {}
                }}
            >
                <Stack>
                    <Text size="sm">Are you sure you want to delete this chat?</Text>
                    <Button type="submit" color="red">
                        Delete
                    </Button>
                </Stack>
            </form>
        </Modal>
    );
});
