import { useAccount, useMsal } from "@azure/msal-react";
import * as Sentry from "@sentry/react";
import { type UseQueryResult, useQuery } from "react-query";
import { getAPI } from "src/utils/fetch";
import { BACKEND_URL } from "./useMetadata";

export interface SearchStatusResponse {
    data_sources: DatasourceIngestionStatus[];
}

export interface DatasourceIngestionStatus {
    id: string;
    total_count: number;
    ingestion_status: string;
    product_lines: ProductLineCount[];
    latest_document_timestamp: number;
}

export interface ProductLineCount {
    count: number;
    product_line: string;
}

export const useIngestionStatus = (): UseQueryResult<SearchStatusResponse, Error> => {
    const { instance } = useMsal();
    const account = useAccount();
    const apiKey = account?.idToken;

    return useQuery<SearchStatusResponse, Error>({
        queryKey: "datasource_ingestion_status",
        queryFn: async () => {
            const response = await getAPI({
                url: `${BACKEND_URL}/v1/search/status`,
                apiKey,
                instance,
            });
            return await response.json();
        },
        enabled: !!apiKey,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
    });
};

export const useApiHealth = () => {
    const { instance } = useMsal();
    const account = useAccount();
    const apiKey = account?.idToken;

    return useQuery({
        queryKey: "api_health",
        queryFn: async () => {
            try {
                const controller = new AbortController();
                const timeoutId = setTimeout(() => controller.abort(), 5000);

                const response = await fetch(BACKEND_URL, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${apiKey}`,
                    },
                    signal: controller.signal,
                });

                clearTimeout(timeoutId);
                return { isHealthy: response.ok };
            } catch (error) {
                // Capture the error in Sentry with additional context
                Sentry.withScope((scope) => {
                    scope.setTransactionName("api_health_check");
                    scope.setTag("error_type", "api_health_check");
                    scope.setExtra("username", account?.username);
                    scope.setExtra("account", account?.idTokenClaims?.gid); // ex. Z1234567890
                    scope.setExtra("org", account?.idTokenClaims?.org_code); // ex. Z1234567890
                    Sentry.captureException(
                        "Backend API Health Check Failed - Service Unreachable",
                        {
                            level: "error",
                        },
                    );
                });

                return { isHealthy: false };
            }
        },
        // Only refetch if the API was unhealthy
        refetchInterval: (data) => (data?.isHealthy ? false : 300000), // 5 minutes
        refetchIntervalInBackground: true,
        // Don't throw errors, we'll handle them in the UI
        useErrorBoundary: false,
    });
};
