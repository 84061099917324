import { createFileRoute } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { type ChatVectorIndexShorthand, DRAWINGS, FSR, RDS, SF } from "src/db";
import { DocumentsRoute } from "../../containers/DocumentsRoute";

export const Route = createFileRoute("/_layout-nosidebar/documents")({
    component: Documents,
});

function Documents() {
    const [selectedIndex, setSelectedIndex] = useState<ChatVectorIndexShorthand>(
        (localStorage.getItem("selectedIndex") as ChatVectorIndexShorthand) ?? FSR,
    );

    useEffect(() => {
        if (location.pathname === "/salesforce" && selectedIndex !== SF) {
            setSelectedIndex(SF);
        } else if (location.pathname === "/documents" && selectedIndex !== FSR) {
            setSelectedIndex(FSR);
        } else if (location.pathname === "/drawings" && selectedIndex !== DRAWINGS) {
            setSelectedIndex(DRAWINGS);
        } else if (location.pathname === "/rds" && selectedIndex !== RDS) {
            setSelectedIndex(RDS);
        }
    }, [selectedIndex]);

    useEffect(() => {
        localStorage.setItem("selectedIndex", selectedIndex);
        setSelectedIndex(selectedIndex);
    }, [selectedIndex]);

    return <DocumentsRoute index={selectedIndex} setSelectedIndex={setSelectedIndex} />;
}
