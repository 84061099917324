import Markdown from "react-markdown";

import { Divider, Modal } from "@mantine/core";

import type { FeedbackSchema } from "./MetadataFeedback";

interface FeedbackModalProps {
    rowData: FeedbackSchema | null;
    onClose: () => void;
}

const FeedbackModal = ({ rowData, onClose }: FeedbackModalProps) => {
    return (
        <Modal
            opened={rowData !== null}
            onClose={onClose}
            title={rowData?._source?.user ? `Feedback from ${rowData?._source?.user}` : "Feedback"}
            size="xl"
            py="sm"
        >
            <h3 style={{ display: "inline" }}>Question: &nbsp;</h3>
            {rowData?._source?.question}

            <h3>Answer:</h3>
            <Markdown>{rowData?._source?.cts_answer?.answer ?? ""}</Markdown>

            {rowData?._source?.cts_answer?.feedback_type && (
                <>
                    <Divider my="md" />
                    <h4 style={{ display: "inline" }}>
                        Feedback Type: &nbsp;
                        <text
                            // green text if feedback is positive, red text if feedback is negative
                            style={{
                                color:
                                    rowData?._source?.cts_answer?.feedback_type.toLowerCase() ===
                                    "positive"
                                        ? "green"
                                        : "red",
                                display: "inline",
                            }}
                        >
                            {rowData?._source?.cts_answer?.feedback_type}
                        </text>
                    </h4>
                </>
            )}
            <br />
            {rowData?._source?.cts_answer?.comments && (
                <>
                    <h4 style={{ display: "inline" }}>Feedback Comments: &nbsp;</h4>
                    {rowData?._source?.cts_answer?.comments}
                </>
            )}
        </Modal>
    );
};

export default FeedbackModal;
