import { createFileRoute } from "@tanstack/react-router";
import { SpotLightRoute } from "../../containers/SpotLightRoute";

export const Route = createFileRoute("/_layout-nosidebar/spotlight")({
    component: Spotlight,
});

export default function Spotlight() {
    return <SpotLightRoute />;
}
