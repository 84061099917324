import React, { useEffect, useState } from "react";

import { createFileRoute } from "@tanstack/react-router";

import { FeedbackRoute } from "../../containers/FeedbackRoute";

export const Route = createFileRoute("/_layout-nosidebar/feedback")({
    component: Feedback,
});

function Feedback() {
    return <FeedbackRoute />;
}
