import { Box, Button, Container, Flex, Stack, Text, Title } from "@mantine/core";
import { IconMessage, IconSearch } from "@tabler/icons-react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useEffect } from "react";
import { useUser } from "src/hooks/useUser";

function Welcome() {
    const navigate = useNavigate();
    const { data: user } = useUser();

    const [_, setProductLines] = useLocalStorage("availableProductLines", []);
    const [__, setDataSources] = useLocalStorage("availableDataSources", []);
    const [___, setUserDatasources] = useLocalStorage("datasources", []);

    useEffect(() => {
        if (user?.datasources) {
            setUserDatasources(user.datasources);

            const uniqueProductLines = [
                ...new Set(user.datasources.flatMap((ds) => ds.product_line_filter) || []),
            ].sort();

            const productLines = [
                {
                    id: "all",
                    title: "Default Filters",
                    subcategories: [],
                },
                ...uniqueProductLines.map((line) => {
                    const availableSources = user.datasources
                        .filter((ds) => ds.product_line_filter.includes(line))
                        .map((ds) =>
                            ds.name
                                .replace(" Cases", "")
                                .replace(" Reports", "")
                                .replace(" Database", ""),
                        );

                    return {
                        id: line,
                        title: line,
                        subcategories: availableSources,
                    };
                }),
            ];

            const dataSources = [
                {
                    id: "all",
                    title: "All Data Sources",
                    subcategories: [],
                },
                ...user.datasources.map((ds) => ({
                    id: ds.id,
                    title: ds.name,
                    subcategories: ds.description ? [ds.description] : [],
                })),
            ];

            setProductLines(productLines);
            setDataSources(dataSources);
        }
    }, [user, setUserDatasources, setProductLines, setDataSources]);

    const handleStartSearch = () => {
        navigate({ to: "/questionnaire" });
    };

    return (
        <Container size="md">
            <Box
                style={{
                    minHeight: "calc(100vh - 200px)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Stack align="center" spacing="xl">
                    <Title order={1} align="center" c="purple.8" size="3rem">
                        Welcome to CTS Knowledge Search
                    </Title>
                    <Text size="xl" align="center" maw={700} c="gray.7">
                        Your intelligent gateway to field service reports, Salesforce cases,
                        engineering drawings, and comprehensive technical documentation.
                    </Text>
                    <Flex gap="md">
                        <Button
                            size="xl"
                            leftIcon={<IconSearch size={24} />}
                            onClick={handleStartSearch}
                            variant="gradient"
                            gradient={{ from: "purple.8", to: "purple.5" }}
                            radius="md"
                            px={40}
                        >
                            Start Searching
                        </Button>
                        <Button
                            size="xl"
                            leftIcon={<IconMessage size={24} />}
                            onClick={() => navigate({ to: "/" })}
                            variant="outline"
                            radius="md"
                            px={40}
                        >
                            Start a conversation
                        </Button>
                    </Flex>
                </Stack>
            </Box>
        </Container>
    );
}

export const Route = createFileRoute("/_layout-nosidebar/welcome")({
    component: Welcome,
});
