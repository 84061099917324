import {
    Badge,
    Button,
    Center,
    Container,
    Group,
    SimpleGrid,
    Text,
    ThemeIcon,
    Title,
} from "@mantine/core";
import { IconCurrencyDollar, IconKey, IconLock, IconNorthStar } from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";
import { useLiveQuery } from "dexie-react-hooks";
import { SettingsModal } from "../../components/SettingsModal";
import { db } from "../../db";

export const Route = createFileRoute("/_layout-nosidebar/unauthorized")({
    component: UnauthorizedRoute,
});

export function UnauthorizedRoute() {
    const settings = useLiveQuery(() => db.settings.get("general"));
    const { openAiApiKey } = settings ?? {};

    return (
        <Center py="xl" sx={{ height: "100%" }}>
            <Container size="sm">
                <Badge mb="lg">GPT-4 Compatible</Badge>
                <Title variant="heading1">CTS Knowledge Search</Title>
                <Text mt={4} size="xl">
                    We help you find the information you need.
                </Text>
                <SimpleGrid
                    mt={50}
                    cols={3}
                    spacing={30}
                    breakpoints={[{ maxWidth: "md", cols: 1 }]}
                >
                    {features.map((feature) => (
                        <div key={feature.title}>
                            <ThemeIcon variant="outline" size={50} radius={50}>
                                <feature.icon size={26} stroke={1.5} />
                            </ThemeIcon>
                            <Text mt="sm" mb={7}>
                                {feature.title}
                            </Text>
                            <Text size="sm" color="dimmed" sx={{ lineHeight: 1.6 }}>
                                {feature.description}
                            </Text>
                        </div>
                    ))}
                </SimpleGrid>
                <Group mt={50}>
                    <SettingsModal>
                        <Button
                            size="md"
                            variant={openAiApiKey ? "light" : "filled"}
                            leftIcon={<IconKey size={20} />}
                        >
                            Request Access
                        </Button>
                    </SettingsModal>
                </Group>
            </Container>
        </Center>
    );
}

const features = [
    {
        icon: IconCurrencyDollar,
        title: "Increase Productivity",
        description:
            "Leverage our Knowledge Search tool to handle Salesforce cases and field service reports effortlessly. Save time by getting instant answers, summaries, and insights without reading through lengthy documents.",
    },
    {
        icon: IconLock,
        title: "Enhanced Privacy",
        description:
            "Your data security is our top priority. With no tracking, no cookies, and complete local storage, our tool ensures that your sensitive information remains confidential and protected.",
    },
    {
        icon: IconNorthStar,
        title: "Superior Support Experience",
        description:
            "Experience unparalleled efficiency with a tool designed to help you provide exceptional support. Our copilot tool enhances your ability to assist end users, ensuring a seamless and productive workflow.",
    },
];
