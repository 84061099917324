import { useAccount, useMsal } from "@azure/msal-react";
import { PdfFocusProvider } from "@llamaindex/pdf-viewer";
import {
    Card,
    Checkbox,
    Flex,
    Grid,
    Group,
    Paper,
    Select,
    Stack,
    Text,
    TextInput,
} from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { useLiveQuery } from "dexie-react-hooks";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ChatRole, MessageEntity, db } from "src/db";
import { type ChatMessage, useChatCompletion } from "src/hooks/useChatCompletion";
import { BACKEND_URL } from "src/hooks/useMetadata";
import { postAPI } from "src/utils/fetch";
import { v4 as uuidv4 } from "uuid";

const chatId = uuidv4();

export const SpotLightRoute = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
    const [filters, setFilters] = useState({});
    const [sortBy, setSortBy] = useState("relevance");
    const { instance } = useMsal();
    const account = useAccount();
    const chatCompletion = useChatCompletion();

    const apiKey = account?.idToken;

    const suggestAnswer = async (debouncedSearchTerm: string, userMessageId: string) => {
        // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
        const messagesSending = await (chatCompletion as any).makeMessagesSendingRequest({
            chatId,
            content: debouncedSearchTerm,
        });

        const assistantMessageReceivedId = await MessageEntity._()
            .setChatId(chatId)
            .setRole(ChatRole.ASSISTANT)
            .setContent("")
            .setRepliedId(userMessageId)
            .add();

        chatCompletion.send(
            messagesSending,
            async (chatMessage: ChatMessage | string, error?: string) => {
                // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                await updateAssistantMessage(assistantMessageReceivedId as any, chatMessage, error);
            },
        );
    };

    const updateAssistantMessage = async (
        messageId: string,
        chatMessage: ChatMessage | string,
        error?: string,
    ) => {
        await db.messages.where({ id: messageId }).modify((message) => {
            message.done = true;
            if (typeof chatMessage === "string") {
                message.content = chatMessage;
            } else {
                message.content = chatMessage.content;
                message.responseId = chatMessage.responseId;
                message.pretrained = chatMessage.pretrained;
                message.suggestions = chatMessage.suggestions;
            }
            message.hasError = Boolean(error);
            message.error = error;
        });
    };

    const fetchSearchResults = async () => {
        const url = new URL("v1/search/text", BACKEND_URL);

        // Only suggest answer if it hasn't been done for this search term
        if (!messages?.some((m) => m.content === debouncedSearchTerm && m.role === ChatRole.USER)) {
            const userMessageId = await MessageEntity._()
                .setChatId(chatId)
                .setRole(ChatRole.USER)
                .setContent(debouncedSearchTerm)
                .add();

            // suggestAnswer(debouncedSearchTerm, userMessageId);
        }

        const response = await postAPI({
            url: url.toString(),
            apiKey,
            instance,
            payload: {
                query: debouncedSearchTerm,
                metadata_filter: Object.entries(filters).flatMap(([key, value]) => {
                    if (Array.isArray(value)) {
                        return value.map((v) => ({ key, value: v }));
                    }
                    return [{ key, value: value.toString() }];
                }),
                sort: sortBy,
            },
        });
        return response.json();
    };

    const messages = useLiveQuery(() => {
        if (!chatId) return [];
        return db.messages.where("chatId").equals(chatId).sortBy("createdAt");
    }, [chatId]);

    const { data, error, refetch } = useQuery(
        ["searchResults", debouncedSearchTerm, filters, sortBy],
        fetchSearchResults,
        {
            enabled: !!debouncedSearchTerm,
        },
    );

    const handleFilterChange = (key, value, checked) => {
        setFilters((prev) => ({
            ...prev,
            [key]: checked
                ? [...(prev[key] || []), value]
                : (prev[key] || []).filter((v) => v !== value),
        }));
    };

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 1000); // 1000ms debounce

        return () => clearTimeout(debounceTimer);
    }, [searchTerm]);

    useEffect(() => {
        if (debouncedSearchTerm) {
            refetch();
        }
    }, [debouncedSearchTerm, refetch]);

    // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
    if (error) return <Text>An error occurred: {(error as any).message}</Text>;

    const { aggregations, hits } = data || {
        aggregations: {},
        hits: { total: { value: 0 }, hits: [] },
    };

    return (
        <PdfFocusProvider>
            <Paper
                p="md"
                radius="sm"
                withBorder
                style={{
                    height: "calc(100vh - 60px)",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Flex justify="center" mb="xl">
                    <TextInput
                        placeholder="When was the last gas turbine changeout at North Rankin?"
                        icon={<IconSearch size={14} />}
                        size="md"
                        style={{ width: "70%" }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </Flex>

                <Grid>
                    <Grid.Col span={9}>
                        <Text weight={700} size="lg" mb="md">
                            {searchTerm}
                        </Text>

                        <Group position="apart" mb="md">
                            <Group spacing="xs">
                                <Text weight={700}>Results {hits.total.value}</Text>
                            </Group>
                            <Group spacing="xs">
                                <Select
                                    placeholder="Sort: relevance"
                                    data={[
                                        { value: "relevance", label: "Relevance" },
                                        { value: "date", label: "Date" },
                                    ]}
                                    styles={{ root: { width: 150 } }}
                                    value={sortBy}
                                    onChange={setSortBy}
                                />
                                <Text size="sm" color="blue">
                                    Hide filters
                                </Text>
                            </Group>
                        </Group>

                        {hits.hits.map((hit) => (
                            <Card key={hit._id} withBorder mb="md">
                                <Text weight={700}>
                                    {hit._source.metadata["Subject/Title"] ||
                                        `Result for ${hit._id}`}
                                </Text>
                                <Text size="sm" color="dimmed" mb="xs">
                                    {hit._id}
                                </Text>
                                {hit.highlight?.text?.map((highlight) => (
                                    <Text
                                        key={`highlight-${highlight}`}
                                        size="sm"
                                        mb="xs"
                                        component="div"
                                    >
                                        <span>{highlight}</span>
                                    </Text>
                                ))}
                                {/* <Group spacing="xs">
									{Object.entries(hit._source.metadata).map(([key, value]) => (
										<Badge
											key={`${key}-${value}`}
											variant="outline"
										>{`${key}: ${value}`}</Badge>
									))}
									<div style={{ height: "90vh", width: "100%" }}>
										{ <AsyncPdfViewer
											apiKey={apiKey}
											instance={instance}
											pdfUrl={`${CHAT_COMPLETIONS_URL}${hit._source.metadata.source.startsWith("/") ? "" : "/"
												}${hit._source.metadata.source}`}
											row={hit._source.metadata.file_name}
										/>}
									</div>
								</Group> */}
                            </Card>
                        ))}
                    </Grid.Col>

                    <Grid.Col span={3}>
                        <Text weight={700} mb="md">
                            Aggregations
                        </Text>
                        <Stack spacing="xs">
                            {Object.entries(aggregations)
                                // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                                .sort(([, a]: [string, any], [, b]: [string, any]) => {
                                    const aTotal =
                                        a.buckets?.reduce(
                                            (sum, bucket) => sum + bucket.doc_count,
                                            0,
                                        ) || 0;
                                    const bTotal =
                                        b.buckets?.reduce(
                                            (sum, bucket) => sum + bucket.doc_count,
                                            0,
                                        ) || 0;
                                    return bTotal - aTotal;
                                })
                                // biome-ignore lint/suspicious/noExplicitAny: TODO: This is a temporary fix to enforce TS typing.
                                .map(([key, value]: [string, any]) => {
                                    if (value.buckets && value.buckets.length > 0) {
                                        const sortedBuckets = value.buckets.sort(
                                            (a, b) => b.doc_count - a.doc_count,
                                        );
                                        return (
                                            <Card key={key} withBorder mb="sm">
                                                <Text weight={600} size="sm">
                                                    {key}
                                                </Text>
                                                {sortedBuckets.map(
                                                    (bucket, index) =>
                                                        bucket.doc_count > 0 && (
                                                            <Checkbox
                                                                key={`${key}-${bucket.key}`}
                                                                label={`${bucket.key} (${bucket.doc_count})`}
                                                                mb="xs"
                                                                onChange={(event) =>
                                                                    handleFilterChange(
                                                                        key,
                                                                        bucket.key,
                                                                        event.currentTarget.checked,
                                                                    )
                                                                }
                                                                checked={
                                                                    filters[key]?.includes(
                                                                        bucket.key,
                                                                    ) || false
                                                                }
                                                            />
                                                        ),
                                                )}
                                            </Card>
                                        );
                                    }
                                    return null;
                                })}
                        </Stack>
                    </Grid.Col>
                </Grid>
            </Paper>
        </PdfFocusProvider>
    );
};
